import React, { useState } from 'react'
import styles from './Nav.module.css'
import { NavLink } from 'react-router-dom'
import {
    HiDocumentSearch,
    HiDatabase,
    HiCalculator,
    HiTemplate,
    HiDotsHorizontal,
    HiLogout,
} from 'react-icons/hi'
import { MdEmail } from 'react-icons/md'
import { RiDiscordFill } from 'react-icons/ri'
import { BsClipboard2DataFill } from 'react-icons/bs'
import { IconType } from 'react-icons'
import { useAuthStore } from '../../state/store'
import OutsideAlerter from '../../hooks/ClickOutside'

type NavItemProps = {
    to: string
    Icon: IconType
    name: string
}

const NavItem = (props: NavItemProps) => {
    const { to, Icon, name } = props
    return (
        <NavLink to={to} className={styles.navItem}>
            {({ isActive }) => {
                return (
                    <>
                        <div className={styles.navIcon}>
                            <Icon color={isActive ? '#1fb723' : '#364165'} />
                        </div>
                        <div className={styles.navName}>{name}</div>
                    </>
                )
            }}
        </NavLink>
    )
}

const DropDownMenu = () => {
    const [isOpened, SetIsOpened] = useState(false)
    const { logout } = useAuthStore((state) => state)
    const pressLogout = () => {
        window.localStorage.removeItem('token')
        logout()
    }
    return (
        <div className={styles.moreContainer}>
            <OutsideAlerter
                onpress={() => {
                    SetIsOpened(false)
                }}
            >
                <div
                    className={styles.navItem}
                    onClick={() => SetIsOpened(!isOpened)}
                >
                    <div className={styles.navIcon}>
                        <HiDotsHorizontal color={'#364165'} />
                    </div>
                    <div className={styles.navName}>More</div>
                </div>
                {isOpened ? (
                    <div className={styles.dropDownMenu}>
                        <a
                            target="_blank"
                            href="https://wisesheets.io/available-data"
                            className={styles.dropDownLink}
                        >
                            <BsClipboard2DataFill
                                className={styles.dropDownLinkIcon}
                            />
                            Available Data
                        </a>
                        <a
                            target="_blank"
                            href="mailto:info@wisesheets.io"
                            className={styles.dropDownLink}
                        >
                            <MdEmail className={styles.dropDownLinkIcon} />
                            Contact
                        </a>
                        <a
                            target="_blank"
                            href="https://discord.com/invite/z8PgPqhCxs"
                            className={styles.dropDownLink}
                        >
                            <RiDiscordFill
                                className={styles.dropDownLinkIcon}
                            />
                            Discord
                        </a>
                        <NavLink
                            to="/"
                            onClick={pressLogout}
                            className={styles.dropDownLink}
                        >
                            <HiLogout className={styles.dropDownLinkIcon} />
                            Logout
                        </NavLink>
                    </div>
                ) : null}
            </OutsideAlerter>
        </div>
    )
}

export default function Nav() {
    return (
        <div className={styles.navigationContainer}>
            <NavItem
                to="/feature/statement-dump"
                Icon={HiDocumentSearch}
                name="Financials"
            />
            <NavItem to="/feature/screener" Icon={HiDatabase} name="Screener" />
            <NavItem
                to={'/feature/function-builder'}
                Icon={HiCalculator}
                name="Functions"
            />
            <NavItem
                to="/feature/templates"
                Icon={HiTemplate}
                name="Templates"
            />
            <DropDownMenu />
        </div>
    )
}
