import React from 'react'
import { IoMdAlert } from 'react-icons/io'
import { useTheme } from 'styled-components'
import { StyledClose, StyledErrorContent } from './styles'
import { StyledError } from './styles'
import { StyledErrorModal } from './styles'
type Props = {
    message: React.ReactNode
    onDismissClick: () => void
    isShown: boolean
}

export default function ErrorModal(props: Props) {
    const theme = useTheme()
    if (!props.isShown) {
        return null
    } else
        return (
            <StyledErrorModal>
                <StyledError>
                    <IoMdAlert color={theme.colours.font.red} size={36} />
                    <StyledErrorContent
                        dangerouslySetInnerHTML={{ __html: props.message }}
                    />
                    <StyledClose onClick={props.onDismissClick}>
                        Dismiss
                    </StyledClose>
                </StyledError>
            </StyledErrorModal>
        )
}
