export enum NumberType {
    Number = 'number',
    Percent = 'percent',
}

export const formatNumber = (num: number, type: NumberType): string => {
    if (!num || !type) return 'N/A'
    if (num >= 1.0e12) {
        return (
            (num / 1.0e12).toFixed(2) +
            (type === NumberType.Number
                ? 'T'
                : type === NumberType.Percent
                ? '%'
                : '')
        )
    }
    if (num >= 1.0e9) {
        return (
            (num / 1.0e9).toFixed(2) +
            (type === NumberType.Number
                ? 'B'
                : type === NumberType.Percent
                ? '%'
                : '')
        )
    }
    if (num >= 1.0e6) {
        return (
            (num / 1.0e6).toFixed(2) +
            (type === NumberType.Number
                ? 'M'
                : type === NumberType.Percent
                ? '%'
                : '')
        )
    }
    if (num >= 1.0e3) {
        return (
            (num / 1.0e3).toFixed(2) +
            (type === NumberType.Number
                ? 'K'
                : type === NumberType.Percent
                ? '%'
                : '')
        )
    }
    return num.toFixed(2) + (type === NumberType.Percent ? '%' : '')
}
type KeyMetricsAQ = {
    date: string // YYYY-MM-DD
    peRatio: number // used to calc average
    priceToSalesRatio: number // calculate price for any given yeah or quarter it is priceToSalesRatio * revenuePerShare
    revenuePerShare: number
    roe: number
    currentRatio: number
    dividendYield: number
    enterpriseValue: number
}

export type CompanyData = {
    quote: {
        symbol: string
        name: string
        price: number
        updatedAt: string
        eps: number
        pe: number
        sharesOutstanding: number
        exchange: string
        change: number
        changesPercentage: number
        earningsAnnouncement: string
        marketCap: number
        volume: number
    }
    profile: [
        {
            image: string // url
            description: string
            website: string
            industry: string
            ipoDate: string
            isEtf: boolean
            currency: string
        }
    ] // always length 1
    peers: [
        {
            symbol: string
            peRatioTTM: number
            roeTTM: number
            currentRatioTTM: number
            marketCap: number
            enterpriseValueTTM: number
            companyName: string
            dividendYieldTTM: number
        }
    ]
    keyMetricsTTM: {
        marketCapTTM: number
        peRatioTTM: number
        roeTTM: number
        currentRatioTTM: number
        dividendYieldTTM: number
        dividendYieldPercentageTTM: number
        enterpriseValueTTM: number
        netIncomePerShareTTM: number
        shareholdersEquityPerShareTTM: number
        dividendPerShareTTM: number
        totalCurrentLiabilitiesTTM: number
        totalCurrentAssetsTTM: number
        priceToSalesRatioTTM: number
        pbRatioTTM: number
    }
    keyMetricsAnnual: KeyMetricsAQ[] // returns all years available
    keyMetricsQuarterly: KeyMetricsAQ[] // returns all quarters available
}
