import axios from 'axios'
import { cellMap } from '../../utils/cell-map'
import {
    API_URL_V3,
    API_URL_V4,
    STATEMENT_DUMP_API_KEY,
    INCOME_STATEMENT,
    URL_MAP,
} from './constants'

export const executeDataDump = async (
    ticker,
    period,
    statement,
    reverseData,
    tickerInfo?
) => {
    try {
        await Excel.run(async (context) => {
            let url = ''
            if (period === 'annual') {
                url =
                    API_URL_V3 +
                    URL_MAP[statement] +
                    '/' +
                    ticker +
                    '?apikey=' +
                    STATEMENT_DUMP_API_KEY
            } else {
                url =
                    API_URL_V3 +
                    URL_MAP[statement] +
                    '/' +
                    ticker +
                    '?period=quarter&apikey=' +
                    STATEMENT_DUMP_API_KEY
            }
            const response: any = await axios.get(url)
            if (!response.data.length) {
                throw 'Unknown ticker'
            }
            let currency = ''

            if (statement === INCOME_STATEMENT) {
                currency = response.data[0].reportedCurrency
            } else {
                if (period === 'annual') {
                    if (ticker.length > 9) {
                        currency =
                            "='" +
                            ticker.slice(0, 9) +
                            ' - ' +
                            INCOME_STATEMENT +
                            " FY'!B3"
                    } else {
                        currency =
                            "='" + ticker + ' - ' + INCOME_STATEMENT + " FY'!B3"
                    }
                } else {
                    if (ticker.length > 9) {
                        currency =
                            "='" +
                            ticker.slice(0, 9) +
                            ' - ' +
                            INCOME_STATEMENT +
                            " Q'!B3"
                    } else {
                        currency =
                            "='" + ticker + ' - ' + INCOME_STATEMENT + " Q'!B3"
                    }
                }
            }

            // only last 18 years
            if (period === 'annual' && response.data.length > 19) {
                response.data.splice(19)
            } else if (response.data.length > 78) {
                response.data.splice(78)
            }

            // filtering data here, just add delete item.whatever
            response.data.map(function (item) {
                const itemKeys = Object.keys(item)
                itemKeys.map((key: string) => {
                    if (
                        cellMap[key + '-' + statement] == null ||
                        cellMap[key + '-' + statement] == undefined
                    ) {
                        delete item[key]
                    }
                })
                return item
            })

            // create the new sheet
            var sheets = context.workbook.worksheets
            var sheetName = ticker + ' - ' + statement
            if (ticker.length > 9) {
                sheetName = ticker.slice(0, 9) + ' - ' + statement
            }
            if (period === 'annual') {
                sheetName += ' FY'
            } else {
                sheetName += ' Q'
            }

            var newSheet = sheets.add(sheetName)

            var insertedValues = []
            var values = []
            var length = 0
            newSheet.activate()
            const exchangeName = tickerInfo
                ? tickerInfo.exchangeShortName
                : 'Unknown Exch.'
            context.workbook.worksheets
                .getActiveWorksheet()
                .getRange('A1').values = [[statement]]
            context.workbook.worksheets
                .getActiveWorksheet()
                .getRange('A2:B2').values = [
                ['Company', ticker + ' (' + exchangeName + ')'],
            ]
            context.workbook.worksheets
                .getActiveWorksheet()
                .getRange('A3:B3').values = [['Currency', currency]]

            // getting all values
            for (
                var i = Object.keys(response.data[0]).length - 1;
                i >= 0;
                i--
            ) {
                var param = Object.keys(response.data[0])[i]

                // formatting parameters strings
                var key = param
                key = key.charAt(0).toUpperCase() + key.slice(1)
                key = key.replace(/([A-Z])/g, ' $1').trim()
                key = key.replace(/([A-Z])\s(?=[A-Z])/g, '$1')

                if (
                    key === 'General And Administrative Expenses' ||
                    key === 'Selling And Marketing Expenses'
                ) {
                    // simulating a tab because \t does not work #fuckexcel
                    key = '    ' + key
                }

                insertedValues.push(key)
                let rowValues = []
                for (var j = 0; j < response.data.length; j++) {
                    rowValues.push(response.data[j][param])
                }
                if (reverseData) {
                    rowValues = rowValues.reverse()
                }
                const insertedRow = insertedValues.concat(rowValues)
                values.push(insertedRow)
                length = values[0].length - 1
                var cellObj = cellMap[param + '-' + statement]
                var firstCell = newSheet.getCell(cellObj.col, cellObj.row)
                var lastCell = newSheet.getCell(
                    cellObj.col,
                    values[0].length - 1
                )
                var range = firstCell.getBoundingRect(lastCell)

                // setting the number formatting
                if (cellObj.format !== '') {
                    range.numberFormat = cellObj.format
                }

                //setting the style attributes
                if (cellObj.borderTop) {
                    var first = newSheet.getCell(cellObj.col, cellObj.row + 1)
                    var last = newSheet.getCell(
                        cellObj.col,
                        values[0].length - 1
                    )
                    var myrange = first.getBoundingRect(last)
                    myrange.format.borders.getItem('EdgeTop').color = 'Black'
                    myrange.format.borders.getItem('EdgeTop').load('style')
                }
                if (cellObj.borderBottom) {
                    var first1 = newSheet.getCell(cellObj.col, cellObj.row + 1)
                    var last1 = newSheet.getCell(
                        cellObj.col,
                        values[0].length - 1
                    )
                    var range1 = first1.getBoundingRect(last1)
                    range1.format.borders.getItem('EdgeBottom').color = 'Black'
                    range1.format.borders.getItem('EdgeBottom').load('style')
                }
                if (cellObj.merge) {
                    // only merge the cells for annual
                    if (period === 'annual') {
                        var first2 = newSheet.getCell(
                            cellObj.col,
                            cellObj.row + 1
                        )
                        var last2 = newSheet.getCell(
                            cellObj.col,
                            values[0].length - 1
                        )
                        var range2 = first2.getBoundingRect(last2)
                        range2.merge()
                        range2.format.horizontalAlignment = 'Center'
                    }
                }
                if (cellObj.bold) {
                    var first3 = newSheet.getCell(cellObj.col, cellObj.row)
                    var last3 = newSheet.getCell(cellObj.col, cellObj.row)
                    var range3 = first3.getBoundingRect(last3)
                    range3.format.font.bold = true
                }

                // setting the values
                range.values = values

                insertedValues = []
                values = []
            }

            var firstCell1 = newSheet.getCell(0, 0)
            var lastCell1 = newSheet.getCell(0, length)
            var range4 = firstCell1.getBoundingRect(lastCell1)

            range4.format.font.bold = true
            range4.format.fill.color = '#F7F8F8'
            range4.format.font.color = '#364165'

            context.workbook.worksheets
                .getActiveWorksheet()
                .getRange()
                .format.autofitColumns()
            await context.sync()
        })
    } catch (e) {
        throw e
    }
}

export const executeSECDataDumpV2 = async (
    ticker: string,
    year: string,
    period: 'FY' | 'Q1' | 'Q2' | 'Q3' | 'Q4',
    tickerInfo?
) => {
    try {
        await Excel.run(async (context) => {
            const url =
                API_URL_V4 +
                `financial-reports-json?symbol=${ticker}&year=${year}&period=${period}&apikey=${STATEMENT_DUMP_API_KEY}`
            const response: any = await axios.get(url)

            if (
                !response.data ||
                response.data['Error Message'] !== undefined
            ) {
                throw 'Unknown error please try a different ticker.'
            }

            // create the new sheets
            var sheets = context.workbook.worksheets
            var operationsSheet = null
            operationsSheet = sheets.add(
                `${ticker} - Income SEC ${year} ${period}`
            )
            var balanceSheet = null
            balanceSheet = sheets.add(
                `${ticker} - Balance SEC ${year} ${period}`
            )
            var cashflowSheet = null
            cashflowSheet = sheets.add(`${ticker} - Cash SEC ${year} ${period}`)

            const exchangeName = tickerInfo
                ? tickerInfo.exchangeShortName
                : 'Unknown Exch.'

            const upperData = upperize(response.data)

            const operationsData =
                upperData['CONSOLIDATED STATEMENTS OF OPER'] ||
                upperData['CONDENSED CONSOLIDATED STATEMEN'] ||
                upperData['CONSOLIDATED STATEMENTS OF EARN'] ||
                upperData['INCOME STATEMENTS'] ||
                upperData['CONSOLIDATED STATEMENTS OF INCO'] ||
                upperData['CONSOLIDATED INCOME STATEMENTS'] ||
                upperData['CONSOLIDATED STATEMENTS OF COMP']

            const balanceData =
                upperData['CONSOLIDATED BALANCE SHEETS'] ||
                upperData['CONDENSED CONSOLIDATED BALANCE '] ||
                upperData['CONSOLIDATED BALANCE SHEETS (UN'] ||
                upperData['CONSOLIDATED BALANCE SHEET'] ||
                upperData['CONSOLIDATED BALANCE SHEETS, AS'] ||
                upperData['CONSOLIDATED STATEMENTS OF ASSE']

            const cashflowData =
                upperData['CONSOLIDATED STATEMENTS OF CASH'] ||
                upperData['CONDENSED CONSOLIDATED STATEM_4'] ||
                upperData['CONDENSED CONSOLIDATED STATEM_3'] ||
                upperData['CONDENSED CONSOLIDATED STATEME7']

            let operationsStatement = ''
            let balanceStatement = ''
            let cashflowStatement = ''

            let insertedOperationsValues = []
            let insertedBalanceValues = []
            let insertedCashflowValues = []

            if (operationsData !== null && operationsData !== undefined) {
                operationsData.forEach((element, index) => {
                    if (index === 0) {
                        // add element value as spreadsheet title
                        operationsStatement = Object.keys(element)[0]
                        // add the rest of the upper sheet stuff
                    } else {
                        let key = Object.keys(element) // string[]
                        let values = Object.values(element)[0] // string[]
                        if (key[0] === 'items') {
                            key[0] = 'Date'
                            const periodRow = ['Period']
                            const periodArray = new Array(
                                (Object.values(element)[0] as string[]).length
                            ).fill(period)
                            const pushRow = periodRow.concat(periodArray)
                            insertedOperationsValues.push(pushRow)
                        }
                        const row = key.concat(values as string[])
                        insertedOperationsValues.push(row)
                    }
                })
                let maxRowSize = 0
                for (let i = 0; i < insertedOperationsValues.length; i++) {
                    if (insertedOperationsValues[i].length > maxRowSize) {
                        maxRowSize = insertedOperationsValues[i].length
                        i = -1
                        continue
                    }

                    if (insertedOperationsValues[i].length < maxRowSize) {
                        const diff =
                            maxRowSize - insertedOperationsValues[i].length
                        for (let j = 0; j < diff; j++) {
                            insertedOperationsValues[i].push(null)
                        }
                    }
                }
                operationsSheet.activate()
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1').values = [[operationsStatement]]
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A2:B2').values = [
                    ['Company', ticker + ' (' + exchangeName + ')'],
                ]
                var firstCell1 = operationsSheet.getCell(0, 0)
                var lastCell1 = operationsSheet.getCell(
                    0,
                    insertedOperationsValues[0].length - 1
                )
                var range4 = firstCell1.getBoundingRect(lastCell1)

                range4.format.font.bold = true
                range4.format.fill.color = '#F7F8F8'
                range4.format.font.color = '#364165'

                var firstCell = operationsSheet.getCell(2, 0)
                var lastCell = operationsSheet.getCell(
                    insertedOperationsValues.length - 1 + 2,
                    insertedOperationsValues[0].length - 1
                )
                var range = firstCell.getBoundingRect(lastCell)
                range.values = insertedOperationsValues

                if (period === 'FY') {
                    var first2 = operationsSheet.getCell(2, 1)
                    var last2 = operationsSheet.getCell(
                        2,
                        insertedOperationsValues[0].length - 1
                    )
                    var range2 = first2.getBoundingRect(last2)
                    range2.merge()
                    range2.format.horizontalAlignment = 'Center'
                }
                var borderCell1 = operationsSheet.getCell(2, 1)
                var borderCell2 = operationsSheet.getCell(
                    2,
                    insertedOperationsValues[0].length - 1
                )
                var borderRange = borderCell1.getBoundingRect(borderCell2)
                borderRange.format.borders.getItem('EdgeBottom').color = 'Black'
                borderRange.format.borders.getItem('EdgeBottom').load('style')

                var boldRange1 = operationsSheet.getCell(3, 1)
                var boldRange2 = operationsSheet.getCell(
                    3,
                    insertedOperationsValues[0].length - 1
                )
                var rangeBold = boldRange1.getBoundingRect(boldRange2)

                rangeBold.format.font.bold = true

                var format1 = operationsSheet.getCell(4, 1)
                var format2 = operationsSheet.getCell(
                    insertedOperationsValues.length - 1 + 4,
                    insertedOperationsValues[0].length - 1
                )
                var formatRange = format1.getBoundingRect(format2)
                formatRange.numberFormat = '#,##0.00_);(#,##0.00)'

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                await context.sync()
            }

            if (balanceData !== null && balanceData !== undefined) {
                balanceData.forEach((element, index) => {
                    if (index === 0) {
                        // add element value as spreadsheet title
                        balanceStatement = Object.keys(element)[0]
                        // add the rest of the upper sheet stuff
                        const periodRow = ['Period']
                        const periodArray = new Array(
                            (Object.values(element)[0] as string[]).length
                        ).fill(period)
                        const pushRow = periodRow.concat(periodArray)
                        insertedBalanceValues.push(pushRow)
                        const dateRow = ['Date']

                        const pushDateRow = dateRow.concat(
                            Object.values(element)[0] as string[]
                        )
                        insertedBalanceValues.push(pushDateRow)
                    } else {
                        let key = Object.keys(element) // string[]
                        let values = Object.values(element)[0] // string[]
                        const row = key.concat(values as string[])
                        insertedBalanceValues.push(row)
                    }
                })
                let maxRowSize = 0
                for (let i = 0; i < insertedBalanceValues.length; i++) {
                    if (insertedBalanceValues[i].length > maxRowSize) {
                        maxRowSize = insertedBalanceValues[i].length
                        i = -1
                        continue
                    }

                    if (insertedBalanceValues[i].length < maxRowSize) {
                        const diff =
                            maxRowSize - insertedBalanceValues[i].length
                        for (let j = 0; j < diff; j++) {
                            insertedBalanceValues[i].push(null)
                        }
                    }
                }
                balanceSheet.activate()
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1').values = [[balanceStatement]]
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A2:B2').values = [
                    ['Company', ticker + ' (' + exchangeName + ')'],
                ]
                var firstCell1 = balanceSheet.getCell(0, 0)
                var lastCell1 = balanceSheet.getCell(
                    0,
                    insertedBalanceValues[0].length - 1
                )
                var range4 = firstCell1.getBoundingRect(lastCell1)

                range4.format.font.bold = true
                range4.format.fill.color = '#F7F8F8'
                range4.format.font.color = '#364165'

                var firstCell = balanceSheet.getCell(2, 0)
                var lastCell = balanceSheet.getCell(
                    insertedBalanceValues.length - 1 + 2,
                    insertedBalanceValues[0].length - 1
                )
                var range = firstCell.getBoundingRect(lastCell)
                range.values = insertedBalanceValues

                if (period === 'FY') {
                    var first2 = balanceSheet.getCell(2, 1)
                    var last2 = balanceSheet.getCell(
                        2,
                        insertedBalanceValues[0].length - 1
                    )
                    var range2 = first2.getBoundingRect(last2)
                    range2.merge()
                    range2.format.horizontalAlignment = 'Center'
                }
                var borderCell1 = balanceSheet.getCell(2, 1)
                var borderCell2 = balanceSheet.getCell(
                    2,
                    insertedBalanceValues[0].length - 1
                )
                var borderRange = borderCell1.getBoundingRect(borderCell2)
                borderRange.format.borders.getItem('EdgeBottom').color = 'Black'
                borderRange.format.borders.getItem('EdgeBottom').load('style')

                var boldRange1 = balanceSheet.getCell(3, 1)
                var boldRange2 = balanceSheet.getCell(
                    3,
                    insertedBalanceValues[0].length - 1
                )
                var rangeBold = boldRange1.getBoundingRect(boldRange2)

                rangeBold.format.font.bold = true

                var format1 = balanceSheet.getCell(4, 1)
                var format2 = balanceSheet.getCell(
                    insertedBalanceValues.length - 1 + 4,
                    insertedBalanceValues[0].length - 1
                )
                var formatRange = format1.getBoundingRect(format2)
                formatRange.numberFormat = '#,##0.00_);(#,##0.00)'

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                await context.sync()
            }
            if (cashflowData !== null && cashflowData !== undefined) {
                cashflowData.forEach((element, index) => {
                    if (index === 0) {
                        // add element value as spreadsheet title
                        cashflowStatement = Object.keys(element)[0]
                        // add the rest of the upper sheet stuff
                    } else {
                        let key = Object.keys(element) // string[]
                        let values = Object.values(element)[0] // string[]
                        if (key[0] === 'items') {
                            key[0] = 'Date'
                            const periodRow = ['Period']
                            const periodArray = new Array(
                                (Object.values(element)[0] as string[]).length
                            ).fill(period)
                            const pushRow = periodRow.concat(periodArray)
                            insertedCashflowValues.push(pushRow)
                        }
                        const row = key.concat(values as string[])
                        insertedCashflowValues.push(row)
                    }
                })
                let maxRowSize = 0
                for (let i = 0; i < insertedCashflowValues.length; i++) {
                    if (insertedCashflowValues[i].length > maxRowSize) {
                        maxRowSize = insertedCashflowValues[i].length
                        i = -1
                        continue
                    }

                    if (insertedCashflowValues[i].length < maxRowSize) {
                        const diff =
                            maxRowSize - insertedCashflowValues[i].length
                        for (let j = 0; j < diff; j++) {
                            insertedCashflowValues[i].push(null)
                        }
                    }
                }
                cashflowSheet.activate()
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1').values = [[cashflowStatement]]
                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A2:B2').values = [
                    ['Company', ticker + ' (' + exchangeName + ')'],
                ]
                var firstCell1 = cashflowSheet.getCell(0, 0)
                var lastCell1 = cashflowSheet.getCell(
                    0,
                    insertedCashflowValues[0].length - 1
                )
                var range4 = firstCell1.getBoundingRect(lastCell1)

                range4.format.font.bold = true
                range4.format.fill.color = '#F7F8F8'
                range4.format.font.color = '#364165'

                var firstCell = cashflowSheet.getCell(2, 0)
                var lastCell = cashflowSheet.getCell(
                    insertedCashflowValues.length - 1 + 2,
                    insertedCashflowValues[0].length - 1
                )
                var range = firstCell.getBoundingRect(lastCell)
                range.values = insertedCashflowValues

                if (period === 'FY') {
                    var first2 = cashflowSheet.getCell(2, 1)
                    var last2 = cashflowSheet.getCell(
                        2,
                        insertedCashflowValues[0].length - 1
                    )
                    var range2 = first2.getBoundingRect(last2)
                    range2.merge()
                    range2.format.horizontalAlignment = 'Center'
                }
                var borderCell1 = cashflowSheet.getCell(2, 1)
                var borderCell2 = cashflowSheet.getCell(
                    2,
                    insertedCashflowValues[0].length - 1
                )
                var borderRange = borderCell1.getBoundingRect(borderCell2)
                borderRange.format.borders.getItem('EdgeBottom').color = 'Black'
                borderRange.format.borders.getItem('EdgeBottom').load('style')

                var boldRange1 = cashflowSheet.getCell(3, 1)
                var boldRange2 = cashflowSheet.getCell(
                    3,
                    insertedCashflowValues[0].length - 1
                )
                var rangeBold = boldRange1.getBoundingRect(boldRange2)

                rangeBold.format.font.bold = true

                var format1 = cashflowSheet.getCell(4, 1)
                var format2 = cashflowSheet.getCell(
                    insertedCashflowValues.length - 1 + 4,
                    insertedCashflowValues[0].length - 1
                )
                var formatRange = format1.getBoundingRect(format2)
                formatRange.numberFormat = '#,##0.00_);(#,##0.00)'

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                await context.sync()
            }
        })
    } catch (e) {
        throw e
    }
}

const upperize = (obj) =>
    Object.keys(obj).reduce((acc, k) => {
        acc[k.toUpperCase()] = obj[k]
        return acc
    }, {})
