import {
    COUNTRIES,
    EXCHANGES,
    getAllIndustries,
    getAllSectors,
    SECTOR_TO_INDUSTRY_MAP,
} from './constants'

type WiseScreenerInput = {
    type: 'multiValueInput' | 'booleanInput' | 'arithmeticInput'
    displayableName: string
    ctaText?: string // ie. add countries
    multiSelectData?: Record<string, string>[] | string[] | (() => string[])
}

export enum SCREENER_FILTER_KEYS {
    'MARKET_CAP' = 'MARKET_CAP',
    'PRICE' = 'PRICE',
    'BETA' = 'BETA',
    'VOLUME' = 'VOLUME',
    'DIVIDEND' = 'DIVIDEND',
    'IS_ETF' = 'IS_ETF',
    'IS_FUND' = 'IS_FUND',
    'IS_ACTIVELY_TRADING' = 'IS_ACTIVELY_TRADING',
    'SECTOR' = 'SECTOR',
    'INDUSTRY' = 'INDUSTRY',
    'COUNTRY' = 'COUNTRY',
    'EXCHANGE' = 'EXCHANGE',
    'REVENUE_PER_SHARE_TTM' = 'REVENUE_PER_SHARE_TTM',
    'NET_INCOME_PER_SHARE_TTM' = 'NET_INCOME_PER_SHARE_TTM',
    'OPERATING_CASH_FLOW_PER_SHARE_TTM' = 'OPERATING_CASH_FLOW_PER_SHARE_TTM',
    'FREE_CASH_FLOW_PER_SHARE_TTM' = 'FREE_CASH_FLOW_PER_SHARE_TTM',
    'CASH_PER_SHARE_TTM' = 'CASH_PER_SHARE_TTM',
    'BOOK_VALUE_PER_SHARE_TTM' = 'BOOK_VALUE_PER_SHARE_TTM',
    'TANGIBLE_BOOK_VALUE_PER_SHARE_TTM' = 'TANGIBLE_BOOK_VALUE_PER_SHARE_TTM',
    'SHAREHOLDERS_EQUITY_PER_SHARE_TTM' = 'SHAREHOLDERS_EQUITY_PER_SHARE_TTM',
    'INTEREST_DEBT_PER_SHARE_TTM' = 'INTEREST_DEBT_PER_SHARE_TTM',
    'MARKET_CAP_TTM' = 'MARKET_CAP_TTM',
    'ENTERPRISE_VALUE_TTM' = 'ENTERPRISE_VALUE_TTM',
    'PE_RATIO_TTM' = 'PE_RATIO_TTM',
    'PRICE_TO_SALES_RATIO_TTM' = 'PRICE_TO_SALES_RATIO_TTM',
    'POCFRATIO_TTM' = 'POCFRATIO_TTM',
    'PFCF_RATIO_TTM' = 'PFCF_RATIO_TTM',
    'PB_RATIO_TTM' = 'PB_RATIO_TTM',
    'PTB_RATIO_TTM' = 'PTB_RATIO_TTM',
    'EV_TO_SALES_TTM' = 'EV_TO_SALES_TTM',
    'ENTERPRISE_VALUE_OVER_EBITDA_TTM' = 'ENTERPRISE_VALUE_OVER_EBITDA_TTM',
    'EV_TO_OPERATING_CASH_FLOW_TTM' = 'EV_TO_OPERATING_CASH_FLOW_TTM',
    'EV_TO_FREE_CASH_FLOW_TTM' = 'EV_TO_FREE_CASH_FLOW_TTM',
    'EARNINGS_YIELD_TTM' = 'EARNINGS_YIELD_TTM',
    'FREE_CASH_FLOW_YIELD_TTM' = 'FREE_CASH_FLOW_YIELD_TTM',
    'DEBT_TO_EQUITY_TTM' = 'DEBT_TO_EQUITY_TTM',
    'DEBT_TO_ASSETS_TTM' = 'DEBT_TO_ASSETS_TTM',
    'NET_DEBT_TO_EBITDA_TTM' = 'NET_DEBT_TO_EBITDA_TTM',
    'CURRENT_RATIO_TTM' = 'CURRENT_RATIO_TTM',
    'INTEREST_COVERAGE_TTM' = 'INTEREST_COVERAGE_TTM',
    'INCOME_QUALITY_TTM' = 'INCOME_QUALITY_TTM',
    'DIVIDEND_YIELD_TTM' = 'DIVIDEND_YIELD_TTM',
    'DIVIDEND_YIELD_PERCENTAGE_TTM' = 'DIVIDEND_YIELD_PERCENTAGE_TTM',
    'PAYOUT_RATIO_TTM' = 'PAYOUT_RATIO_TTM',
    'SALES_GENERAL_AND_ADMINISTRATIVE_TO_REVENUE_TTM' = 'SALES_GENERAL_AND_ADMINISTRATIVE_TO_REVENUE_TTM',
    'RESEARCH_AND_DEVELOPMENT_TO_REVENUE_TTM' = 'RESEARCH_AND_DEVELOPMENT_TO_REVENUE_TTM',
    'INTANGIBLES_TO_TOTAL_ASSETS_TTM' = 'INTANGIBLES_TO_TOTAL_ASSETS_TTM',
    'CAPEX_TO_OPERATING_CASH_FLOW_TTM' = 'CAPEX_TO_OPERATING_CASH_FLOW_TTM',
    'CAPEX_TO_REVENUE_TTM' = 'CAPEX_TO_REVENUE_TTM',
    'CAPEX_TO_DEPRECIATION_TTM' = 'CAPEX_TO_DEPRECIATION_TTM',
    'STOCK_BASED_COMPENSATION_TO_REVENUE_TTM' = 'STOCK_BASED_COMPENSATION_TO_REVENUE_TTM',
    'GRAHAM_NUMBER_TTM' = 'GRAHAM_NUMBER_TTM',
    'ROIC_TTM' = 'ROIC_TTM',
    'RETURN_ON_TANGIBLE_ASSETS_TTM' = 'RETURN_ON_TANGIBLE_ASSETS_TTM',
    'GRAHAM_NET_NET_TTM' = 'GRAHAM_NET_NET_TTM',
    'WORKING_CAPITAL_TTM' = 'WORKING_CAPITAL_TTM',
    'TANGIBLE_ASSET_VALUE_TTM' = 'TANGIBLE_ASSET_VALUE_TTM',
    'NET_CURRENT_ASSET_VALUE_TTM' = 'NET_CURRENT_ASSET_VALUE_TTM',
    'INVESTED_CAPITAL_TTM' = 'INVESTED_CAPITAL_TTM',
    'AVERAGE_RECEIVABLES_TTM' = 'AVERAGE_RECEIVABLES_TTM',
    'AVERAGE_PAYABLES_TTM' = 'AVERAGE_PAYABLES_TTM',
    'AVERAGE_INVENTORY_TTM' = 'AVERAGE_INVENTORY_TTM',
    'DAYS_SALES_OUTSTANDING_TTM' = 'DAYS_SALES_OUTSTANDING_TTM',
    'DAYS_PAYABLES_OUTSTANDING_TTM' = 'DAYS_PAYABLES_OUTSTANDING_TTM',
    'DAYS_OF_INVENTORY_ON_HAND_TTM' = 'DAYS_OF_INVENTORY_ON_HAND_TTM',
    'RECEIVABLES_TURNOVER_TTM' = 'RECEIVABLES_TURNOVER_TTM',
    'PAYABLES_TURNOVER_TTM' = 'PAYABLES_TURNOVER_TTM',
    'INVENTORY_TURNOVER_TTM' = 'INVENTORY_TURNOVER_TTM',
    'ROE_TTM' = 'ROE_TTM',
    'CAPEX_PER_SHARE_TTM' = 'CAPEX_PER_SHARE_TTM',
    'DIVIDEND_PER_SHARE_TTM' = 'DIVIDEND_PER_SHARE_TTM',
    'DEBT_TO_MARKET_CAP_TTM' = 'DEBT_TO_MARKET_CAP_TTM',
}

type SCREENER_INPUT_TO_FORM_VALIDATOR = Record<
    SCREENER_FILTER_KEYS,
    WiseScreenerInput
>

export const SCREENER_INPUT_MAP: SCREENER_INPUT_TO_FORM_VALIDATOR = {
    // type 1: arithmetic
    [SCREENER_FILTER_KEYS.MARKET_CAP]: {
        type: 'arithmeticInput',
        displayableName: 'Market Cap',
    },
    [SCREENER_FILTER_KEYS.PRICE]: {
        type: 'arithmeticInput',
        displayableName: 'Price',
    },
    [SCREENER_FILTER_KEYS.BETA]: {
        type: 'arithmeticInput',
        displayableName: 'Beta',
    },
    [SCREENER_FILTER_KEYS.VOLUME]: {
        type: 'arithmeticInput',
        displayableName: 'Volume',
    },
    [SCREENER_FILTER_KEYS.DIVIDEND]: {
        type: 'arithmeticInput',
        displayableName: 'Dividend',
    },
    // type 2: boolean
    [SCREENER_FILTER_KEYS.IS_ETF]: {
        type: 'booleanInput',
        displayableName: 'Is ETF',
    },
    [SCREENER_FILTER_KEYS.IS_FUND]: {
        type: 'booleanInput',
        displayableName: 'Is Fund',
    },
    [SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING]: {
        type: 'booleanInput',
        displayableName: 'Is Actively Trading',
    },
    // type 3: multi value
    [SCREENER_FILTER_KEYS.SECTOR]: {
        type: 'multiValueInput',
        displayableName: 'Sector',
        ctaText: 'Add sectors',
        multiSelectData: getAllSectors,
    },
    [SCREENER_FILTER_KEYS.INDUSTRY]: {
        type: 'multiValueInput',
        displayableName: 'Industry',
        ctaText: 'Add industries',
        multiSelectData: getAllIndustries,
    },
    [SCREENER_FILTER_KEYS.COUNTRY]: {
        type: 'multiValueInput',
        displayableName: 'Country',
        ctaText: 'Add countries',
        multiSelectData: COUNTRIES,
    },
    [SCREENER_FILTER_KEYS.EXCHANGE]: {
        type: 'multiValueInput',
        displayableName: 'Exchange',
        ctaText: 'Add exchanges',
        multiSelectData: EXCHANGES,
    },
    // type 4: TTM
    [SCREENER_FILTER_KEYS.REVENUE_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Revenue Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.NET_INCOME_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Net Income Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.OPERATING_CASH_FLOW_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Operating Cash Flow Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.FREE_CASH_FLOW_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Free Cash Flow Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.CASH_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Cash Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.BOOK_VALUE_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Book Value Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.TANGIBLE_BOOK_VALUE_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Tangible Book Value Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.SHAREHOLDERS_EQUITY_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Shareholders Equity Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.INTEREST_DEBT_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Interest Debt Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.MARKET_CAP_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Market Cap TTM',
    },
    [SCREENER_FILTER_KEYS.ENTERPRISE_VALUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Enterprise Value TTM',
    },
    [SCREENER_FILTER_KEYS.PE_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'PE Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.PRICE_TO_SALES_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Price To Sales Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.POCFRATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'POCF Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.PFCF_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'PFCF Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.PB_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'PB Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.PTB_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'PTB Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.EV_TO_SALES_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'EV To Sales TTM',
    },
    [SCREENER_FILTER_KEYS.ENTERPRISE_VALUE_OVER_EBITDA_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Enterprise Value Over EBITDA TTM',
    },
    [SCREENER_FILTER_KEYS.EV_TO_OPERATING_CASH_FLOW_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'EV To Operating Cash Flow TTM',
    },
    [SCREENER_FILTER_KEYS.EV_TO_FREE_CASH_FLOW_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'EV To Free Cash Flow TTM',
    },
    [SCREENER_FILTER_KEYS.EARNINGS_YIELD_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Earnings Yield TTM',
    },
    [SCREENER_FILTER_KEYS.FREE_CASH_FLOW_YIELD_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Free Cash Flow Yield TTM',
    },
    [SCREENER_FILTER_KEYS.DEBT_TO_EQUITY_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Debt To Equity TTM',
    },
    [SCREENER_FILTER_KEYS.DEBT_TO_ASSETS_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Debt To Assets TTM',
    },
    [SCREENER_FILTER_KEYS.NET_DEBT_TO_EBITDA_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Net Debt To EBITDA TTM',
    },
    [SCREENER_FILTER_KEYS.CURRENT_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Current Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.INTEREST_COVERAGE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Interest Coverage TTM',
    },
    [SCREENER_FILTER_KEYS.INCOME_QUALITY_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Income Quality TTM',
    },
    [SCREENER_FILTER_KEYS.DIVIDEND_YIELD_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Dividend Yield TTM',
    },
    [SCREENER_FILTER_KEYS.DIVIDEND_YIELD_PERCENTAGE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Dividend Yield Percentage TTM',
    },
    [SCREENER_FILTER_KEYS.PAYOUT_RATIO_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Payout Ratio TTM',
    },
    [SCREENER_FILTER_KEYS.SALES_GENERAL_AND_ADMINISTRATIVE_TO_REVENUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Sales General And Administrative To Revenue TTM',
    },
    [SCREENER_FILTER_KEYS.RESEARCH_AND_DEVELOPMENT_TO_REVENUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Research And Development To Revenue TTM',
    },
    [SCREENER_FILTER_KEYS.INTANGIBLES_TO_TOTAL_ASSETS_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Intangibles To Total Assets TTM',
    },
    [SCREENER_FILTER_KEYS.CAPEX_TO_OPERATING_CASH_FLOW_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Capex To Operating Cash Flow TTM',
    },
    [SCREENER_FILTER_KEYS.CAPEX_TO_REVENUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Capex To Revenue TTM',
    },
    [SCREENER_FILTER_KEYS.CAPEX_TO_DEPRECIATION_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Capex To Depreciation TTM',
    },
    [SCREENER_FILTER_KEYS.STOCK_BASED_COMPENSATION_TO_REVENUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Stock Based Compensation To Revenue TTM',
    },
    [SCREENER_FILTER_KEYS.GRAHAM_NUMBER_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Graham Number TTM',
    },
    [SCREENER_FILTER_KEYS.ROIC_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'ROIC TTM',
    },
    [SCREENER_FILTER_KEYS.RETURN_ON_TANGIBLE_ASSETS_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Return On Tangible Assets TTM',
    },
    [SCREENER_FILTER_KEYS.GRAHAM_NET_NET_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Graham Net Net TTM',
    },
    [SCREENER_FILTER_KEYS.WORKING_CAPITAL_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Working Capital TTM',
    },
    [SCREENER_FILTER_KEYS.TANGIBLE_ASSET_VALUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Tangible Asset Value TTM',
    },
    [SCREENER_FILTER_KEYS.NET_CURRENT_ASSET_VALUE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Net Current Asset Value TTM',
    },
    [SCREENER_FILTER_KEYS.INVESTED_CAPITAL_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Invested Capital TTM',
    },
    [SCREENER_FILTER_KEYS.AVERAGE_RECEIVABLES_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Average Receivables TTM',
    },
    [SCREENER_FILTER_KEYS.AVERAGE_PAYABLES_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Average Payables TTM',
    },
    [SCREENER_FILTER_KEYS.AVERAGE_INVENTORY_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Average Inventory TTM',
    },
    [SCREENER_FILTER_KEYS.DAYS_SALES_OUTSTANDING_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Days Sales Outstanding TTM',
    },
    [SCREENER_FILTER_KEYS.DAYS_PAYABLES_OUTSTANDING_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Days Payables Outstanding TTM',
    },
    [SCREENER_FILTER_KEYS.DAYS_OF_INVENTORY_ON_HAND_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Days Of Inventory On Hand TTM',
    },
    [SCREENER_FILTER_KEYS.RECEIVABLES_TURNOVER_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Receivables Turnover TTM',
    },
    [SCREENER_FILTER_KEYS.PAYABLES_TURNOVER_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Payables Turnover TTM',
    },
    [SCREENER_FILTER_KEYS.INVENTORY_TURNOVER_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Inventory Turnover TTM',
    },
    [SCREENER_FILTER_KEYS.ROE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'ROE TTM',
    },
    [SCREENER_FILTER_KEYS.CAPEX_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Capex Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.DIVIDEND_PER_SHARE_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Dividend Per Share TTM',
    },
    [SCREENER_FILTER_KEYS.DEBT_TO_MARKET_CAP_TTM]: {
        type: 'arithmeticInput',
        displayableName: 'Debt To Market Cap TTM',
    },
}

export const DEFAULT_SELECTED_FILTER_OPTION_STATE = {
    [SCREENER_FILTER_KEYS.SECTOR]: [],
    [SCREENER_FILTER_KEYS.INDUSTRY]: [],
    [SCREENER_FILTER_KEYS.COUNTRY]: [],
    [SCREENER_FILTER_KEYS.EXCHANGE]: [],
}

export const getValidIndustries = (selectedSectors: string[]) => {
    if (!selectedSectors.length) return getAllIndustries()

    let industries = []
    selectedSectors.forEach((sector) => {
        if (SECTOR_TO_INDUSTRY_MAP[sector]) {
            industries = [...industries, ...SECTOR_TO_INDUSTRY_MAP[sector]]
        }
    })
    return industries
}

export const SCREENER_FILTER_KEY_TO_CAMEL_CASE: Record<string, string> = {
    [SCREENER_FILTER_KEYS.PRICE]: 'price',
    [SCREENER_FILTER_KEYS.MARKET_CAP]: 'marketCap',
    [SCREENER_FILTER_KEYS.BETA]: 'beta',
    [SCREENER_FILTER_KEYS.VOLUME]: 'volume',
    [SCREENER_FILTER_KEYS.DIVIDEND]: 'dividend',
    [SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING]: 'isActivelyTrading',
    [SCREENER_FILTER_KEYS.IS_ETF]: 'isEtf',
    [SCREENER_FILTER_KEYS.IS_FUND]: 'isFund',
    [SCREENER_FILTER_KEYS.SECTOR]: 'sector',
    [SCREENER_FILTER_KEYS.INDUSTRY]: 'industry',
    [SCREENER_FILTER_KEYS.COUNTRY]: 'country',
    [SCREENER_FILTER_KEYS.EXCHANGE]: 'exchange',
    [SCREENER_FILTER_KEYS.REVENUE_PER_SHARE_TTM]: 'revenuePerShareTTM',
    [SCREENER_FILTER_KEYS.NET_INCOME_PER_SHARE_TTM]: 'netIncomePerShareTTM',
    [SCREENER_FILTER_KEYS.OPERATING_CASH_FLOW_PER_SHARE_TTM]:
        'operatingCashFlowPerShareTTM',
    [SCREENER_FILTER_KEYS.FREE_CASH_FLOW_PER_SHARE_TTM]:
        'freeCashFlowPerShareTTM',
    [SCREENER_FILTER_KEYS.CASH_PER_SHARE_TTM]: 'cashPerShareTTM',
    [SCREENER_FILTER_KEYS.BOOK_VALUE_PER_SHARE_TTM]: 'bookValuePerShareTTM',
    [SCREENER_FILTER_KEYS.TANGIBLE_BOOK_VALUE_PER_SHARE_TTM]:
        'tangibleBookValuePerShareTTM',
    [SCREENER_FILTER_KEYS.SHAREHOLDERS_EQUITY_PER_SHARE_TTM]:
        'shareholdersEquityPerShareTTM',
    [SCREENER_FILTER_KEYS.INTEREST_DEBT_PER_SHARE_TTM]:
        'interestDebtPerShareTTM',
    [SCREENER_FILTER_KEYS.MARKET_CAP_TTM]: 'marketCapTTM',
    [SCREENER_FILTER_KEYS.ENTERPRISE_VALUE_TTM]: 'enterpriseValueTTM',
    [SCREENER_FILTER_KEYS.PE_RATIO_TTM]: 'peRatioTTM',
    [SCREENER_FILTER_KEYS.PRICE_TO_SALES_RATIO_TTM]: 'priceToSalesRatioTTM',
    [SCREENER_FILTER_KEYS.POCFRATIO_TTM]: 'pocfRatioTTM',
    [SCREENER_FILTER_KEYS.PFCF_RATIO_TTM]: 'pfcfRatioTTM',
    [SCREENER_FILTER_KEYS.PB_RATIO_TTM]: 'pbRatioTTM',
    [SCREENER_FILTER_KEYS.PTB_RATIO_TTM]: 'ptbRatioTTM',
    [SCREENER_FILTER_KEYS.EV_TO_SALES_TTM]: 'evToSalesTTM',
    [SCREENER_FILTER_KEYS.ENTERPRISE_VALUE_OVER_EBITDA_TTM]:
        'enterpriseValueOverEBITDATTM',
    [SCREENER_FILTER_KEYS.EV_TO_OPERATING_CASH_FLOW_TTM]:
        'evToOperatingCashFlowTTM',
    [SCREENER_FILTER_KEYS.EV_TO_FREE_CASH_FLOW_TTM]: 'evToFreeCashFlowTTM',
    [SCREENER_FILTER_KEYS.EARNINGS_YIELD_TTM]: 'earningsYieldTTM',
    [SCREENER_FILTER_KEYS.FREE_CASH_FLOW_YIELD_TTM]: 'freeCashFlowYieldTTM',
    [SCREENER_FILTER_KEYS.DEBT_TO_EQUITY_TTM]: 'debtToEquityTTM',
    [SCREENER_FILTER_KEYS.DEBT_TO_ASSETS_TTM]: 'debtToAssetsTTM',
    [SCREENER_FILTER_KEYS.NET_DEBT_TO_EBITDA_TTM]: 'netDebtToEBITDATTM',
    [SCREENER_FILTER_KEYS.CURRENT_RATIO_TTM]: 'currentRatioTTM',
    [SCREENER_FILTER_KEYS.INTEREST_COVERAGE_TTM]: 'interestCoverageTTM',
    [SCREENER_FILTER_KEYS.INCOME_QUALITY_TTM]: 'incomeQualityTTM',
    [SCREENER_FILTER_KEYS.DIVIDEND_YIELD_TTM]: 'dividendYieldTTM',
    [SCREENER_FILTER_KEYS.DIVIDEND_YIELD_PERCENTAGE_TTM]:
        'dividendYieldPercentageTTM',
    [SCREENER_FILTER_KEYS.PAYOUT_RATIO_TTM]: 'payoutRatioTTM',
    [SCREENER_FILTER_KEYS.SALES_GENERAL_AND_ADMINISTRATIVE_TO_REVENUE_TTM]:
        'salesGeneralAndAdministrativeToRevenueTTM',
    [SCREENER_FILTER_KEYS.RESEARCH_AND_DEVELOPMENT_TO_REVENUE_TTM]:
        'researchAndDevelopmentToRevenueTTM',
    [SCREENER_FILTER_KEYS.INTANGIBLES_TO_TOTAL_ASSETS_TTM]:
        'intangiblesToTotalAssetsTTM',
    [SCREENER_FILTER_KEYS.CAPEX_TO_OPERATING_CASH_FLOW_TTM]:
        'capexToOperatingCashFlowTTM',
    [SCREENER_FILTER_KEYS.CAPEX_TO_REVENUE_TTM]: 'capexToRevenueTTM',
    [SCREENER_FILTER_KEYS.CAPEX_TO_DEPRECIATION_TTM]: 'capexToDepreciationTTM',
    [SCREENER_FILTER_KEYS.STOCK_BASED_COMPENSATION_TO_REVENUE_TTM]:
        'stockBasedCompensationToRevenueTTM',
    [SCREENER_FILTER_KEYS.GRAHAM_NUMBER_TTM]: 'grahamNumberTTM',
    [SCREENER_FILTER_KEYS.ROIC_TTM]: 'roicTTM',
    [SCREENER_FILTER_KEYS.RETURN_ON_TANGIBLE_ASSETS_TTM]:
        'returnOnTangibleAssetsTTM',
    [SCREENER_FILTER_KEYS.GRAHAM_NET_NET_TTM]: 'grahamNetNetTTM',
    [SCREENER_FILTER_KEYS.WORKING_CAPITAL_TTM]: 'workingCapitalTTM',
    [SCREENER_FILTER_KEYS.TANGIBLE_ASSET_VALUE_TTM]: 'tangibleAssetValueTTM',
    [SCREENER_FILTER_KEYS.NET_CURRENT_ASSET_VALUE_TTM]:
        'netCurrentAssetValueTTM',
    [SCREENER_FILTER_KEYS.INVESTED_CAPITAL_TTM]: 'investedCapitalTTM',
    [SCREENER_FILTER_KEYS.AVERAGE_RECEIVABLES_TTM]: 'averageReceivablesTTM',
    [SCREENER_FILTER_KEYS.AVERAGE_PAYABLES_TTM]: 'averagePayablesTTM',
    [SCREENER_FILTER_KEYS.AVERAGE_INVENTORY_TTM]: 'averageInventoryTTM',
    [SCREENER_FILTER_KEYS.DAYS_SALES_OUTSTANDING_TTM]:
        'daysSalesOutstandingTTM',
    [SCREENER_FILTER_KEYS.DAYS_PAYABLES_OUTSTANDING_TTM]:
        'daysPayablesOutstandingTTM',
    [SCREENER_FILTER_KEYS.DAYS_OF_INVENTORY_ON_HAND_TTM]:
        'daysOfInventoryOnHandTTM',
    [SCREENER_FILTER_KEYS.RECEIVABLES_TURNOVER_TTM]: 'receivablesTurnoverTTM',
    [SCREENER_FILTER_KEYS.PAYABLES_TURNOVER_TTM]: 'payablesTurnoverTTM',
    [SCREENER_FILTER_KEYS.INVENTORY_TURNOVER_TTM]: 'inventoryTurnoverTTM',
    [SCREENER_FILTER_KEYS.ROE_TTM]: 'roeTTM',
    [SCREENER_FILTER_KEYS.CAPEX_PER_SHARE_TTM]: 'capexPerShareTTM',
    [SCREENER_FILTER_KEYS.DIVIDEND_PER_SHARE_TTM]: 'dividendPerShareTTM',
    [SCREENER_FILTER_KEYS.DEBT_TO_MARKET_CAP_TTM]: 'debtToMarketCapTTM',
}
