export const COUNTRIES = [
    { name: 'Anguilla', short: 'AI' },
    { name: 'Argentina', short: 'AR' },
    { name: 'Australia', short: 'AU' },
    { name: 'Austria', short: 'AT' },
    { name: 'Azerbaijan', short: 'AZ' },
    { name: 'Bangladesh', short: 'BD' },
    { name: 'Barbados', short: 'BB' },
    { name: 'Belgium', short: 'BE' },
    { name: 'Bermuda', short: 'BM' },
    { name: 'Brazil', short: 'BR' },
    { name: 'British Virgin Islands', short: 'VG' },
    { name: 'Bulgaria', short: 'BG' },
    { name: 'Cambodia', short: 'KH' },
    { name: 'Canada', short: 'CA' },
    { name: 'Cayman Islands', short: 'KY' },
    { name: 'Chile', short: 'CL' },
    { name: 'China', short: 'CN' },
    { name: 'Colombia', short: 'CO' },
    { name: 'Costa Rica', short: 'CR' },
    { name: "Côte d'Ivoire", short: 'CI' },
    { name: 'Curaçao', short: 'CW' },
    { name: 'Cyprus', short: 'CY' },
    { name: 'Czechia', short: 'CZ' },
    { name: 'Denmark', short: 'DK' },
    { name: 'Finland', short: 'FI' },
    { name: 'France', short: 'FR' },
    { name: 'Gabon', short: 'GA' },
    { name: 'Georgia', short: 'GE' },
    { name: 'Germany', short: 'DE' },
    { name: 'Gibraltar', short: 'GI' },
    { name: 'Greece', short: 'GR' },
    { name: 'Guernsey', short: 'GG' },
    { name: 'Hong Kong', short: 'HK' },
    { name: 'Hungary', short: 'HU' },
    { name: 'Iceland', short: 'IS' },
    { name: 'India', short: 'IN' },
    { name: 'Indonesia', short: 'ID' },
    { name: 'Ireland', short: 'IE' },
    { name: 'Israel', short: 'IL' },
    { name: 'Isle of Man', short: 'IM' },
    { name: 'Italy', short: 'IT' },
    { name: 'Japan', short: 'JP' },
    { name: 'Jersey', short: 'JE' },
    { name: 'Jordan', short: 'JO' },
    { name: 'Liechtenstein', short: 'LI' },
    { name: 'Lithuania', short: 'LT' },
    { name: 'Luxembourg', short: 'LU' },
    { name: 'Macao', short: 'MO' },
    { name: 'Malaysia', short: 'MY' },
    { name: 'Malta', short: 'MT' },
    { name: 'Mauritius', short: 'MU' },
    { name: 'Mexico', short: 'MX' },
    { name: 'Monaco', short: 'MC' },
    { name: 'Mongolia', short: 'MN' },
    { name: 'Morocco', short: 'MA' },
    { name: 'Netherlands', short: 'NL' },
    { name: 'New Zealand', short: 'NZ' },
    { name: 'Nigeria', short: 'NG' },
    { name: 'Norway', short: 'NO' },
    { name: 'Panama', short: 'PA' },
    { name: 'Papua New Guinea', short: 'PG' },
    { name: 'Peru', short: 'PE' },
    { name: 'Poland', short: 'PL' },
    { name: 'Portugal', short: 'PT' },
    { name: 'Puerto Rico', short: 'PR' },
    { name: 'Russia', short: 'RU' },
    { name: 'Senegal', short: 'SN' },
    { name: 'Singapore', short: 'SG' },
    { name: 'Slovakia', short: 'SK' },
    { name: 'Solomon Islands', short: 'SB' },
    { name: 'South Africa', short: 'ZA' },
    { name: 'Spain', short: 'ES' },
    { name: 'Sweden', short: 'SE' },
    { name: 'Switzerland', short: 'CH' },
    { name: 'Taiwan', short: 'TW' },
    { name: 'Thailand', short: 'TH' },
    { name: 'The Bahamas', short: 'BS' },
    { name: 'The Cook Islands', short: 'CK' },
    { name: 'The Dominican Republic', short: 'DO' },
    { name: 'The Falkland Islands', short: 'FK' },
    { name: 'The Faroe Islands', short: 'FO' },
    { name: 'The Philippines', short: 'PH' },
    { name: 'The Republic of Korea', short: 'KR' },
    { name: 'The Turks and Caicos Islands', short: 'TC' },
    { name: 'The United Arab Emirates', short: 'AE' },
    { name: 'Togo', short: 'TG' },
    { name: 'Turkey', short: 'TR' },
    { name: 'Ukraine', short: 'UA' },
    { name: 'United Kingdom of Great Britain', short: 'GB' },
    { name: 'United States of America', short: 'US' },
    { name: 'Uruguay', short: 'UY' },
    { name: 'Viet Nam', short: 'VN' },
    { name: 'Virgin Islands (U.S.)', short: 'VI' },
    { name: 'Zambia', short: 'ZM' },
]

export const EXCHANGES = [
    { name: 'New York Stock Exchange', short: 'nyse' },
    { name: 'Nasdaq', short: 'nasdaq' },
    { name: 'Shanghai Stock Exchange', short: 'shh' },
    { name: 'Euronext', short: 'euronext' },
    { name: 'Japan Exchange Group', short: 'jpx' },
    { name: 'Shenzhen Stock Exchange', short: 'shz' },
    { name: 'Hong Kong Stock Exchange', short: 'hkse' },
    { name: 'London Stock Exchange', short: 'lse' },
    { name: 'National Stock Exchange', short: 'nse' },
    { name: 'Toronto Stock Exchange', short: 'tsx' },
    { name: 'Saudi Stock Exchange', short: 'sau' },
    { name: 'SIX Swiss Exchange', short: 'six' },
    { name: 'Mutual Funds', short: 'mutual_fund' },
    { name: 'ETFs', short: 'etf' },
    { name: 'American Stock Exchange', short: 'amex' },
    { name: 'Index Exchange', short: 'index' },
    { name: 'Commodities Exchange', short: 'commodity' },
    { name: 'Foreign Exchange', short: 'forex' },
    { name: 'Johannesburg Stock Exchange', short: 'jse' },
    { name: 'Johannesburg Stock Exchange', short: 'jnb' },
    { name: 'Korean Stock Exchange', short: 'ksc' },
    { name: 'Kosdaq', short: 'kosdaq' },
    { name: 'Xetra', short: 'xetra' },
    { name: 'IOB London stock exchange', short: 'iob' },
    { name: 'Taiwan Stock Exchange', short: 'tai' },
    { name: 'Warsaw Stock Exchange', short: 'wse' },
    { name: 'Australian Securities Exchange', short: 'asx' },
    { name: 'Taipei Stock Exchange', short: 'two' },
    { name: 'Oslo Stock Exchange', short: 'ose' },
    { name: 'Stockholm Stock Exchange', short: 'sto' },
    { name: 'Milan Stock Exchange', short: 'mil' },
    { name: 'Jakarta Stock Exchange', short: 'jkt' },
    { name: 'Helsinki Stock Exchange', short: 'hel' },
    { name: 'Multi Commodity Exchange India', short: 'mcx' },
    { name: 'Stock Exchange of Thailand', short: 'set' },
    { name: 'Copenhagen Stock Exchange', short: 'cph' },
    { name: 'Tel Aviv Stock Exchange', short: 'tlv' },
    { name: 'Istanbul Stock Exchange', short: 'ist' },
    { name: 'Santiago Stock Exchange', short: 'sgo' },
    { name: 'Doha Exchange', short: 'doh' },
    { name: 'Austria Exchange', short: 'vie' },
    { name: 'EBS Swiss Exchange', short: 'ebs' },
    { name: 'Euronext Amsterdam', short: 'ams' },
    { name: 'Euronext Brussels', short: 'bru' },
    { name: 'Athens Exchange', short: 'ath' },
    { name: 'Euronext Lisbon', short: 'lis' },
    { name: 'Sweden Stock Exchange', short: 'sat' },
    { name: 'Prague Stock Exchange', short: 'pra' },
    { name: 'Hamburg Stock Exchange', short: 'ham' },
    { name: 'First North Iceland', short: 'ice' },
    { name: 'São Paulo Exchange', short: 'sao' },
    { name: 'Madrid Stock Exchange', short: 'mce/bme' },
    { name: 'Germany Xetra', short: 'ger' },
]

export const SECTOR_TO_INDUSTRY_MAP: Record<string, string[]> = {
    'Basic Materials': [
        'Steel',
        'Silver',
        'Paper, Lumber & Forest Products',
        'Other Precious Metals',
        'Industrial Materials',
        'Gold',
        'Copper',
        'Construction Materials',
        'Chemicals - Specialty',
        'Chemicals',
        'Aluminum',
        'Agricultural Inputs',
    ],
    'Communication Services': [
        'Telecommunications Services',
        'Publishing',
        'Internet Content & Information',
        'Entertainment',
        'Electronic - Gaming & Multimedia',
        'Broadcasting',
        'Advertising Agencies',
    ],
    'Consumer Cyclical': [
        'Travel Services',
        'Travel Lodging',
        'Specialty Retail',
        'Restaurants',
        'Residential Construction',
        'Personal Products & Services',
        'Packaging & Containers',
        'Luxury Goods',
        'Leisure',
        'Home Improvement',
        'Gambling, Resorts & Casinos',
        'Furnishings, Fixtures & Appliances',
        'Department Stores',
        'Auto - Recreational Vehicles',
        'Auto - Parts',
        'Auto - Manufacturers',
        'Auto - Dealerships',
        'Apparel - Retail',
        'Apparel - Manufacturers',
        'Apparel - Footwear & Accessories',
    ],
    'Consumer Defensive': [
        'Tobacco',
        'Packaged Foods',
        'Household & Personal Products',
        'Grocery Stores',
        'Food Distribution',
        'Food Confectioners',
        'Education & Training Services',
        'Discount Stores',
        'Beverages - Wineries & Distilleries',
        'Beverages - Non-Alcoholic',
        'Beverages - Alcoholic',
        'Agricultural Farm Products',
    ],
    Energy: [
        'Uranium',
        'Solar',
        'Oil & Gas Refining & Marketing',
        'Oil & Gas Midstream',
        'Oil & Gas Integrated',
        'Oil & Gas Exploration & Production',
        'Oil & Gas Equipment & Services',
        'Oil & Gas Energy',
        'Oil & Gas Drilling',
        'Coal',
    ],
    'Financial Services': [
        'Shell Companies',
        'Investment - Banking & Investment Services',
        'Insurance - Specialty',
        'Insurance - Reinsurance',
        'Insurance - Property & Casualty',
        'Insurance - Life',
        'Insurance - Diversified',
        'Insurance - Brokers',
        'Financial - Mortgages',
        'Financial - Diversified',
        'Financial - Data & Stock Exchanges',
        'Financial - Credit Services',
        'Financial - Conglomerates',
        'Financial - Capital Markets',
        'Banks - Regional',
        'Banks - Diversified',
        'Banks',
        'Asset Management - Leveraged',
        'Asset Management - Income',
        'Asset Management - Global',
        'Asset Management - Cryptocurrency',
        'Asset Management - Bonds',
        'Asset Management',
    ],
    Healthcare: [
        'Medical - Specialties',
        'Medical - Pharmaceuticals',
        'Medical - Instruments & Supplies',
        'Medical - Healthcare Plans',
        'Medical - Healthcare Information Services',
        'Medical - Equipment & Services',
        'Medical - Distribution',
        'Medical - Diagnostics & Research',
        'Medical - Devices',
        'Medical - Care Facilities',
        'Drug Manufacturers - Specialty & Generic',
        'Drug Manufacturers - General',
        'Biotechnology',
    ],
    Industrials: [
        'Waste Management',
        'Trucking',
        'Staffing & Employment Services',
        'Specialty Business Services',
        'Security & Protection Services',
        'Rental & Leasing Services',
        'Railroads',
        'Marine Shipping',
        'Manufacturing - Tools & Accessories',
        'Manufacturing - Textiles',
        'Manufacturing - Miscellaneous',
        'Manufacturing - Metal Fabrication',
        'Integrated Freight & Logistics',
        'Industrial - Specialties',
        'Industrial - Pollution & Treatment Controls',
        'Industrial - Machinery',
        'Industrial - Infrastructure Operations',
        'Industrial - Distribution',
        'Industrial - Capital Goods',
        'General Transportation',
        'Engineering & Construction',
        'Electrical Equipment & Parts',
        'Consulting Services',
        'Construction',
        'Conglomerates',
        'Business Equipment & Supplies',
        'Airlines, Airports & Air Services',
        'Agricultural - Machinery',
        'Aerospace & Defense',
    ],
    'Real Estate': [
        'REIT - Specialty',
        'REIT - Retail',
        'REIT - Residential',
        'REIT - Office',
        'REIT - Mortgage',
        'REIT - Industrial',
        'REIT - Hotel & Motel',
        'REIT - Healthcare Facilities',
        'REIT - Diversified',
        'Real Estate - Services',
        'Real Estate - General',
        'Real Estate - Diversified',
        'Real Estate - Development',
    ],
    Technology: [
        'Technology Distributors',
        'Software - Services',
        'Software - Infrastructure',
        'Software - Application',
        'Semiconductors',
        'Media & Entertainment',
        'Information Technology Services',
        'Hardware, Equipment & Parts',
        'Electronic Gaming & Multimedia',
        'Consumer Electronics',
        'Computer Hardware',
        'Communication Equipment',
    ],
    Utilities: [
        'Renewable Utilities',
        'Regulated Water',
        'Regulated Gas',
        'Regulated Electric',
        'Independent Power Producers',
        'General Utilities',
        'Diversified Utilities',
    ],
}

export const getAllIndustries = (): string[] => {
    const allIndustries = new Set()
    Object.values(SECTOR_TO_INDUSTRY_MAP).forEach((industries) => {
        industries.forEach((industry) => {
            allIndustries.add(industry)
        })
    })
    return Array.from(allIndustries) as string[]
}
export const getAllSectors = (): string[] => {
    return Object.keys(SECTOR_TO_INDUSTRY_MAP)
}
export const SCREENER_API_KEY = 'f9a03f75a09c3b0908b4a0e2f3bfd140'
