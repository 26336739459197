import React, { useEffect, useMemo } from 'react'
import TemplateListItem from '../../components/TemplateListItem/TemplateListItem'
import { httpClient } from '../../utils/http'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import {
    useAuthStore,
    useErrorStore,
    useLoadingStore,
    useSuccessStore,
} from '../../state/store'
import { STABLEBREAD_TEMPLATE } from './constants'
import { StyledPageDescription } from './styles'
import { useAmplitudeTrack, AmplitudeEvents } from '../../hooks/amplitude'
const LIMIT = 10

export default function Templates() {
    const { currentUser } = useAuthStore((state) => state)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const { setSuccessMessage } = useSuccessStore((state) => state)
    const trackEvent = useAmplitudeTrack()

    const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage } =
        useInfiniteQuery(
            ['templates'],
            async ({ pageParam = 0 }) => {
                const res: AxiosResponse<{
                    templates: Template[]
                    next: number
                }> = await httpClient.get('/template', {
                    params: {
                        limit: LIMIT,
                        skip: pageParam,
                        platform: 'EXCEL',
                    },
                })
                return res.data
            },
            {
                getNextPageParam: (lastPage) => lastPage.next,
                refetchOnWindowFocus: false,
            }
        )

    useEffect(() => {
        const onScroll = () => {
            const atBottomOfPage =
                window.scrollY + window.innerHeight + 5 >=
                document.body.scrollHeight
            if (atBottomOfPage) {
                if (hasNextPage) {
                    fetchNextPage()
                }
            }
        }
        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [hasNextPage])

    useEffect(() => {
        if (isLoading || isFetching) {
            setLoading({ isLoading: true, message: 'Loading templates...' })
        } else if (isError) {
            setError({
                hasError: true,
                errorMessage: 'Error loading templates.',
            })
        } else {
            setLoading({ isLoading: false, message: '' })
            setError({ hasError: false, errorMessage: '' })
        }
    }, [isLoading, isFetching, isError])

    const handleClick = async (id, platform, url) => {
        if (platform !== 'EXCEL') {
            setError({
                hasError: true,
                errorMessage: 'Cannot apply Google Sheets template in Excel.',
            })
            return
        }
        window.open(url)
        trackEvent(AmplitudeEvents.APPLY_TEMPLATE, {
            template_id: id,
        })
        setSuccessMessage('Template downloaded! Please check your downloads.')
    }

    const templates = useMemo(() => {
        return data?.pages.map((page) => page.templates).flat()
    }, [data?.pages])

    const templatesToRender = (): any[] => {
        const templatesWithLockedAtTop = [
            <TemplateListItem
                key={STABLEBREAD_TEMPLATE.id}
                template={STABLEBREAD_TEMPLATE}
                handleClick={() => {
                    const stablebread = window.open(
                        STABLEBREAD_TEMPLATE.url,
                        '_blank'
                    )
                    if (stablebread) stablebread.focus()
                    trackEvent(AmplitudeEvents.APPLY_TEMPLATE, {
                        template_id: STABLEBREAD_TEMPLATE.id,
                    })
                }}
                isLocked
            />,
            templates?.map((template) => (
                <TemplateListItem
                    key={template.id}
                    template={template}
                    handleClick={handleClick}
                    isLocked={false}
                />
            )),
        ]
        return currentUser.isSubscribed
            ? templatesWithLockedAtTop
            : templatesWithLockedAtTop.reverse()
    }

    return (
        <div>
            <StyledPageDescription>
                Access pre-made templates you can customize to your liking
            </StyledPageDescription>
            <div>
                <div>{templatesToRender()}</div>
            </div>
        </div>
    )
}
