import { RxGrid } from 'react-icons/rx'
import styled from 'styled-components'

export const StyledFilterItem = styled.div`
    display: flex;
    align-items: center;
`

export const StyledFilterContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colours.background.primary};
    color: black;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-size: 12px;
    border-radius: 4px;
`

export const StyledFilterOption = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 0;
`

export const StyledInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StyledLeftInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;
`

export const StyledFilterTitle = styled.span`
    white-space: nowrap;
    margin-right: 4px;
`

export const StyledArithmeticFilter = styled.select`
    border: none;
    font-size: 12px;
    outline: none;
    color: ${({ theme }) => theme.colours.font.green};
`

export const StyledArithmeticInput = styled.input`
    width: 100%;
    outline: none;
    border: 1px solid ${({ theme }) => theme.colours.border.grey};
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const StyledDeleteButton = styled.div`
    cursor: pointer;
    padding: 4px;
    background-color: ${({ theme }) => theme.colours.background.blue};
    border-radius: 50%;
    margin-left: 4px;
    display: flex;
`

export const StyledSelectedFilterOption = styled.div`
    color: black;
    background-color: ${({ theme }) => theme.colours.background.blue_accent};
    display: flex;
    align-items: center;
    letter-spacing: 0;
    padding: 4px;
    padding-right: 6px;
    margin-right: 4px;
    border-radius: 4px;
    margin-bottom: 2px;
`

export const StyledAddFilterValueButton = styled.div`
    color: gray;
    letter-spacing: 0;
    padding: 4px;
    margin-right: 4px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 2px;
    &:hover {
        background-color: ${({ theme }) => theme.colours.background.tertiary};
    }
`

export const StyledPageDescription = styled.div`
    font-size: 14px;
    padding: 16px 0;
`

export const StyledTabsContainer = styled.div`
    position: relative;
    padding: 0 20px;
    margin-bottom: 20px;
`

export const StyledTabs = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colours.background.blue};
    justify-content: center;
    border-radius: 4px;
`

export const StyledSeparator = styled.span`
    margin: 0 4px;
`

export const StyledTab = styled.div`
    padding: 8px;
    color: ${({ theme }) => theme.colours.font.primary};
    width: 50%;
    text-align: center;
    cursor: pointer;
    z-index: 1;
`

export const StyledTabHighlight = styled.span`
    bottom: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colours.background.secondary};
    transition: all 0.3s ease 0s;
    position: absolute;
    display: block;
    z-index: 0;
    border-radius: 4px;
`

export const StyledClickHere = styled.div`
    font-size: 12px;
    margin-bottom: 8px;
    width: '100%';
    text-align: center;
    position: relative;
    top: 40;
`

export const StyledLink = styled.a`
    font-size: 12px;
    margin-bottom: 8px;
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colours.font.green};
`

export const StyledAddAnotherFilter = styled.div`
    font-size: 12px;
    margin: 8px 0px;
    cursor: pointer;
`

export const StyledErrorMessage = styled.div`
    font-size: 12px;
    margin: 8px 0px;
    color: ${({ theme }) => theme.colours.font.red_accent};
`

export const StyledRangeLabel = styled.label`
    line-height: 30px;
`

export const StyledCustomInputContainer = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
`

export const StyledCustomInput = styled.input`
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-radius: 4px;
    box-sizing: content-box;
    margin-bottom: 4px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:focus {
        outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const StyledRxGridContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colours.background.primary};
    padding: 0 8px;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 4px;
`

export const StyledRxGrid = styled(RxGrid)`
    cursor: pointer;
    color: ${({ theme }) => theme.colours.font.dark_grey};
    font-size: 20px;
`

export const StyledFullVersionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 16px;
`
export const StyledFullVersionButton = styled.button`
    padding: 2% 4%;
    border: none;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colours.background.blue};
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    font-size: 14px;
    color: ${({ theme }) => theme.colours.font.primary};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export const StyledInfo = styled.div`
    text-align: justify;
    color: ${({ theme }) => theme.colours.font.primary};
    font-size: 13px;
    padding-bottom: 16px;
`

export const StyledOverlay = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: ${({ theme }) =>
        theme.colours.background.transparent_black};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledPopupContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.primary};
    width: 90%;
    border-radius: 4px;
    color: ${({ theme }) => theme.colours.font.black};
    z-index: 1001;
    overflow: hidden;
`

export const StyledPopupContent = styled.div`
    padding: 8px 8px 0 8px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 80vh;
`

export const StyledModalHeader = styled.div`
    margin-bottom: 8px;
`

export const StyledSearchInput = styled.input`
    width: 100%;
    outline: none;
    border: 1px solid ${({ theme }) => theme.colours.border.grey};
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const StyledPopupData = styled.div`
    height: 300px;
    overflow-y: scroll;
`

export const StyledCheckboxLabel = styled.label`
    color: ${({ theme }) => theme.colours.font.black};
    font-weight: lighter;
    font-size: 10px;
    padding-bottom: 0px;
`

export const StyledCloseButtonContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.primary};
    padding: 8px;
    box-shadow: 0px 1px 5px ${({ theme }) => theme.colours.background.tertiary},
        0px -1px 5px ${({ theme }) => theme.colours.background.tertiary};
`

export const StyledCloseButton = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
    background-color: ${({ theme }) => theme.colours.background.blue};
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    padding: 8px;
`

export const StyledLinkFooter = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
`
