export const cellMap = {
    //income statement
    'period-Income Statement': {
        col: 3,
        row: 0,
        format: '',
        borderTop: false,
        borderBottom: false,
        merge: true,
        bold: false,
    },
    'date-Income Statement': {
        col: 4,
        row: 0,
        format: '',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'revenue-Income Statement': {
        col: 6,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'costOfRevenue-Income Statement': {
        col: 8,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'grossProfit-Income Statement': {
        col: 9,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'grossProfitRatio-Income Statement': {
        col: 10,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'researchAndDevelopmentExpenses-Income Statement': {
        col: 12,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'sellingGeneralAndAdministrativeExpenses-Income Statement': {
        col: 13,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'generalAndAdministrativeExpenses-Income Statement': {
        col: 14,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'sellingAndMarketingExpenses-Income Statement': {
        col: 15,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherExpenses-Income Statement': {
        col: 16,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingExpenses-Income Statement': {
        col: 17,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'costAndExpenses-Income Statement': {
        col: 19,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'interestExpense-Income Statement': {
        col: 22,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'depreciationAndAmortization-Income Statement': {
        col: 23,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'ebitda-Income Statement': {
        col: 20,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'ebitdaratio-Income Statement': {
        col: 21,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingIncome-Income Statement': {
        col: 24,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'operatingIncomeRatio-Income Statement': {
        col: 25,
        row: 0,
        format: '0.00%',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalOtherIncomeExpensesNet-Income Statement': {
        col: 27,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'incomeBeforeTax-Income Statement': {
        col: 28,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'incomeBeforeTaxRatio-Income Statement': {
        col: 29,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'incomeTaxExpense-Income Statement': {
        col: 30,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netIncome-Income Statement': {
        col: 31,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },
    'netIncomeRatio-Income Statement': {
        col: 33,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'eps-Income Statement': {
        col: 34,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'epsdiluted-Income Statement': {
        col: 35,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'weightedAverageShsOut-Income Statement': {
        col: 36,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'weightedAverageShsOutDil-Income Statement': {
        col: 37,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },

    // balance sheet
    'period-Balance Sheet': {
        col: 3,
        row: 0,
        format: '',
        borderTop: false,
        borderBottom: false,
        merge: true,
        bold: false,
    },
    'date-Balance Sheet': {
        col: 4,
        row: 0,
        format: '',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'cashAndCashEquivalents-Balance Sheet': {
        col: 6,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'shortTermInvestments-Balance Sheet': {
        col: 7,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'cashAndShortTermInvestments-Balance Sheet': {
        col: 8,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netReceivables-Balance Sheet': {
        col: 9,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'inventory-Balance Sheet': {
        col: 10,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherCurrentAssets-Balance Sheet': {
        col: 11,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalCurrentAssets-Balance Sheet': {
        col: 12,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'propertyPlantEquipmentNet-Balance Sheet': {
        col: 14,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'goodwill-Balance Sheet': {
        col: 15,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'intangibleAssets-Balance Sheet': {
        col: 16,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'goodwillAndIntangibleAssets-Balance Sheet': {
        col: 17,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'longTermInvestments-Balance Sheet': {
        col: 18,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'taxAssets-Balance Sheet': {
        col: 19,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherNonCurrentAssets-Balance Sheet': {
        col: 20,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalNonCurrentAssets-Balance Sheet': {
        col: 21,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'otherAssets-Balance Sheet': {
        col: 23,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalAssets-Balance Sheet': {
        col: 24,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },
    'accountPayables-Balance Sheet': {
        col: 26,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'shortTermDebt-Balance Sheet': {
        col: 27,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'taxPayables-Balance Sheet': {
        col: 28,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'deferredRevenue-Balance Sheet': {
        col: 29,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherCurrentLiabilities-Balance Sheet': {
        col: 30,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalCurrentLiabilities-Balance Sheet': {
        col: 31,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'longTermDebt-Balance Sheet': {
        col: 33,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'deferredRevenueNonCurrent-Balance Sheet': {
        col: 34,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'deferredTaxLiabilitiesNonCurrent-Balance Sheet': {
        col: 35,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherNonCurrentLiabilities-Balance Sheet': {
        col: 36,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalNonCurrentLiabilities-Balance Sheet': {
        col: 37,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'otherLiabilities-Balance Sheet': {
        col: 39,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalLiabilities-Balance Sheet': {
        col: 40,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },
    'commonStock-Balance Sheet': {
        col: 42,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'retainedEarnings-Balance Sheet': {
        col: 43,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'accumulatedOtherComprehensiveIncomeLoss-Balance Sheet': {
        col: 44,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'othertotalStockholdersEquity-Balance Sheet': {
        col: 45,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalStockholdersEquity-Balance Sheet': {
        col: 46,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },
    'totalLiabilitiesAndStockholdersEquity-Balance Sheet': {
        col: 48,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalInvestments-Balance Sheet': {
        col: 49,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'totalDebt-Balance Sheet': {
        col: 50,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netDebt-Balance Sheet': {
        col: 51,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },

    // cash flow
    'period-Cash Flow': {
        col: 3,
        row: 0,
        format: '',
        borderTop: false,
        borderBottom: false,
        merge: true,
        bold: false,
    },
    'date-Cash Flow': {
        col: 4,
        row: 0,
        format: '',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netIncome-Cash Flow': {
        col: 6,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },
    'depreciationAndAmortization-Cash Flow': {
        col: 7,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'deferredIncomeTax-Cash Flow': {
        col: 8,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'stockBasedCompensation-Cash Flow': {
        col: 9,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'changeInWorkingCapital-Cash Flow': {
        col: 10,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'accountsReceivables-Cash Flow': {
        col: 11,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'inventory-Cash Flow': {
        col: 12,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'accountsPayables-Cash Flow': {
        col: 13,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherWorkingCapital-Cash Flow': {
        col: 14,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherNonCashItems-Cash Flow': {
        col: 15,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netCashProvidedByOperatingActivities-Cash Flow': {
        col: 16,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'investmentsInPropertyPlantAndEquipment-Cash Flow': {
        col: 18,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'acquisitionsNet-Cash Flow': {
        col: 19,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'purchasesOfInvestments-Cash Flow': {
        col: 20,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'salesMaturitiesOfInvestments-Cash Flow': {
        col: 21,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherInvestingActivites-Cash Flow': {
        col: 22,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netCashUsedForInvestingActivites-Cash Flow': {
        col: 23,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'debtRepayment-Cash Flow': {
        col: 25,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'commonStockIssued-Cash Flow': {
        col: 26,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'commonStockRepurchased-Cash Flow': {
        col: 27,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'dividendsPaid-Cash Flow': {
        col: 28,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'otherFinancingActivites-Cash Flow': {
        col: 29,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netCashUsedProvidedByFinancingActivities-Cash Flow': {
        col: 30,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: true,
    },
    'effectOfForexChangesOnCash-Cash Flow': {
        col: 32,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netChangeInCash-Cash Flow': {
        col: 33,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'cashAtEndOfPeriod-Cash Flow': {
        col: 34,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'cashAtBeginningOfPeriod-Cash Flow': {
        col: 35,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingCashFlow-Cash Flow': {
        col: 36,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'capitalExpenditure-Cash Flow': {
        col: 37,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'freeCashFlow-Cash Flow': {
        col: 38,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: true,
        borderBottom: true,
        merge: false,
        bold: true,
    },

    // key metrics
    'period-Key Metrics': {
        col: 3,
        row: 0,
        format: '',
        borderTop: false,
        borderBottom: false,
        merge: true,
        bold: false,
    },
    'date-Key Metrics': {
        col: 4,
        row: 0,
        format: '',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'revenuePerShare-Key Metrics': {
        col: 5,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netIncomePerShare-Key Metrics': {
        col: 6,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingCashFlowPerShare-Key Metrics': {
        col: 7,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'freeCashFlowPerShare-Key Metrics': {
        col: 8,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'cashPerShare-Key Metrics': {
        col: 9,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'bookValuePerShare-Key Metrics': {
        col: 10,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tangibleBookValuePerShare-Key Metrics': {
        col: 11,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'shareholdersEquityPerShare-Key Metrics': {
        col: 12,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'interestDebtPerShare-Key Metrics': {
        col: 13,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'marketCap-Key Metrics': {
        col: 14,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'enterpriseValue-Key Metrics': {
        col: 15,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'peRatio-Key Metrics': {
        col: 16,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'priceToSalesRatio-Key Metrics': {
        col: 17,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'pocfratio-Key Metrics': {
        col: 18,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'pfcfRatio-Key Metrics': {
        col: 19,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'pbRatio-Key Metrics': {
        col: 20,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'ptbRatio-Key Metrics': {
        col: 21,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'evToSales-Key Metrics': {
        col: 22,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'enterpriseValueOverEBITDA-Key Metrics': {
        col: 23,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'evToOperatingCashFlow-Key Metrics': {
        col: 24,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'evToFreeCashFlow-Key Metrics': {
        col: 25,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'earningsYield-Key Metrics': {
        col: 26,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'freeCashFlowYield-Key Metrics': {
        col: 27,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'debtToEquity-Key Metrics': {
        col: 28,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'debtToAssets-Key Metrics': {
        col: 29,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netDebtToEBITDA-Key Metrics': {
        col: 30,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'currentRatio-Key Metrics': {
        col: 31,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'interestCoverage-Key Metrics': {
        col: 32,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'incomeQuality-Key Metrics': {
        col: 33,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'dividendYield-Key Metrics': {
        col: 34,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'payoutRatio-Key Metrics': {
        col: 35,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'salesGeneralAndAdministrativeToRevenue-Key Metrics': {
        col: 36,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'researchAndDdevelopementToRevenue-Key Metrics': {
        col: 37,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'intangiblesToTotalAssets-Key Metrics': {
        col: 38,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'capexToOperatingCashFlow-Key Metrics': {
        col: 39,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'capexToRevenue-Key Metrics': {
        col: 40,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'capexToDepreciation-Key Metrics': {
        col: 41,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'stockBasedCompensationToRevenue-Key Metrics': {
        col: 42,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'grahamNumber-Key Metrics': {
        col: 43,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'roic-Key Metrics': {
        col: 44,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'returnOnTangibleAssets-Key Metrics': {
        col: 45,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'grahamNetNet-Key Metrics': {
        col: 46,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'workingCapital-Key Metrics': {
        col: 47,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tangibleAssetValue-Key Metrics': {
        col: 48,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netCurrentAssetValue-Key Metrics': {
        col: 49,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'averageReceivables-Key Metrics': {
        col: 50,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'averagePayables-Key Metrics': {
        col: 51,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'averageInventory-Key Metrics': {
        col: 52,
        row: 0,
        format: '#,##0.00_);(#,##0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'daysSalesOutstanding-Key Metrics': {
        col: 53,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'daysPayablesOutstanding-Key Metrics': {
        col: 54,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'daysOfInventoryOnHand-Key Metrics': {
        col: 55,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'receivablesTurnover-Key Metrics': {
        col: 56,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'payablesTurnover-Key Metrics': {
        col: 57,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'inventoryTurnover-Key Metrics': {
        col: 58,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'roe-Key Metrics': {
        col: 59,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'capexPerShare-Key Metrics': {
        col: 60,
        row: 0,
        format: '0.00_);(0.00)',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },

    // financial growth
    'period-Financial Growth': {
        col: 3,
        row: 0,
        format: '',
        borderTop: false,
        borderBottom: false,
        merge: true,
        bold: false,
    },
    'date-Financial Growth': {
        col: 4,
        row: 0,
        format: '',
        borderTop: true,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'revenueGrowth-Financial Growth': {
        col: 6,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'grossProfitGrowth-Financial Growth': {
        col: 7,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'ebitgrowth-Financial Growth': {
        col: 8,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingIncomeGrowth-Financial Growth': {
        col: 9,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'netIncomeGrowth-Financial Growth': {
        col: 10,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'epsgrowth-Financial Growth': {
        col: 11,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'epsdilutedGrowth-Financial Growth': {
        col: 12,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'weightedAverageSharesGrowth-Financial Growth': {
        col: 13,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'weightedAverageSharesDilutedGrowth-Financial Growth': {
        col: 14,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'dividendsperShareGrowth-Financial Growth': {
        col: 15,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'operatingCashFlowGrowth-Financial Growth': {
        col: 16,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'freeCashFlowGrowth-Financial Growth': {
        col: 17,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tenYRevenueGrowthPerShare-Financial Growth': {
        col: 18,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'fiveYRevenueGrowthPerShare-Financial Growth': {
        col: 19,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'threeYRevenueGrowthPerShare-Financial Growth': {
        col: 20,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tenYOperatingCFGrowthPerShare-Financial Growth': {
        col: 21,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'fiveYOperatingCFGrowthPerShare-Financial Growth': {
        col: 22,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'threeYOperatingCFGrowthPerShare-Financial Growth': {
        col: 23,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tenYNetIncomeGrowthPerShare-Financial Growth': {
        col: 24,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'fiveYNetIncomeGrowthPerShare-Financial Growth': {
        col: 25,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'threeYNetIncomeGrowthPerShare-Financial Growth': {
        col: 26,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tenYShareholdersEquityGrowthPerShare-Financial Growth': {
        col: 27,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'fiveYShareholdersEquityGrowthPerShare-Financial Growth': {
        col: 28,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'threeYShareholdersEquityGrowthPerShare-Financial Growth': {
        col: 29,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'tenYDividendperShareGrowthPerShare-Financial Growth': {
        col: 30,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'fiveYDividendperShareGrowthPerShare-Financial Growth': {
        col: 31,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'threeYDividendperShareGrowthPerShare-Financial Growth': {
        col: 32,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'receivablesGrowth-Financial Growth': {
        col: 33,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'assetGrowth-Financial Growth': {
        col: 34,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'inventoryGrowth-Financial Growth': {
        col: 35,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'bookValueperShareGrowth-Financial Growth': {
        col: 36,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'debtGrowth-Financial Growth': {
        col: 37,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'rdexpenseGrowth-Financial Growth': {
        col: 38,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
    'sgaexpensesGrowth-Financial Growth': {
        col: 39,
        row: 0,
        format: '0.00%',
        borderTop: false,
        borderBottom: false,
        merge: false,
        bold: false,
    },
}

// //https://financialmodelingprep.com/developer/docs/stock-api/
// .set('symbol', 'quote')
// .set('price', 'quote')
// .set('change', 'quote')
// .set('previousclose', 'quote')
// .set('daylow', 'quote')
// .set('dayhigh', 'quote')
// .set('yearhigh', 'quote')
// .set('yearlow', 'quote')
// .set('marketcap', 'quote')
// .set('priceavg50', 'quote')
// .set('priceavg200', 'quote')
// .set('volume', 'quote')
// .set('avgvolume', 'quote')
