import React, { useState } from 'react'
import styles from './StockTable.module.css'
import { NavLink } from 'react-router-dom'
import { formatNumber, NumberType } from '../../pages/CompanyProfile/utils'

type QuoteData = {
    avgVolume: number
    change: number
    changesPercentage: number
    dayHigh: number
    dayLow: number
    earningsAnnouncement: string | null
    eps: number
    exchange: string
    marketCap: number
    name: string
    open: number
    pe: number
    previousClose: number
    price: number
    priceAvg50: number
    priceAvg200: number
    sharesOutstanding: number
    symbol: string
    timestamp: number
    volume: number
    yearHigh: number
    yearLow: number
}

type Props = {
    data: QuoteData[]
    isExpandable?: boolean
    defaultDataLength: number
}

export default function StockTable(props: Props) {
    const { data, isExpandable, defaultDataLength } = props
    const [isExpanded, setIsExpanded] = useState(false)

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    const visibleData = isExpanded ? data : data?.slice(0, defaultDataLength)
    return (
        <div className={styles.container}>
            <div
                className={styles.gridContainer}
                style={{ paddingBottom: isExpandable ? 8 : 0 }}
            >
                <div className={`${styles.gridHeader} ${styles.gridItem}`}>
                    Ticker
                </div>
                <div className={`${styles.gridHeader} ${styles.gridItem}`}>
                    Last
                </div>
                <div className={`${styles.gridHeader} ${styles.gridItem}`}>
                    Change
                </div>
                <div className={`${styles.gridHeader} ${styles.gridItem}`}>
                    Volume
                </div>
                <div
                    className={`${styles.gridHeader} ${styles.gridItem}`}
                ></div>

                {visibleData?.map((row, index) => (
                    <React.Fragment key={index}>
                        <NavLink
                            className={styles.gridItem}
                            style={{ color: '#6CCBF3' }}
                            to={`/feature/company-profile/${row.symbol}`}
                        >
                            {row.symbol}
                        </NavLink>
                        <div className={styles.gridItem}>
                            {formatNumber(row.price, NumberType.Number)}
                        </div>
                        <div
                            className={styles.gridItem}
                            style={{
                                color:
                                    row.changesPercentage > 0
                                        ? '#48D74B'
                                        : '#FF4D4D',
                            }}
                        >
                            {formatNumber(
                                row.changesPercentage,
                                NumberType.Percent
                            )}
                        </div>
                        <div className={styles.gridItem}>
                            {formatNumber(row.volume, NumberType.Number)}
                        </div>
                        <div className={styles.gridItem}>
                            <NavLink
                                to={`/feature/company-profile/${row.symbol}`}
                                className={styles.link}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4.16671 3.16634C3.90149 3.16634 3.64714 3.2717 3.4596 3.45923C3.27206 3.64677 3.16671 3.90112 3.16671 4.16634V11.833C3.16671 12.0982 3.27206 12.3526 3.4596 12.5401C3.64714 12.7276 3.90149 12.833 4.16671 12.833H11.8334C12.0986 12.833 12.3529 12.7276 12.5405 12.5401C12.728 12.3526 12.8334 12.0982 12.8334 11.833V9.16634C12.8334 8.98953 12.9036 8.81996 13.0286 8.69494C13.1537 8.56991 13.3232 8.49967 13.5 8.49967C13.6769 8.49967 13.8464 8.56991 13.9714 8.69494C14.0965 8.81996 14.1667 8.98953 14.1667 9.16634V11.833C14.1667 12.4518 13.9209 13.0453 13.4833 13.4829C13.0457 13.9205 12.4522 14.1663 11.8334 14.1663H4.16671C3.54787 14.1663 2.95438 13.9205 2.51679 13.4829C2.07921 13.0453 1.83337 12.4518 1.83337 11.833V4.16634C1.83337 3.5475 2.07921 2.95401 2.51679 2.51643C2.95438 2.07884 3.54787 1.83301 4.16671 1.83301H6.83337C7.01018 1.83301 7.17975 1.90325 7.30478 2.02827C7.4298 2.15329 7.50004 2.32286 7.50004 2.49967C7.50004 2.67649 7.4298 2.84605 7.30478 2.97108C7.17975 3.0961 7.01018 3.16634 6.83337 3.16634H4.16671ZM8.50004 2.49967C8.50004 2.32286 8.57028 2.15329 8.6953 2.02827C8.82033 1.90325 8.9899 1.83301 9.16671 1.83301H13.5C13.6769 1.83301 13.8464 1.90325 13.9714 2.02827C14.0965 2.15329 14.1667 2.32286 14.1667 2.49967V6.83301C14.1667 7.00982 14.0965 7.17939 13.9714 7.30441C13.8464 7.42944 13.6769 7.49967 13.5 7.49967C13.3232 7.49967 13.1537 7.42944 13.0286 7.30441C12.9036 7.17939 12.8334 7.00982 12.8334 6.83301V4.10901L9.63804 7.30434C9.57654 7.36801 9.50298 7.4188 9.42164 7.45374C9.34031 7.48868 9.25283 7.50707 9.16431 7.50784C9.07579 7.50861 8.988 7.49174 8.90607 7.45822C8.82414 7.4247 8.74971 7.3752 8.68711 7.3126C8.62452 7.25001 8.57501 7.17557 8.54149 7.09364C8.50797 7.01171 8.4911 6.92393 8.49187 6.83541C8.49264 6.74689 8.51103 6.65941 8.54597 6.57807C8.58091 6.49674 8.6317 6.42317 8.69537 6.36167L11.8907 3.16634H9.16671C8.9899 3.16634 8.82033 3.0961 8.6953 2.97108C8.57028 2.84605 8.50004 2.67649 8.50004 2.49967Z"
                                        fill="#48D74B"
                                    />
                                </svg>
                            </NavLink>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            {isExpandable && data?.length > defaultDataLength && (
                <div className={styles.toggleButton}>
                    {isExpanded ? (
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={handleToggleExpand}
                            style={{ cursor: 'pointer' }}
                        >
                            <circle cx="11" cy="11" r="11" fill="#4A5474" />
                            <circle
                                cx="11"
                                cy="11"
                                r="10.5417"
                                stroke="white"
                                strokeOpacity="0.75"
                                strokeWidth="0.916667"
                            />
                            <path
                                d="M14.8531 12.8532C14.7594 12.947 14.6322 12.9996 14.4996 12.9996C14.367 12.9996 14.2399 12.947 14.1461 12.8532L10.9996 9.70672L7.85312 12.8532C7.807 12.901 7.75182 12.9391 7.69082 12.9653C7.62982 12.9915 7.56421 13.0053 7.49782 13.0058C7.43143 13.0064 7.36559 12.9938 7.30414 12.9686C7.24269 12.9435 7.18687 12.9064 7.13992 12.8594C7.09297 12.8125 7.05585 12.7566 7.03071 12.6952C7.00557 12.6337 6.99292 12.5679 6.99349 12.5015C6.99407 12.4351 7.00786 12.3695 7.03407 12.3085C7.06027 12.2475 7.09836 12.1923 7.14612 12.1462L10.6461 8.64622C10.7399 8.55248 10.867 8.49982 10.9996 8.49982C11.1322 8.49982 11.2594 8.55248 11.3531 8.64622L14.8531 12.1462C14.9469 12.24 14.9995 12.3671 14.9995 12.4997C14.9995 12.6323 14.9469 12.7595 14.8531 12.8532Z"
                                fill="white"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={handleToggleExpand}
                            style={{ cursor: 'pointer' }}
                        >
                            <circle cx="11" cy="11" r="11" fill="#4A5474" />
                            <circle
                                cx="11"
                                cy="11"
                                r="10.5417"
                                stroke="white"
                                strokeOpacity="0.75"
                                strokeWidth="0.916667"
                            />
                            <path
                                d="M7.14688 9.14678C7.24065 9.05305 7.3678 9.00039 7.50038 9.00039C7.63296 9.00039 7.76012 9.05305 7.85388 9.14678L11.0004 12.2933L14.1469 9.14678C14.193 9.09903 14.2482 9.06094 14.3092 9.03473C14.3702 9.00853 14.4358 8.99474 14.5022 8.99416C14.5686 8.99358 14.6344 9.00623 14.6959 9.03137C14.7573 9.05651 14.8131 9.09364 14.8601 9.14059C14.907 9.18753 14.9442 9.24336 14.9693 9.30481C14.9944 9.36626 15.0071 9.4321 15.0065 9.49849C15.0059 9.56487 14.9921 9.63048 14.9659 9.69149C14.9397 9.75249 14.9016 9.80766 14.8539 9.85378L11.3539 13.3538C11.2601 13.4475 11.133 13.5002 11.0004 13.5002C10.8678 13.5002 10.7406 13.4475 10.6469 13.3538L7.14688 9.85378C7.05315 9.76002 7.00049 9.63287 7.00049 9.50028C7.00049 9.3677 7.05315 9.24055 7.14688 9.14678Z"
                                fill="white"
                            />
                        </svg>
                    )}
                </div>
            )}
        </div>
    )
}
