import React from 'react'
import styles from './LoadingModal.module.css'
import { ScaleLoader } from 'react-spinners'

type Props = {
    message: string
    isShown: boolean
}

export default function LoadingModal(props: Props) {
    if (props.isShown) {
        return (
            <div className={styles.loadingContainer}>
                <ScaleLoader color={'#1FB723'} loading={true} />
                <p className={styles.message}>{props.message}</p>
            </div>
        )
    } else {
        return null
    }
}
