import React, { useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import {
    StyledChildrenContainer,
    StyledCollapsibleContainer,
    StyledLabelContainer,
} from './styles'

type Props = {
    label: string
    children: React.ReactNode
}

export default function Collapsible(props: Props) {
    const { label, children } = props
    const [isOpened, setIsOpened] = useState(false)

    return (
        <StyledCollapsibleContainer $isOpened={isOpened}>
            <StyledLabelContainer onClick={() => setIsOpened(!isOpened)}>
                <div>{label}</div>
                {isOpened ? <BiChevronUp /> : <BiChevronDown />}
            </StyledLabelContainer>
            {isOpened ? (
                <StyledChildrenContainer>{children}</StyledChildrenContainer>
            ) : null}
        </StyledCollapsibleContainer>
    )
}
