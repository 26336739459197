// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nN9ZX9CvpklceJZ4QZmF {\n    height: 10px;\n    padding: 12px 8px;\n    font-weight: 300;\n    font-size: 14px;\n    border: 1px solid #aaa;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    letter-spacing: 0.5px;\n}\n\n.dY3uFni9aCQU_AEOQagD {\n    background-color: white;\n    position: absolute;\n    z-index: 100;\n    box-sizing: border-box;\n    overflow-y: scroll;\n}\n\n.dY3uFni9aCQU_AEOQagD::-webkit-scrollbar {\n    display: block;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.dY3uFni9aCQU_AEOQagD {\n    -ms-overflow-style: scrollbar; /* IE and Edge */\n    scrollbar-width: thin; /* Firefox */\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/AutoSuggest/AutoSuggest.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA,4CAA4C;AAC5C;IACI,6BAA6B,EAAE,gBAAgB;IAC/C,qBAAqB,EAAE,YAAY;AACvC","sourcesContent":[".input {\n    height: 10px;\n    padding: 12px 8px;\n    font-weight: 300;\n    font-size: 14px;\n    border: 1px solid #aaa;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    letter-spacing: 0.5px;\n}\n\n.autoSuggestSelections {\n    background-color: white;\n    position: absolute;\n    z-index: 100;\n    box-sizing: border-box;\n    overflow-y: scroll;\n}\n\n.autoSuggestSelections::-webkit-scrollbar {\n    display: block;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.autoSuggestSelections {\n    -ms-overflow-style: scrollbar; /* IE and Edge */\n    scrollbar-width: thin; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "nN9ZX9CvpklceJZ4QZmF",
	"autoSuggestSelections": "dY3uFni9aCQU_AEOQagD"
};
export default ___CSS_LOADER_EXPORT___;
