import React from 'react'
import styles from './TemplateListItem.module.css'
import { AiFillLock } from 'react-icons/ai'

type TemplateProps = {
    template: Template
    handleClick: (id: string, platform: string, url: string) => void
    isLocked: boolean // used for templates that are promotional and don't interface directly with the spreadsheet
}

function TemplateListItem(props: TemplateProps) {
    const { handleClick, template } = props

    return (
        <div
            className={styles.template}
            onClick={() => {
                handleClick(template.id, template.platform, template.url)
            }}
        >
            {props.isLocked ? (
                <div className={styles.templateOverlay}>
                    <AiFillLock
                        style={{ height: 70, width: 70, marginBottom: 70 }}
                    />
                </div>
            ) : null}
            <div>
                {template.image_url ? (
                    <img
                        className={styles.templateImage}
                        src={template.image_url}
                    />
                ) : null}
            </div>
            <div>
                <div className={styles.templateTop}>
                    <div className={styles.templateName}>{template.name}</div>
                </div>
                <div className={styles.templateDescription}>
                    {template.description}
                </div>
            </div>
        </div>
    )
}

export default TemplateListItem
