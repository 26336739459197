import React, { useEffect, useState } from 'react'
import './Login.css'
import '../../utils/styles/styles.css'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import ActionButton from '../../components/ActionButton/ActionButton'
import { httpClient } from '../../utils/http'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAuthStore, useErrorStore, useLoadingStore } from '../../state/store'
import GoogleButton from '../../components/GoogleButton/GoogleButton'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export default function Login() {
    // *********** state *********** //
    const { setAuth, token, logout, currentUser } = useAuthStore(
        (state) => state
    )
    const [hasCheckedToken, setHasCheckedTokenValue] = useState<boolean>(false)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const [showPassword, setShowPassword] = useState(false)

    const { register, handleSubmit } = useForm<LoginFields>()

    const navigate = useNavigate()

    useEffect(() => {
        // this is a little fucked but basically the redirect url send us back to this page but within the embeded dailog window so we use this check here to relay the code back to the parent window which is also this window...
        if (
            new URLSearchParams(window.location.search).get('code') !== null &&
            new URLSearchParams(window.location.search).get('code') !== ''
        ) {
            Office.context.ui.messageParent(
                new URLSearchParams(window.location.search).get('code')
            )
        }

        const checkIfTokenIsValid = async (token: string) => {
            try {
                await httpClient.post(
                    '/public/auth/validate',
                    {
                        token: token,
                        platform: 'EXCEL',
                    },
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                window.localStorage.setItem('token', token)
                setAuth(token)
                mixpanel.track('LOGIN_SUCCESS', {
                    distinct_id: currentUser.email,
                    isSubscribed: currentUser.isSubscribed,
                    subscriptionType: currentUser.subscriptionType,
                    platform: 'EXCEL',
                })
                navigate('/feature/statement-dump')
            } catch (e) {
                window.localStorage.removeItem('token')
                logout()
            } finally {
                setHasCheckedTokenValue(true)
            }
        }
        // checking to see if you have a token and if you do try and redirect
        if (token) {
            checkIfTokenIsValid(token)
        } else {
            setHasCheckedTokenValue(true)
        }
    }, [])

    const onSubmit: SubmitHandler<LoginFields> = async (data) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: { token: string } } = await httpClient.post(
                '/public/auth/authenticate',
                {
                    email: data.email.trim(),
                    password: data.password,
                    platform: 'EXCEL',
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            const token = response.data.token
            // unfortunately becuse of how excel works, we cant just read the state from the store inside the function file
            // we have to write to local storage and then read from local storage to communicate with the function file
            // stupid runtime bs
            window.localStorage.setItem('token', token)
            setAuth(token)
            mixpanel.track('LOGIN_SUCCESS', {
                distinct_id: currentUser.email,
                isSubscribed: currentUser.isSubscribed,
                subscriptionType: currentUser.subscriptionType,
                platform: 'EXCEL',
            })
            setLoading({ isLoading: false, message: '' })
            navigate('/feature/statement-dump')
        } catch (e) {
            setLoading({ isLoading: false, message: '' })
            if (e.response.status === 404) {
                setError({
                    hasError: true,
                    errorMessage: `Please create an account at <a
                        href="https://wisesheets.io/signup"
                        target="_blank"
                    >
                        www.wisesheets.io
                    </a>`,
                })
            } else if (e.response.status === 400) {
                setError({
                    hasError: true,
                    errorMessage: `Email or password is incorrect. Try passwordless login?`,
                })
            } else {
                setError({
                    hasError: true,
                    errorMessage:
                        'Something went wrong while logging in. Please try again.',
                })
            }
        }
    }

    const onSso = async (code) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: { token: string } } = await httpClient.post(
                '/public/auth/google-excel-sso',
                {
                    code: code,
                    uri:
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/taskpane.html',
                    // uri: 'https://localhost:3000/taskpane.html',
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )

            const token = response.data.token
            // unfortunately becuse of how excel works, we cant just read the state from the store inside the function file
            // we have to write to local storage and then read from local storage to communicate with the function file
            // stupid runtime bs
            window.localStorage.setItem('token', token)
            setAuth(token)
            mixpanel.track('LOGIN_SUCCESS', {
                distinct_id: currentUser.email,
                isSubscribed: currentUser.isSubscribed,
                subscriptionType: currentUser.subscriptionType,
                platform: 'EXCEL',
            })
            setLoading({ isLoading: false, message: '' })
            navigate('/feature/statement-dump')
        } catch (e) {
            setLoading({ isLoading: false, message: '' })
            if (e.response.status === 404) {
                setError({
                    hasError: true,
                    errorMessage: `Please create an account at <a
                        href="https://wisesheets.io/signup"
                        target="_blank"
                    >
                        www.wisesheets.io
                    </a>`,
                })
            } else if (e.response.status === 400) {
                setError({
                    hasError: true,
                    errorMessage: `Email or password is incorrect. Try passwordless login?`,
                })
            } else {
                setError({
                    hasError: true,
                    errorMessage:
                        'Something went wrong while logging in. Please try again.',
                })
            }
        }
    }

    let dialog

    const processMessage = async (arg) => {
        dialog.close()
        onSso(arg.message)
    }

    const signInWithGoogle = () => {
        Office.context.ui.displayDialogAsync(
            window.location.protocol +
                '//' +
                window.location.host +
                '/taskpane.html#sso',
            {
                height: 60,
                width: 40,
                promptBeforeOpen: false,
                displayInIframe: false,
            },
            function (asyncResult) {
                if (asyncResult.error) {
                    console.log(asyncResult.error)
                }
                dialog = asyncResult.value
                dialog.addEventHandler(
                    Office.EventType.DialogMessageReceived,
                    processMessage
                )
            }
        )
    }

    return (
        <>
            {hasCheckedToken ? (
                <div className="content">
                    <div className="login-header">
                        <div className="white">
                            Wise<span className="wise-green">sheets</span>
                        </div>
                    </div>
                    <form
                        className="login-form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="input">
                            <input
                                type="text"
                                id="email"
                                placeholder="Email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'invalid email address',
                                    },
                                })}
                            />
                        </div>
                        <div className="input" style={{ position: 'relative' }}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                placeholder="Password"
                                {...register('password', { required: true })}
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                {showPassword ? (
                                    <FaEyeSlash fontSize={16} />
                                ) : (
                                    <FaEye fontSize={16} />
                                )}
                            </button>
                        </div>
                        <div className="login-button">
                            <ActionButton type={'submit'} title={'Login'} />
                        </div>
                    </form>
                    <div className="orContainer">
                        <span className="line" />
                        <span className="or">or</span>
                        <span className="line" />
                    </div>
                    <div>
                        <GoogleButton
                            onClick={() => {
                                signInWithGoogle()
                            }}
                        />
                    </div>
                    <div style={{ marginTop: '8px' }}>
                        <ActionButton
                            type={'button'}
                            title={'Login with one-time passcode'}
                            action={() => {
                                navigate('/passwordless-login')
                            }}
                            color={'#465484'}
                        />
                    </div>

                    <div className="new-user">
                        New to Wisesheets?
                        <a href="https://wisesheets.io/signup" target="_blank">
                            {' '}
                            Make an account
                        </a>
                        <br />
                        Forgot password?
                        <a
                            href="https://wisesheets.io/forgot-password"
                            target="_blank"
                        >
                            {' '}
                            Reset password
                        </a>
                    </div>
                    <br />
                    <div className="value-prop">
                        No more copy-paste. No more updating data manually.
                        Instant access to financial data in your spreadsheet.
                    </div>
                    <br />
                    <br />
                    <div className="policies">
                        <a
                            href="https://wisesheets.io/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href="https://wisesheets.io/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                    </div>
                </div>
            ) : null}
        </>
    )
}
