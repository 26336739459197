import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
    HiDocumentSearch,
    HiDatabase,
    HiCalculator,
    HiTemplate,
    HiDotsHorizontal,
    HiLogout,
} from 'react-icons/hi'
import { MdEmail } from 'react-icons/md'
import { BsClipboard2DataFill } from 'react-icons/bs'
import { RiDiscordFill } from 'react-icons/ri'
import { IconType } from 'react-icons'
import { useAuthStore } from '../../state/store'
import OutsideAlerter from '../../hooks/ClickOutside'

import { useTheme } from 'styled-components'
import {
    StyledDropDownLink,
    StyledDropDownLinkIcon,
    StyledDropDownMenu,
    StyledMoreContainer,
    StyledNavContainer,
    StyledNavIcon,
    StyledNavName,
    StyledNavItem,
} from './styles'

type NavItemProps = {
    to: string
    Icon: IconType
    name: string
}

const NavItem = (props: NavItemProps) => {
    const { to, Icon, name } = props
    const theme = useTheme()
    return (
        <StyledNavItem as={NavLink} to={to}>
            {({ isActive }) => {
                return (
                    <>
                        <StyledNavIcon>
                            <Icon
                                color={
                                    isActive
                                        ? theme.colours.font.green
                                        : theme.colours.font.blue
                                }
                            />
                        </StyledNavIcon>
                        <StyledNavName>{name}</StyledNavName>
                    </>
                )
            }}
        </StyledNavItem>
    )
}

const DropDownMenu = () => {
    const [isOpened, SetIsOpened] = useState(false)
    const { logout } = useAuthStore((state) => state)
    const pressLogout = () => {
        window.localStorage.removeItem('token')
        logout()
    }
    const theme = useTheme()

    return (
        <StyledMoreContainer>
            <OutsideAlerter
                onpress={() => {
                    SetIsOpened(false)
                }}
            >
                <StyledNavItem onClick={() => SetIsOpened(!isOpened)}>
                    <StyledNavIcon>
                        <HiDotsHorizontal color={theme.colours.font.blue} />
                    </StyledNavIcon>
                    <StyledNavName>More</StyledNavName>
                </StyledNavItem>
                {isOpened ? (
                    <StyledDropDownMenu>
                        <StyledDropDownLink
                            target="_blank"
                            href="https://wisesheets.io/available-data"
                        >
                            <StyledDropDownLinkIcon as={BsClipboard2DataFill} />
                            Available Data
                        </StyledDropDownLink>
                        <StyledDropDownLink
                            target="_blank"
                            href="mailto:info@wisesheets.io"
                        >
                            <StyledDropDownLinkIcon as={MdEmail} />
                            Contact
                        </StyledDropDownLink>
                        <StyledDropDownLink
                            target="_blank"
                            href="https://discord.com/invite/z8PgPqhCxs"
                        >
                            <StyledDropDownLinkIcon as={RiDiscordFill} />
                            Discord
                        </StyledDropDownLink>
                        <StyledDropDownLink
                            as={NavLink}
                            to="/"
                            onClick={pressLogout}
                        >
                            <StyledDropDownLinkIcon as={HiLogout} />
                            Logout
                        </StyledDropDownLink>
                    </StyledDropDownMenu>
                ) : null}
            </OutsideAlerter>
        </StyledMoreContainer>
    )
}

export default function Nav() {
    return (
        <StyledNavContainer>
            <NavItem
                to="/feature/statement-dump"
                Icon={HiDocumentSearch}
                name="Financials"
            />
            <NavItem
                to={'/feature/function-builder'}
                Icon={HiCalculator}
                name="Functions"
            />
            <NavItem to="/feature/screener" Icon={HiDatabase} name="Screener" />
            <NavItem
                to="/feature/templates"
                Icon={HiTemplate}
                name="Templates"
            />
            <DropDownMenu />
        </StyledNavContainer>
    )
}
