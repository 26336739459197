import axios from 'axios'
import { WISESHEETS_API_URL } from '../../constants'
import { useAuthStore } from '../state/store'

export const httpClient = axios.create({
    baseURL: WISESHEETS_API_URL,
    timeout: 60000,
})

// Add a request interceptor
httpClient.interceptors.request.use(
    async (config) => {
        // Add auth token to header of each request if available
        const token = useAuthStore.getState().token
        if (config.headers !== undefined) {
            if (token !== null) {
                config.headers.Authorization = token
            }
        }
        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
)
