import React from 'react'
import { AiFillTool } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import styles from './RefreshErrors.module.css'

export default function RefreshErrors(props: { refreshErrors: () => void }) {
    const { refreshErrors } = props
    return (
        <div
            className={styles.refreshErrors}
            style={
                Office.context.platform === Office.PlatformType.Mac
                    ? { right: '40px' }
                    : { right: '16px' }
            }
        >
            <AiFillTool
                data-for="refresh-errors"
                data-tip="Refresh errors"
                color="#fff"
                onClick={refreshErrors}
            />
            <ReactTooltip id="refresh-errors" effect="solid" place="left" />
        </div>
    )
}
