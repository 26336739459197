// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BGofZDSB1nNunLkyH31E {\n    position: absolute;\n    top: 20px;\n    color: #fff;\n    font-size: 18px;\n    cursor: pointer;\n    z-index: 2;\n}\n\n.G3WtoAyp8w5OYrG7hetA {\n    position: relative;\n}\n\n.yVuOb6hhHdrlRxmMfh8f {\n    position: absolute;\n    background-color: white;\n    border-radius: 6px;\n    margin-top: 4px;\n    right: 0;\n    z-index: 1000;\n    cursor: auto;\n    padding: 12px;\n    box-shadow: inset 0 0 0 1px #ebebeb;\n}\n\n.y4wMNwicPkdxz3pwNRCC {\n    color: #4b5563;\n    font-size: 12px;\n    text-wrap: nowrap;\n    text-align: left;\n    cursor: pointer;\n}\n\n.NaOEiU_zKoIbnQSFtxni {\n    border: none;\n    border-top: 1px solid #ebebeb;\n    margin: 8px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/RefreshFunctions/RefreshFunctions.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;IACf,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,QAAQ;IACR,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,aAAa;AACjB","sourcesContent":[".refreshFunctions {\n    position: absolute;\n    top: 20px;\n    color: #fff;\n    font-size: 18px;\n    cursor: pointer;\n    z-index: 2;\n}\n\n.moreContainer {\n    position: relative;\n}\n\n.dropDownMenu {\n    position: absolute;\n    background-color: white;\n    border-radius: 6px;\n    margin-top: 4px;\n    right: 0;\n    z-index: 1000;\n    cursor: auto;\n    padding: 12px;\n    box-shadow: inset 0 0 0 1px #ebebeb;\n}\n\n.menuItem {\n    color: #4b5563;\n    font-size: 12px;\n    text-wrap: nowrap;\n    text-align: left;\n    cursor: pointer;\n}\n\n.divider {\n    border: none;\n    border-top: 1px solid #ebebeb;\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshFunctions": "BGofZDSB1nNunLkyH31E",
	"moreContainer": "G3WtoAyp8w5OYrG7hetA",
	"dropDownMenu": "yVuOb6hhHdrlRxmMfh8f",
	"menuItem": "y4wMNwicPkdxz3pwNRCC",
	"divider": "NaOEiU_zKoIbnQSFtxni"
};
export default ___CSS_LOADER_EXPORT___;
