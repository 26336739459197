import React from 'react'
import Styles from './PageRoot.module.css'
import PageHeader from '../PageHeader/PageHeader'
import Nav from '../Nav/Nav'
import { Outlet } from 'react-router-dom'

export default function PageRoot() {
    return (
        <div className={Styles.taskPane}>
            <PageHeader />
            <Nav />
            <Outlet />
        </div>
    )
}
