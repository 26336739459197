import React, { useState } from 'react'
import './PasswordlessLogin.css'
import '../../utils/styles/styles.css'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import { httpClient } from '../../utils/http'
import ActionButton from '../../components/ActionButton/ActionButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAuthStore, useErrorStore, useLoadingStore } from '../../state/store'

export default function PasswordlessLogin() {
    // *********** state *********** //
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const [mojoAuthStateId, setMojoAuthStateId] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const { setAuth, currentUser } = useAuthStore((state) => state)
    const navigate = useNavigate()
    const { register, handleSubmit, reset } =
        useForm<{ email: string; otp: string }>()

    function goBack() {
        navigate('/')
    }

    const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: { state_id: string } } =
                await httpClient.post(
                    '/public/auth/send-otp',
                    {
                        email: data.email.trim(),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
            setEmail(data.email.trim())
            setMojoAuthStateId(response.data.state_id)
            reset()
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setError({
                    hasError: true,
                    errorMessage: 'Please make sure you have an account.',
                })
            } else {
                setError({
                    hasError: true,
                    errorMessage: 'Error while sending OTP.',
                })
            }
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const onSubmitCode: SubmitHandler<{ otp: string }> = async (data) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: any } = await httpClient.post(
                '/public/auth/verify-otp',
                {
                    otp: data.otp.trim(),
                    state_id: mojoAuthStateId,
                    platform: 'EXCEL',
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            const token = response.data.token
            setAuth(token)
            mixpanel.track('LOGIN_SUCCESS', {
                distinct_id: currentUser.email,
                isSubscribed: currentUser.isSubscribed,
                subscriptionType: currentUser.subscriptionType,
                platform: 'EXCEL',
            })
            setLoading({ isLoading: false, message: '' })
            navigate('/feature/statement-dump')
        } catch (e) {
            setLoading({ isLoading: false, message: '' })
            setError({ hasError: true, errorMessage: e.response.data })
        }
    }

    return (
        <>
            <div className="content">
                <div onClick={goBack} className="back">
                    Back
                </div>
                <div className="login-header">
                    <div className="white">
                        Wise<span className="wise-green">sheets</span>
                    </div>
                </div>
                <br />

                {mojoAuthStateId === '' ? (
                    <>
                        <div className="value-prop">
                            We will send a one-time passcode to the email you
                            enter below.
                        </div>
                        <form
                            className="login-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="input">
                                <input
                                    type="text"
                                    id="email"
                                    placeholder="Email"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'invalid email address',
                                        },
                                    })}
                                />
                            </div>
                            <div className="login-button">
                                <ActionButton
                                    type={'submit'}
                                    title={'Login without password'}
                                />
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <div className="value-prop">
                            We just sent an OTP to {email}.
                        </div>
                        <form
                            className="login-form"
                            onSubmit={handleSubmit(onSubmitCode)}
                        >
                            <div className="input">
                                <input
                                    type="text"
                                    id="otp"
                                    placeholder="OTP"
                                    {...register('otp', {
                                        required: true,
                                        pattern: {
                                            value: /^[0-9]{6}$/i,
                                            message: 'Invalid otp',
                                        },
                                    })}
                                />
                            </div>
                            <div className="login-button">
                                <ActionButton
                                    type={'submit'}
                                    title={'Submit'}
                                />
                            </div>
                        </form>
                    </>
                )}
                <div className="new-user">
                    New to Wisesheets?{' '}
                    <a href="https://wisesheets.io/signup" target="_blank">
                        Make an account
                    </a>
                    <br />
                    Forgot password?{' '}
                    <a
                        href="https://wisesheets.io/forgot-password"
                        target="_blank"
                    >
                        Reset password
                    </a>
                </div>
                <br />
                <div className="policies">
                    <a
                        href="https://wisesheets.io/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="https://wisesheets.io/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>
                </div>
            </div>
        </>
    )
}
