// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D9DW9DCieuJ2f0LGmmil {\n    padding: 8px 0;\n    border: none;\n    border-radius: 4px;\n    background-color: #ffffff;\n    font-size: 16px;\n    color: #ffffff;\n    width: 100%;\n    color: #232323;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n\n.RtDyohjJ0FHRhu_pXTXk {\n    margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/GoogleButton/GoogleButton.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,WAAW;IACX,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".googleButton {\n    padding: 8px 0;\n    border: none;\n    border-radius: 4px;\n    background-color: #ffffff;\n    font-size: 16px;\n    color: #ffffff;\n    width: 100%;\n    color: #232323;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n\n.googleIcon {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"googleButton": "D9DW9DCieuJ2f0LGmmil",
	"googleIcon": "RtDyohjJ0FHRhu_pXTXk"
};
export default ___CSS_LOADER_EXPORT___;
