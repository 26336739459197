import styles from './AutoSuggest.module.css'
import React from 'react'
import { FaAngleDoubleDown } from 'react-icons/fa'

type AutoSuggestionsProps = {
    width: number
    inputRef: React.RefObject<HTMLInputElement>
    inputValue: string

    onInputBlur: () => void
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void

    placeholder: string

    currentSuggestions: any[]
    renderSuggestion: (suggestion: any) => React.ReactNode
}

export default function AutoSuggestions(props: AutoSuggestionsProps) {
    const {
        width,
        currentSuggestions,
        inputRef,
        inputValue,
        onInputBlur,
        onInputChange,
        placeholder,
        onInputFocus,
    } = props

    return (
        <div style={{ width: width }}>
            <input
                ref={inputRef}
                onBlur={onInputBlur}
                className={styles.input}
                style={{ width: width }}
                value={inputValue}
                placeholder={placeholder}
                onChange={onInputChange}
                onFocus={onInputFocus}
            />
            {currentSuggestions.length > 0 && (
                <div
                    className={styles.autoSuggestSelections}
                    style={{ width: width, height: 300 }}
                >
                    {currentSuggestions.map((suggestion, index) =>
                        props.renderSuggestion(suggestion)
                    )}
                    <div
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            marginLeft: 115,
                            zIndex: 50000,
                        }}
                    >
                        <FaAngleDoubleDown color={'grey'} />
                    </div>
                </div>
            )}
        </div>
    )
}
