import React from 'react'
import styles from './SuccessModal.module.css'
import { AiFillCheckCircle } from 'react-icons/ai'

type Props = {
    message: string
    onDismissClick: () => void
}

export default function SuccessModal(props: Props) {
    if (props.message === '') return null

    return (
        <div className={styles.successContainer}>
            <div className={styles.success}>
                <AiFillCheckCircle color="#1fb723" size={36} />
                <div
                    className={styles.successContent}
                    dangerouslySetInnerHTML={{ __html: props.message }}
                />
                <div className={styles.close} onClick={props.onDismissClick}>
                    Dismiss
                </div>
            </div>
        </div>
    )
}
