import React, { useState } from 'react'
import styles from './TrendingStocks.module.css'
import { useQuery } from '@tanstack/react-query'
import { API_URL_V3, STATEMENT_DUMP_API_KEY } from '../StatementDump/constants'
import axios from 'axios'
import StockTable from '../../components/StockTable/StockTable'
import Autosuggest from 'react-autosuggest'
import theme from './autocomplete.module.css'
import { EXCHANGES_FILTER } from '../FunctionBuilder/constants'
import { EXCHANGES } from '../Screener/constants'
import { useNavigate } from 'react-router-dom'

const fetchSymbols = async (url: string) => {
    const response = await axios.get(url, {
        params: { apikey: STATEMENT_DUMP_API_KEY },
    })
    return response.data
        .slice(0, 10)
        .map((stock: { symbol: string }) => stock.symbol)
}

const fetchAllSymbols = async () => {
    const urls = [
        'https://financialmodelingprep.com/api/v3/stock_market/gainers',
        'https://financialmodelingprep.com/api/v3/stock_market/losers',
        'https://financialmodelingprep.com/api/v3/stock_market/actives',
    ]

    const results = await Promise.all(urls.map((url) => fetchSymbols(url)))
    return results.flat()
}

const fetchQuoteData = async (symbols) => {
    const response = await axios.get(
        'https://financialmodelingprep.com/api/v3/quote/' + symbols.join(','),
        { params: { apikey: STATEMENT_DUMP_API_KEY } }
    )
    return response.data
}

export default function TrendingStocks() {
    const navigate = useNavigate()

    const { data: symbols } = useQuery<string[]>(
        ['trendingSymbols'],
        fetchAllSymbols,
        {
            refetchOnWindowFocus: false,
        }
    ) // the symbol order is always the same as the order of the urls. ie the first 3 symbols are the top gainers, the next 3 are the top losers, and the last 3 are the most active

    const { data: quoteData } = useQuery(
        ['trendingQuoteData', symbols],
        () => fetchQuoteData(symbols),
        {
            enabled: !!symbols,
            refetchOnWindowFocus: false,
        }
    )
    const [suggestedTickers, setSuggestedTickers] = useState<
        TickerSuggestion[]
    >([])
    const [tickerQuery, setTickerQuery] = useState<string>('')

    const onTickerChange = (event: any, { newValue }: any) => {
        setTickerQuery(newValue)
    }

    const getSuggestedTickers = async (value: { value: string }) => {
        if (value.value.length < 2) return
        try {
            const ticker = value.value.toUpperCase()
            const response: { data: TickerSuggestion[] } = await axios.get(
                API_URL_V3 +
                    'search?query=' +
                    ticker +
                    `&limit=5&exchange=${EXCHANGES_FILTER}&apikey=` +
                    STATEMENT_DUMP_API_KEY
            )

            const EXCHANGE_PRIORITY_LIST = EXCHANGES.map((exchange) =>
                exchange.short.toUpperCase()
            )

            const sortedByExchangePriority = response.data.sort((a, b) => {
                return (
                    EXCHANGE_PRIORITY_LIST.indexOf(
                        a.exchangeShortName.toUpperCase()
                    ) -
                    EXCHANGE_PRIORITY_LIST.indexOf(
                        b.exchangeShortName.toUpperCase()
                    )
                )
            })

            setSuggestedTickers(sortedByExchangePriority)
        } catch (e) {}
    }

    const renderTickerSuggestion = (ticker: TickerSuggestion) => {
        return (
            <div>
                {ticker.name} - {ticker.symbol}
            </div>
        )
    }

    const symbolInputProps = {
        placeholder: 'Search using ticker, name etc',
        value: tickerQuery,
        onChange: onTickerChange,
    }

    return (
        <div>
            <div>
                <div className={styles.trendingStocksHeader}>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: 4 }}
                    >
                        <path
                            d="M8.52915 7.80686L11.2685 5.06754C11.5065 5.6319 11.6387 6.25191 11.6387 6.90191C11.6387 8.06576 11.2136 9.13015 10.5143 9.95578L11.244 10.6855L11.3738 10.5557C11.5282 10.4013 11.7776 10.4013 11.9319 10.5557L13.0582 11.682C13.2445 11.8679 13.3471 12.1157 13.3471 12.3798C13.3471 12.6438 13.2445 12.8908 13.0582 13.0775C12.866 13.2697 12.6135 13.366 12.3605 13.366C12.1075 13.366 11.8549 13.2697 11.6627 13.0775L10.5364 11.9512C10.4622 11.877 10.4207 11.7771 10.4207 11.6721C10.4207 11.5672 10.4622 11.4669 10.5364 11.3931L10.686 11.2435L9.95663 10.5138C9.13101 11.2128 8.06701 11.6378 6.90316 11.6378C5.02893 11.6378 3.41044 10.5407 2.64322 8.95729L5.0459 6.55501C5.19469 6.40543 5.47134 6.42319 5.58737 6.53725L6.85541 7.97025C7.09299 8.20784 7.41858 8.29821 7.77101 8.22757C8.04451 8.17192 8.31406 8.02235 8.52994 7.80686H8.52915ZM6.90277 2.16602C4.29131 2.16602 2.16687 4.29046 2.16687 6.90191C2.16687 7.33288 2.22962 7.74885 2.33815 8.14587L4.48706 5.99696C4.93421 5.54981 5.71406 5.54981 6.1616 5.99696L7.42964 7.42996C7.432 7.43115 7.48252 7.48166 7.61394 7.45404C7.73352 7.42996 7.86692 7.3534 7.97111 7.24882L10.8801 4.33979C10.036 3.03387 8.5702 2.16602 6.90316 2.16602H6.90277ZM13.2173 2.16602H11.244C11.0262 2.16602 10.8493 2.34243 10.8493 2.56067C10.8493 2.77892 11.0262 2.95533 11.244 2.95533H12.2646L10.8801 4.33979C11.0289 4.56988 11.1607 4.8122 11.2685 5.06715L12.8226 3.51298V4.53357C12.8226 4.75181 12.9994 4.92823 13.2173 4.92823C13.4351 4.92823 13.612 4.75181 13.612 4.53357V2.56067C13.612 2.34243 13.4351 2.16602 13.2173 2.16602Z"
                            fill="white"
                        />
                    </svg>
                    Trending & Popular Stocks
                </div>
                <Autosuggest
                    theme={theme}
                    suggestions={suggestedTickers}
                    onSuggestionSelected={(event: any, { suggestion }: any) => {
                        navigate(
                            '/feature/company-profile/' + suggestion.symbol
                        )
                        setTickerQuery('')
                    }}
                    onSuggestionsFetchRequested={getSuggestedTickers}
                    onSuggestionsClearRequested={() => setSuggestedTickers([])}
                    getSuggestionValue={(symbol: TickerSuggestion) => {
                        return symbol.symbol
                    }}
                    renderSuggestion={renderTickerSuggestion}
                    inputProps={symbolInputProps}
                    focusInputOnSuggestionClick={false}
                />
                <div className={styles.trendingSubHeader}>Top Gainers</div>
                <StockTable
                    data={quoteData?.slice(0, 10)}
                    defaultDataLength={3}
                    isExpandable
                />
                <div className={styles.trendingSubHeader}>Top Losers</div>
                <StockTable
                    data={quoteData?.slice(10, 20)}
                    defaultDataLength={3}
                    isExpandable
                />
                <div className={styles.trendingSubHeader}>Most Active</div>
                <StockTable
                    data={quoteData?.slice(20, 30)}
                    defaultDataLength={3}
                    isExpandable
                />
            </div>
        </div>
    )
}
