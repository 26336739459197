const API_BASE_URL = 'https://financialmodelingprep.com/api/'
export const API_URL_V3 = API_BASE_URL + 'v3/'
export const API_URL_V4 = API_BASE_URL + 'v4/'
export const STATEMENT_DUMP_API_KEY = '36b49936e9ac9474394788014021c3dc'
export const KEY_METRICS = 'Key Metrics'
export const FINANCIAL_GROWTH = 'Financial Growth'
export const INCOME_STATEMENT = 'Income Statement'
export const BALANCE_SHEET = 'Balance Sheet'
export const CASH_FLOW = 'Cash Flow'

export const URL_MAP = {
    'Key Metrics': 'key-metrics',
    'Financial Growth': 'financial-growth',
    'Income Statement': 'income-statement',
    'Balance Sheet': 'balance-sheet-statement',
    'Cash Flow': 'cash-flow-statement',
}

export const STATEMENT_ORDER_MAP = {
    'Income Statement': [
        'Period',
        'Date',
        'SPACE',
        'Revenue',
        'SPACE',
        'Cost Of Revenue',
        'Gross Profit',
        'Gross Profit Ratio',
        'SPACE',
        'Research And Development Expenses',
        'General And Administrative Expenses',
        'Selling And Marketing Expenses',
        'Other Expenses',
        'Operating Expenses',
        'SPACE',
        'Cost And Expenses',
        'Ebitda',
        'Ebitdaratio',
        'Interest Expense',
        'Depreciation And Amortization',
        'Operating Income',
        'Operating Income Ratio',
        'SPACE',
        'Total Other Income Expenses Net',
        'Income Before Tax',
        'Income Before Tax Ratio',
        'Income Tax Expense',
        'Net Income',
        'SPACE',
        'Net Income Ratio',
        'Eps',
        'Epsdiluted',
        'Weighted Average Shs Out',
        'Weighted Average Shs Out Dil',
    ],
    'Balance Sheet': [
        'Period',
        'Date',
        'SPACE',
        'Cash And Cash Equivalents',
        'Short Term Investments',
        'Cash And Short Term Investments',
        'Net Receivables',
        'Inventory',
        'Other Current Assets',
        'Total Current Assets',
        'SPACE',
        'Property Plant Equipment Net',
        'Goodwill',
        'Intangible Assets',
        'Goodwill And Intangible Assets',
        'Long Term Investments',
        'Tax Assets',
        'Other Non Current Assets',
        'Total Non Current Assets',
        'SPACE',
        'Other Assets',
        'Total Assets',
        'SPACE',
        'Account Payables',
        'Short Term Debt',
        'Tax Payables',
        'Deferred Revenue',
        'Other Current Liabilities',
        'Total Current Liabilities',
        'SPACE',
        'Long Term Debt',
        'Deferred Revenue Non Current',
        'Deferred Tax Liabilities Non Current',
        'Other Non Current Liabilities',
        'Total Non Current Liabilities',
        'SPACE',
        'Other Liabilities',
        'Total Liabilities',
        'SPACE',
        'Common Stock',
        'Retained Earnings',
        'Accumulated Other Comprehensive Income Loss',
        'Othertotal Stockholders Equity',
        'Total Stockholders Equity',
        'SPACE',
        'Total Liabilities And Stockholders Equity',
        'Total Investments',
        'Total Debt',
        'Net Debt',
    ],
    'Cash Flow': [
        'Period',
        'Date',
        'SPACE',
        'Net Income',
        'Depreciation And Amortization',
        'Deferred Income Tax',
        'Stock Based Compensation',
        'Change In Working Capital',
        'Accounts Receivables',
        'Inventory',
        'Accounts Payables',
        'Other Working Capital',
        'Other Non Cash Items',
        'Net Cash Provided By Operating Activities',
        'SPACE',
        'Investments In Property Plant And Equipment',
        'Acquisitions Net',
        'Purchases Of Investments',
        'Sales Maturities Of Investments',
        'Other Investing Activites',
        'Net Cash Used For Investing Activites',
        'SPACE',
        'Debt Repayment',
        'Common Stock Issued',
        'Common Stock Repurchased',
        'Dividends Paid',
        'Other Financing Activites',
        'Net Cash Used Provided By Financing Activities',
        'SPACE',
        'Effect Of Forex Changes On Cash',
        'Net Change In Cash',
        'Cash At End Of Period',
        'Cash At Beginning Of Period',
        'Operating Cash Flow',
        'Capital Expenditure',
        'Free Cash Flow',
    ],
    'Key Metrics': [
        'Period',
        'Date',
        'Revenue Per Share',
        'Net Income Per Share',
        'Operating Cash Flow Per Share',
        'Free Cash Flow Per Share',
        'Cash Per Share',
        'Book Value Per Share',
        'Tangible Book Value Per Share',
        'Shareholders Equity Per Share',
        'Interest Debt Per Share',
        'Market Cap',
        'Enterprise Value',
        'Pe Ratio',
        'Price To Sales Ratio',
        'Pocfratio',
        'Pfcf Ratio',
        'Pb Ratio',
        'Ptb Ratio',
        'Ev To Sales',
        'Enterprise Value Over EBITDA',
        'Ev To Operating Cash Flow',
        'Ev To Free Cash Flow',
        'Earnings Yield',
        'Free Cash Flow Yield',
        'Debt To Equity',
        'Debt To Assets',
        'Net Debt To EBITDA',
        'Current Ratio',
        'Interest Coverage',
        'Income Quality',
        'Dividend Yield',
        'Payout Ratio',
        'Sales General And Administrative To Revenue',
        'Research And Ddevelopement To Revenue',
        'Intangibles To Total Assets',
        'Capex To Operating Cash Flow',
        'Capex To Revenue',
        'Capex To Depreciation',
        'Stock Based Compensation To Revenue',
        'Graham Number',
        'Roic',
        'Return On Tangible Assets',
        'Graham Net Net',
        'Working Capital',
        'Tangible Asset Value',
        'Net Current Asset Value',
        'Average Receivables',
        'Average Payables',
        'Average Inventory',
        'Days Sales Outstanding',
        'Days Payables Outstanding',
        'Days Of Inventory On Hand',
        'Receivables Turnover',
        'Payables Turnover',
        'Inventory Turnover',
        'Roe',
        'Capex Per Share',
    ],
    'Financial Growth': [
        'Period',
        'Date',
        'SPACE',
        'Revenue Growth',
        'Gross Profit Growth',
        'Ebitgrowth',
        'Operating Income Growth',
        'Net Income Growth',
        'Epsgrowth',
        'Epsdiluted Growth',
        'Weighted Average Shares Growth',
        'Weighted Average Shares Diluted Growth',
        'Dividendsper Share Growth',
        'Operating Cash Flow Growth',
        'Free Cash Flow Growth',
        'Ten YRevenue Growth Per Share',
        'Five YRevenue Growth Per Share',
        'Three YRevenue Growth Per Share',
        'Ten YOperating CFGrowth Per Share',
        'Five YOperating CFGrowth Per Share',
        'Three YOperating CFGrowth Per Share',
        'Ten YNet Income Growth Per Share',
        'Five YNet Income Growth Per Share',
        'Three YNet Income Growth Per Share',
        'Ten YShareholders Equity Growth Per Share',
        'Five YShareholders Equity Growth Per Share',
        'Three YShareholders Equity Growth Per Share',
        'Ten YDividendper Share Growth Per Share',
        'Five YDividendper Share Growth Per Share',
        'Three YDividendper Share Growth Per Share',
        'Receivables Growth',
        'Asset Growth',
        'Inventory Growth',
        'Book Valueper Share Growth',
        'Debt Growth',
        'Rdexpense Growth',
        'Sgaexpenses Growth',
    ],
}
