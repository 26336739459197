import React, { useState } from 'react'
import { Bucket } from '../../state/types/bucket'
import { BsChevronRight, BsChevronDown } from 'react-icons/bs'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useErrorStore } from '../../state/store'
import {
    StyledBucketContainer,
    StyledBucketTop,
    StyledExpandButton,
    StyledBucketName,
    StyledRangeContainer,
    StyledRangeInputs,
    StyledLabel,
    StyledRefresh,
    StyledTrash,
} from './styles'

export default function BucketListItem(props: {
    bucket: Bucket
    onSetBucketName: (index: number, name: string) => void
    onRemoveBucket: (index: number) => void
    onSetBucketTickerRange: (index: number, tickerRange: string) => void
    onSetBucketParameterRange: (index: number, parameterRange: string) => void
    onRefreshData: (
        data: any,
        shouldSave: boolean,
        event: 'GET_SCREENER_DATA' | 'REFRESH_BUCKET'
    ) => Promise<void>
    index: number
}) {
    const {
        bucket,
        onSetBucketName,
        onRemoveBucket,
        onSetBucketTickerRange,
        onSetBucketParameterRange,
        onRefreshData,
        index,
    } = props

    const { setError } = useErrorStore((state) => state)

    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <StyledBucketContainer>
            <StyledBucketTop>
                {isExpanded ? (
                    <StyledExpandButton
                        as={BsChevronDown}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                ) : (
                    <StyledExpandButton
                        as={BsChevronRight}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                )}
                <StyledBucketName
                    type="text"
                    onChange={(e) => {
                        onSetBucketName(index, e.target.value)
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            onSetBucketName(index, 'Bucket ' + (index + 1))
                        }
                    }}
                    value={bucket.name}
                />
                <StyledRefresh
                    data-tooltip-id="refresh-data"
                    data-tooltip-content="Refresh live data"
                    onClick={() =>
                        onRefreshData(
                            {
                                tickerRange: bucket.tickerRange,
                                parameterRange: bucket.parameterRange,
                            },
                            false,
                            'REFRESH_BUCKET'
                        )
                    }
                />
                <ReactTooltip id="refresh-data" place="top" />
                <StyledTrash
                    data-tooltip-id="delete-bucket"
                    data-tooltip-content="Delete bucket"
                    onClick={() => onRemoveBucket(index)}
                />
                <ReactTooltip id="delete-bucket" place="top" />
            </StyledBucketTop>
            {isExpanded ? (
                <StyledRangeContainer>
                    <StyledLabel>Tickers</StyledLabel>
                    <StyledRangeInputs
                        onMouseEnter={async () => {
                            try {
                                await Excel.run(async (context) => {
                                    // create the new sheet
                                    var currentSheet =
                                        context.workbook.worksheets.getActiveWorksheet()
                                    let range = currentSheet.getRange(
                                        bucket.tickerRange
                                    )
                                    range.select()
                                    await context.sync()
                                })
                            } catch (error) {
                                setError({
                                    hasError: true,
                                    errorMessage:
                                        'Something went wrong. Please try again.',
                                })
                            }
                        }}
                        type="text"
                        onChange={(e) => {
                            onSetBucketTickerRange(index, e.target.value)
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                onSetBucketTickerRange(index, 'A1:A1')
                            }
                        }}
                        value={bucket.tickerRange}
                    />
                    <StyledLabel>Parameters</StyledLabel>
                    <StyledRangeInputs
                        onMouseEnter={async () => {
                            try {
                                await Excel.run(async (context) => {
                                    // create the new sheet
                                    var currentSheet =
                                        context.workbook.worksheets.getActiveWorksheet()
                                    let range = currentSheet.getRange(
                                        bucket.parameterRange
                                    )
                                    range.select()
                                    await context.sync()
                                })
                            } catch (error) {
                                setError({
                                    hasError: true,
                                    errorMessage:
                                        'Something went wrong. Please try again.',
                                })
                            }
                        }}
                        type="text"
                        onChange={(e) => {
                            onSetBucketParameterRange(index, e.target.value)
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                onSetBucketParameterRange(index, 'A1:A1')
                            }
                        }}
                        value={bucket.parameterRange}
                    />
                </StyledRangeContainer>
            ) : null}
        </StyledBucketContainer>
    )
}
