import React, { useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import styles from './Collapsible.module.css'

type Props = {
    label: string
    children: React.ReactNode
}

export default function Collapsible(props: Props) {
    const { label, children } = props
    const [isOpened, setIsOpened] = useState(false)

    return (
        <div
            style={{ backgroundColor: isOpened ? '#465484' : null }}
            className={styles.collapsibleContainer}
        >
            <div
                className={styles.labelContainer}
                onClick={() => setIsOpened(!isOpened)}
            >
                <div>{label}</div>
                {isOpened ? <BiChevronUp /> : <BiChevronDown />}
            </div>
            {isOpened ? (
                <div className={styles.childrenContainer}>{children}</div>
            ) : null}
        </div>
    )
}
