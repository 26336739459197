import React, { useState } from 'react'
import styles from './BucketListItem.module.css'
import { Bucket } from '../../state/types/bucket'
import { BsTrash3 } from 'react-icons/bs'
import { TbRefresh } from 'react-icons/tb'
import { BsChevronRight, BsChevronDown } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { useErrorStore } from '../../state/store'

export default function BucketListItem(props: {
    bucket: Bucket
    onSetBucketName: (index: number, name: string) => void
    onRemoveBucket: (index: number) => void
    onSetBucketTickerRange: (index: number, tickerRange: string) => void
    onSetBucketParameterRange: (index: number, parameterRange: string) => void
    onRefreshData: (
        data: any,
        shouldSave: boolean,
        event: 'GET_SCREENER_DATA' | 'REFRESH_BUCKET'
    ) => Promise<void>
    index: number
}) {
    const {
        bucket,
        onSetBucketName,
        onRemoveBucket,
        onSetBucketTickerRange,
        onSetBucketParameterRange,
        onRefreshData,
        index,
    } = props

    const { setError } = useErrorStore((state) => state)

    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <div className={styles.bucketContainer}>
            <div className={styles.bucketTop}>
                {isExpanded ? (
                    <BsChevronDown
                        className={styles.expandButton}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                ) : (
                    <BsChevronRight
                        className={styles.expandButton}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                )}
                <input
                    className={styles.bucketName}
                    type="text"
                    onChange={(e) => {
                        onSetBucketName(index, e.target.value)
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            onSetBucketName(index, 'Bucket ' + (index + 1))
                        }
                    }}
                    value={bucket.name}
                />
                <TbRefresh
                    data-for="refresh-data"
                    data-tip="Refresh live data"
                    className={styles.refresh}
                    onClick={() =>
                        onRefreshData(
                            {
                                tickerRange: bucket.tickerRange,
                                parameterRange: bucket.parameterRange,
                            },
                            false,
                            'REFRESH_BUCKET'
                        )
                    }
                />
                <ReactTooltip id="refresh-data" place="top" />
                <BsTrash3
                    data-for="delete-bucket"
                    data-tip="Delete bucket"
                    className={styles.trash}
                    onClick={() => onRemoveBucket(index)}
                />
                <ReactTooltip id="delete-bucket" place="top" />
            </div>
            {isExpanded ? (
                <div className={styles.rangesContainer}>
                    <label className={styles.inputLabel}>Tickers</label>
                    <input
                        onMouseEnter={async () => {
                            try {
                                await Excel.run(async (context) => {
                                    // create the new sheet
                                    var currentSheet =
                                        context.workbook.worksheets.getActiveWorksheet()
                                    let range = currentSheet.getRange(
                                        bucket.tickerRange
                                    )
                                    range.select()
                                    await context.sync()
                                })
                            } catch (error) {
                                setError({
                                    hasError: true,
                                    errorMessage:
                                        'Something went wrong. Please try again.',
                                })
                            }
                        }}
                        className={styles.rangeInputs}
                        type="text"
                        onChange={(e) => {
                            onSetBucketTickerRange(index, e.target.value)
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                onSetBucketTickerRange(index, 'A1:A1')
                            }
                        }}
                        value={bucket.tickerRange}
                    />
                    <label className={styles.inputLabel}>Parameters</label>
                    <input
                        onMouseEnter={async () => {
                            try {
                                await Excel.run(async (context) => {
                                    // create the new sheet
                                    var currentSheet =
                                        context.workbook.worksheets.getActiveWorksheet()
                                    let range = currentSheet.getRange(
                                        bucket.parameterRange
                                    )
                                    range.select()
                                    await context.sync()
                                })
                            } catch (error) {
                                setError({
                                    hasError: true,
                                    errorMessage:
                                        'Something went wrong. Please try again.',
                                })
                            }
                        }}
                        className={styles.rangeInputs}
                        type="text"
                        onChange={(e) => {
                            onSetBucketParameterRange(index, e.target.value)
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                onSetBucketParameterRange(index, 'A1:A1')
                            }
                        }}
                        value={bucket.parameterRange}
                    />
                </div>
            ) : null}
        </div>
    )
}
