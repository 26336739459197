import React from 'react'
import styles from './ActionButton.module.css'

interface ActionButtonProps {
    title: string | React.ReactNode
    action?: (data?: any) => void
    type: 'button' | 'submit' | 'reset'
    color?: string
    fullWidth?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
    title,
    action,
    type,
    color,
    fullWidth = true, // Set default value to true
}) => {
    return (
        <button
            onClick={action}
            className={styles.actionButton}
            type={type}
            style={{
                backgroundColor: color,
                width: fullWidth ? '100%' : 'auto',
            }}
        >
            {title}
        </button>
    )
}
export default ActionButton
