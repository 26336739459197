import React from 'react'
import styles from './GoogleButton.module.css'
import { FcGoogle } from 'react-icons/fc'

export default function GoogleButton(props: { onClick: () => void }) {
    return (
        <button onClick={props.onClick} className={styles.googleButton}>
            <FcGoogle className={styles.googleIcon} size={18} />
            Sign in with Google
        </button>
    )
}
