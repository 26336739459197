import styled from 'styled-components'

type StyledActionButtonProps = {
    color?: string
    $fullWidth?: boolean
}

export const StyledActionButton = styled.button<StyledActionButtonProps>`
    padding: 10px 12px;
    border: none;
    border-radius: 6px;
    background-color: ${({ color, theme }) =>
        color || theme.colours.background.secondary};
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    font-size: 16px;
    color: ${({ theme }) => theme.colours.font.primary};
    text-align: center;
    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
`
