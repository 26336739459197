export const STABLEBREAD_TEMPLATE: Template = {
    date_created: new Date().toDateString(),
    description:
        'Valuation models, charts, financial and ratio analysis, and more in one spreadsheet.',
    id: '123456',
    name: "StableBread's Automated Stock Analysis Spreadsheet",
    platform: 'EXCEL',
    url: 'https://stablebread.com/automated-stock-analysis-spreadsheet/?aff=19817f43',
    image_url:
        'https://wisesheets-template-images.s3.us-east-2.amazonaws.com/stablebread_template.png',
}
