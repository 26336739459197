import React, { useEffect, useRef, useState } from 'react'
import ActionButton from '../../components/ActionButton/ActionButton'
import {
    useAuthStore,
    useErrorStore,
    useLoadingStore,
    useBucketStore,
} from '../../state/store'
import { useFieldArray, useForm } from 'react-hook-form'
import { RiCloseLine } from 'react-icons/ri'
import {
    SCREENER_INPUT_MAP,
    SCREENER_FILTER_KEYS,
    DEFAULT_SELECTED_FILTER_OPTION_STATE,
    getValidIndustries,
    SCREENER_FILTER_KEY_TO_CAMEL_CASE,
} from './utils'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { httpClient } from '../../utils/http'
import BucketListItem from '../../components/BucketListItem/BucketListItem'
import {
    StyledAddAnotherFilter,
    StyledAddFilterValueButton,
    StyledArithmeticFilter,
    StyledArithmeticInput,
    StyledCheckboxLabel,
    StyledClickHere,
    StyledCloseButton,
    StyledCloseButtonContainer,
    StyledCustomInput,
    StyledCustomInputContainer,
    StyledDeleteButton,
    StyledErrorMessage,
    StyledFilterContainer,
    StyledFilterItem,
    StyledFilterOption,
    StyledFilterTitle,
    StyledFullVersionButton,
    StyledFullVersionContainer,
    StyledInfo,
    StyledInputContainer,
    StyledLeftInputContainer,
    StyledLink,
    StyledLinkFooter,
    StyledModalHeader,
    StyledOverlay,
    StyledPageDescription,
    StyledPopupContainer,
    StyledPopupContent,
    StyledPopupData,
    StyledRangeLabel,
    StyledRxGrid,
    StyledRxGridContainer,
    StyledSearchInput,
    StyledSelectedFilterOption,
    StyledSeparator,
    StyledTab,
    StyledTabHighlight,
    StyledTabs,
    StyledTabsContainer,
} from './styles'
import { useTheme } from 'styled-components'
import { AmplitudeEvents, useAmplitudeTrack } from '../../hooks/amplitude'

enum ARITHMETIC_FILTER_OPTIONS {
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    BETWEEN = 'BETWEEN',
}

type Field = {
    id: string
    name: string
}

const Screener = () => {
    const theme = useTheme()
    const { currentUser } = useAuthStore((state) => state)
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const {
        buckets,
        saveBucket,
        removeBucket,
        setBucketName,
        setBucketTickersRange,
        setBucketParametersRange,
    } = useBucketStore((state) => state)
    const [isSelectFiltersModalOpen, setIsSelectFiltersModalOpen] =
        useState(false)

    // **** state for multi select values **** //
    const [currentMultiSelectFilter, setCurrentMultiSelectFilter] = useState('')
    // maintains a list of all the selected values for each multi select filter
    const [
        selectedMultiValueFilterOptions,
        setSelectedMultiValueFilterOptions,
    ] = useState<Record<string, string[]>>(DEFAULT_SELECTED_FILTER_OPTION_STATE)

    const [selectedMiltiValueFilterData, setSelectedMultiValueFilterData] =
        useState([])
    const isPremiumUser = currentUser.subscriptionType === 'PREMIUM'
    // *************************************** //

    const [shouldShowSearch, setShouldShowSearch] = useState(false)
    const [searchInput, setSearchInput] = useState('')

    const {
        register,
        unregister,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm()

    const trackEvent = useAmplitudeTrack()

    // TODO: figure out how to type fields
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'screener-form', // unique name for your Field Array
    })

    useEffect(() => {
        remove()
        append([
            { name: SCREENER_FILTER_KEYS.MARKET_CAP },
            { name: SCREENER_FILTER_KEYS.PRICE },
            { name: SCREENER_FILTER_KEYS.SECTOR },
            { name: SCREENER_FILTER_KEYS.INDUSTRY },
            { name: SCREENER_FILTER_KEYS.EXCHANGE },
            { name: SCREENER_FILTER_KEYS.BETA },
            { name: SCREENER_FILTER_KEYS.VOLUME },
            { name: SCREENER_FILTER_KEYS.DIVIDEND },
            { name: SCREENER_FILTER_KEYS.IS_ETF },
            { name: SCREENER_FILTER_KEYS.IS_FUND },
            { name: SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING },
            { name: SCREENER_FILTER_KEYS.COUNTRY },
        ])
    }, [])

    const runScreenerV2 = async (data: any) => {
        if (!currentUser.isSubscribed) {
            setError({
                hasError: true,
                errorMessage: 'Please subscribe to screen stocks.',
            })
            return
        }
        if (!isPremiumUser) {
            setError({
                hasError: true,
                errorMessage: 'Please upgrade to screen stocks.',
            })
            return
        }
        setLoading({ isLoading: true, message: 'Screening data...' })
        trackEvent(AmplitudeEvents.RUN_SCREENER)
        const body: {
            [key: string]:
                | number
                | string
                | boolean
                | {
                      min?: number
                      max?: number
                      value?: number
                      type: string
                  }
                | string[]
        } = {}

        Object.entries(data).forEach(
            ([key, value]: [
                string,
                {
                    option: string | string[]
                    value: string
                    max?: string
                    min?: string
                }
            ]) => {
                if (key === 'screener-form') {
                    return
                }
                const camelCaseKey = SCREENER_FILTER_KEY_TO_CAMEL_CASE[key]
                // Skip empty objects
                if (!value || Object.keys(value).length === 0) return

                // Handle numeric comparisons (fields that have max/min options)
                if (
                    [
                        'price',
                        'marketCap',
                        'beta',
                        'volume',
                        'dividend',
                    ].includes(camelCaseKey) ||
                    camelCaseKey.endsWith('TTM')
                ) {
                    const comparison: {
                        min?: number
                        max?: number
                        value?: number
                        type: string
                    } = {
                        type: value.option as string,
                    }

                    if (value.option === ARITHMETIC_FILTER_OPTIONS.BETWEEN) {
                        const min = value.min?.replace(/,/g, '')
                        const max = value.max?.replace(/,/g, '')

                        if (
                            min &&
                            max &&
                            !isNaN(Number(min)) &&
                            !isNaN(Number(max))
                        ) {
                            comparison.min = Number(min)
                            comparison.max = Number(max)
                            body[camelCaseKey] = comparison
                        }
                    } else {
                        const numValue = value.value?.replace(/,/g, '')

                        if (numValue && !isNaN(Number(numValue))) {
                            comparison.value = Number(numValue)
                            body[camelCaseKey] = comparison
                        }
                    }
                    return
                }

                // Handle boolean values
                if (
                    ['isActivelyTrading', 'isEtf', 'isFund'].includes(
                        camelCaseKey
                    )
                ) {
                    body[camelCaseKey] = value.value
                    return
                }

                // Handle category values (sector, industry, country, exchange)
                if (
                    ['sector', 'industry', 'country', 'exchange'].includes(
                        camelCaseKey
                    )
                ) {
                    if (
                        value.value &&
                        typeof value.value === 'string' &&
                        value.value.trim()
                    ) {
                        if (value.option) {
                            body[camelCaseKey] = value.option as string[]
                        }
                    }
                    return
                }
            }
        )

        body.limit = 5000

        try {
            const response: { data: any } = await httpClient.post(
                '/api/private/screener/stock-screener',
                body
            )
            if (response.data.length === 0) {
                setError({ hasError: true, errorMessage: 'No data found.' })
                return
            }
            const headings = Object.keys(response.data[0])
            const structuredData = response.data.map(Object.values)
            structuredData.unshift(headings)
            await Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets
                const sheetName =
                    'Screened data ' +
                    new Date().getHours() +
                    ' ' +
                    new Date().getMinutes() +
                    ' ' +
                    new Date().getSeconds()
                const newSheet = worksheets.add(sheetName)
                newSheet.activate()

                // put the data in the sheet
                var dataRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(
                        structuredData.length - 1,
                        structuredData[0].length - 1
                    )
                dataRange.values = structuredData

                // Format marketCapUSD column if it exists
                const marketCapIndex = headings.findIndex(
                    (h) => h === 'marketCapUSD'
                )
                if (marketCapIndex !== -1) {
                    const columnLetter = String.fromCharCode(
                        65 + marketCapIndex
                    ) // Convert index to column letter (A, B, C, etc)
                    const marketCapRange = context.workbook.worksheets
                        .getActiveWorksheet()
                        .getRange(
                            `${columnLetter}2:${columnLetter}${structuredData.length}`
                        )
                    marketCapRange.numberFormat = [['#,##0_);(#,##0)']]
                }

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()

                // put the font colour and background colour on the headings
                var hearderRange = context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange('A1')
                    .getResizedRange(0, headings.length - 1)
                hearderRange.format.font.color = '#F7F8F8'
                hearderRange.format.fill.color = '#364165'
                newSheet.autoFilter.apply(dataRange)
            })
        } catch (e) {
            console.log(e)
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const handleNewFilterCheckboxChange = (e) => {
        if (!e.target.checked) {
            fields.map((field: Field, index) => {
                if (field.name === e.target.value) {
                    remove(index)
                }
            })
        } else append({ name: e.target.value })
    }

    const renderFormOptions = () => {
        return Object.keys(SCREENER_INPUT_MAP).map((key, index) => (
            <StyledFilterOption key={index}>
                <input
                    checked={fields.some((field: Field) => field.name === key)}
                    value={key}
                    type="checkbox"
                    onChange={handleNewFilterCheckboxChange}
                    id={`${key}checkbox`}
                />
                <label
                    style={{ color: theme.colours.font.black }}
                    htmlFor={`${key}checkbox`}
                >
                    {SCREENER_INPUT_MAP[key].displayableName}
                </label>
            </StyledFilterOption>
        ))
    }

    const openModalWithMultiSelectOptions = (multiSelectFieldType: string) => {
        setCurrentMultiSelectFilter(multiSelectFieldType)
        if (multiSelectFieldType === SCREENER_FILTER_KEYS.INDUSTRY) {
            const allowedIndustries = getValidIndustries(
                selectedMultiValueFilterOptions[SCREENER_FILTER_KEYS.SECTOR]
            )
            setSelectedMultiValueFilterData(allowedIndustries)
        } else {
            setSelectedMultiValueFilterData(
                SCREENER_INPUT_MAP[multiSelectFieldType].multiSelectData
            )
        }
        if (
            typeof SCREENER_INPUT_MAP[multiSelectFieldType]
                .multiSelectData[0] === 'object' ||
            typeof SCREENER_INPUT_MAP[multiSelectFieldType].multiSelectData ===
                'function'
        ) {
            setShouldShowSearch(true)
        }
    }

    const handleMultiSelectValueCheckboxChange = (e) => {
        const value = e.target.value
        const isChecked = e.target.checked
        // need to unregister all selected values one change so when it re-renders it registers the correct options
        unregister(`${currentMultiSelectFilter}.option`)

        setSelectedMultiValueFilterOptions((previous) => {
            const newState = { ...previous }

            if (isChecked) {
                newState[currentMultiSelectFilter].push(value)
            } else {
                newState[currentMultiSelectFilter] = newState[
                    currentMultiSelectFilter
                ].filter((item) => item !== value)
            }
            return newState
        })
    }

    const shouldMultiFilterOptionBeChecked = (value: string): boolean => {
        return selectedMultiValueFilterOptions[currentMultiSelectFilter].some(
            (field) => field === value
        )
    }

    const handleMultiSelectOptionsModalClose = () => {
        setCurrentMultiSelectFilter('')
        setSearchInput('')
        setShouldShowSearch(false)
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name?.includes('.option') && type === 'change') {
                const fieldName = name.split('.')[0]
                if (
                    value[fieldName]?.option !==
                    ARITHMETIC_FILTER_OPTIONS.BETWEEN
                ) {
                    unregister(`${fieldName}.min`)
                    unregister(`${fieldName}.max`)
                }
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, unregister])

    const renderInputFields = (field: any) => {
        const comparisonType = watch(`${field.name}.option`)

        const isBetaOrDividend =
            field.name === SCREENER_FILTER_KEYS.BETA ||
            field.name === SCREENER_FILTER_KEYS.DIVIDEND

        const handleNumberInput = (
            e: React.ChangeEvent<HTMLInputElement>,
            fieldPath: string
        ) => {
            // Allow numbers, decimal point, and minus sign
            const cleanValue = (e.target.value as any)
                .replaceAll(/[^0-9.-]/g, '') // Keep only numbers, dots, and minus
                .replace(/([.-])[.-]+/g, '$1') // Remove multiple dots or minus signs
                .replace(/^([^.]*\.[^.]*).*/g, '$1') // Keep only first decimal point

            if (isBetaOrDividend) {
                setValue(fieldPath, cleanValue)
                return
            }

            // For other numeric fields (with thousand separators)
            if (
                cleanValue.endsWith('.') ||
                cleanValue === '.' ||
                cleanValue === '-' ||
                cleanValue === '-.'
            ) {
                setValue(fieldPath, cleanValue)
                return
            }

            // If it's a valid number, format it
            if (cleanValue !== '') {
                const num = Number(cleanValue)
                if (!isNaN(num)) {
                    const formattedNumber = num.toLocaleString('en-US', {
                        minimumFractionDigits: cleanValue.includes('.')
                            ? cleanValue.split('.')[1].length
                            : 0,
                        maximumFractionDigits: 20,
                    })
                    setValue(fieldPath, formattedNumber)
                    return
                }
            }

            setValue(fieldPath, cleanValue)
        }

        if (comparisonType === ARITHMETIC_FILTER_OPTIONS.BETWEEN) {
            return (
                <StyledInputContainer>
                    <StyledArithmeticInput
                        type="text"
                        placeholder="Min"
                        {...register(`${field.name}.min`, {
                            onChange: (e) =>
                                handleNumberInput(e, `${field.name}.min`),
                        })}
                    />
                    <StyledSeparator>to</StyledSeparator>
                    <StyledArithmeticInput
                        type="text"
                        placeholder="Max"
                        {...register(`${field.name}.max`, {
                            onChange: (e) =>
                                handleNumberInput(e, `${field.name}.max`),
                        })}
                    />
                </StyledInputContainer>
            )
        }

        return (
            <StyledArithmeticInput
                type="text"
                {...register(`${field.name}.value`, {
                    onChange: (e) =>
                        handleNumberInput(e, `${field.name}.value`),
                })}
            />
        )
    }

    const renderValidationInputs = () => {
        return fields.map((field: Field, index) => {
            if (SCREENER_INPUT_MAP[field.name].type === 'arithmeticInput') {
                return (
                    <StyledFilterItem key={field.name}>
                        <StyledFilterContainer>
                            <StyledInputContainer>
                                <StyledLeftInputContainer>
                                    <StyledFilterTitle>
                                        {
                                            SCREENER_INPUT_MAP[field.name]
                                                .displayableName
                                        }
                                    </StyledFilterTitle>
                                    <StyledArithmeticFilter
                                        name="arithmeticFilter"
                                        {...register(`${field.name}.option`)}
                                    >
                                        <option
                                            value={
                                                ARITHMETIC_FILTER_OPTIONS.GREATER_THAN
                                            }
                                        >
                                            greater than
                                        </option>
                                        <option
                                            value={
                                                ARITHMETIC_FILTER_OPTIONS.BETWEEN
                                            }
                                        >
                                            between
                                        </option>
                                        <option
                                            value={
                                                ARITHMETIC_FILTER_OPTIONS.LESS_THAN
                                            }
                                        >
                                            less than
                                        </option>
                                    </StyledArithmeticFilter>
                                </StyledLeftInputContainer>
                                {renderInputFields(field)}
                            </StyledInputContainer>
                        </StyledFilterContainer>
                        <StyledDeleteButton>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={theme.colours.font.primary}
                                size={14}
                            />
                        </StyledDeleteButton>
                    </StyledFilterItem>
                )
            } else if (SCREENER_INPUT_MAP[field.name].type === 'booleanInput') {
                return (
                    <StyledFilterItem key={field.name}>
                        <StyledFilterContainer>
                            <StyledInputContainer>
                                <StyledFilterTitle>
                                    {
                                        SCREENER_INPUT_MAP[field.name]
                                            .displayableName
                                    }
                                </StyledFilterTitle>
                            </StyledInputContainer>
                            <input
                                type={'checkbox'}
                                {...register(`${field.name}.value`)}
                                defaultChecked={
                                    field.name ===
                                    SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING
                                }
                            />
                        </StyledFilterContainer>
                        <StyledDeleteButton>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={theme.colours.font.primary}
                                size={14}
                            />
                        </StyledDeleteButton>
                    </StyledFilterItem>
                )
            } else if (
                SCREENER_INPUT_MAP[field.name].type === 'multiValueInput'
            ) {
                return (
                    <StyledFilterItem key={field.name}>
                        <StyledFilterContainer>
                            <StyledInputContainer style={{ flexWrap: 'wrap' }}>
                                <input
                                    type={'hidden'}
                                    {...register(`${field.name}.value`)}
                                    value={field.name}
                                />
                                <StyledFilterTitle>
                                    {
                                        SCREENER_INPUT_MAP[field.name]
                                            .displayableName
                                    }
                                </StyledFilterTitle>
                                {selectedMultiValueFilterOptions[field.name]
                                    .length
                                    ? selectedMultiValueFilterOptions[
                                          field.name
                                      ].map((selectedFilterOption, index) => (
                                          <StyledSelectedFilterOption
                                              key={selectedFilterOption}
                                          >
                                              <RiCloseLine
                                                  onClick={() => {
                                                      setSelectedMultiValueFilterOptions(
                                                          (previous) => {
                                                              const newState = {
                                                                  ...previous,
                                                              }

                                                              newState[
                                                                  field.name
                                                              ] = newState[
                                                                  field.name
                                                              ].filter(
                                                                  (item) =>
                                                                      item !==
                                                                      selectedFilterOption
                                                              )
                                                              unregister(
                                                                  `${field.name}.option`
                                                              )

                                                              return newState
                                                          }
                                                      )
                                                  }}
                                                  style={{
                                                      marginRight: 2,
                                                      cursor: 'pointer',
                                                      paddingTop: 1,
                                                  }}
                                                  color={'black'}
                                                  size={12}
                                              />
                                              {selectedFilterOption}
                                              <input
                                                  type="hidden"
                                                  {...register(
                                                      `${field.name}.option.${index}`
                                                  )}
                                                  value={selectedFilterOption}
                                              />
                                          </StyledSelectedFilterOption>
                                      ))
                                    : null}
                                <StyledAddFilterValueButton
                                    onClick={() => {
                                        openModalWithMultiSelectOptions(
                                            field.name
                                        )
                                    }}
                                >
                                    + {SCREENER_INPUT_MAP[field.name].ctaText}
                                </StyledAddFilterValueButton>
                            </StyledInputContainer>
                        </StyledFilterContainer>
                        <StyledDeleteButton>
                            <RiCloseLine
                                onClick={() => {
                                    remove(index)
                                    unregister(`${field.name}`)
                                }}
                                color={theme.colours.font.primary}
                                size={14}
                            />
                        </StyledDeleteButton>
                    </StyledFilterItem>
                )
            } else {
                return null
            }
        })
    }

    const handleChange = (e) => {
        e.preventDefault()
        setSearchInput(e.target.value)
    }

    useEffect(() => {
        if (!currentMultiSelectFilter) return
        const arrayToSearch =
            SCREENER_INPUT_MAP[currentMultiSelectFilter].multiSelectData
        const dataToBeFiltered =
            typeof arrayToSearch === 'function'
                ? arrayToSearch()
                : arrayToSearch

        if (searchInput.length > 0) {
            setSelectedMultiValueFilterData(
                dataToBeFiltered.filter((data) => {
                    if (typeof arrayToSearch === 'function') {
                        return data
                            .toUpperCase()
                            .match(searchInput.toUpperCase())
                    } else {
                        return data.name
                            .toUpperCase()
                            .match(searchInput.toUpperCase())
                    }
                })
            )
        } else {
            setSelectedMultiValueFilterData(dataToBeFiltered)
        }
    }, [searchInput])

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
    } = useForm()

    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)

    const tabsRef = useRef([])

    const tabs = [{ label: 'Get List' }, { label: 'Get Data' }]

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex]
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0)
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
        }

        setTabPosition()
        window.addEventListener('resize', setTabPosition)

        return () => window.removeEventListener('resize', setTabPosition)
    }, [activeTabIndex])

    const letterToColumn = (letter: string) => {
        var column = 0,
            length = letter.length
        for (var i = 0; i < length; i++) {
            column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1)
        }
        return column
    }

    const runScreenerData = async (
        data: any,
        shouldSave: boolean,
        event: 'GET_SCREENER_DATA' | 'REFRESH_BUCKET'
    ): Promise<void> => {
        if (!currentUser.isSubscribed) {
            setError({
                hasError: true,
                errorMessage: 'Please subscribe to screen stocks.',
            })
            return
        }
        setLoading({ isLoading: true, message: 'Fetching data...' })
        trackEvent(AmplitudeEvents.RUN_SCREENER_DATA, {
            is_refresh: event === 'REFRESH_BUCKET',
        })
        try {
            await Excel.run(async (context) => {
                let sheet = context.workbook.worksheets.getActiveWorksheet()
                const tickerRangeValues = sheet.getRange(data.tickerRange)
                const paramRangeValues = sheet.getRange(data.parameterRange)
                tickerRangeValues.load('values')
                paramRangeValues.load('values')
                await context.sync()
                if (tickerRangeValues.values.flat().length > 1001) {
                    setLoading({ isLoading: false, message: '' })

                    setError({
                        hasError: true,
                        errorMessage:
                            'Please select less than 1,000 tickers to get data.',
                    })
                    return
                }

                const resp = await httpClient.post(
                    '/api/private/screener/data',
                    {
                        tickers: tickerRangeValues.values.flat(),
                        parameters: paramRangeValues.values.flat(),
                    }
                )

                const column = letterToColumn(
                    data.parameterRange
                        .toUpperCase()
                        .split(':')[0]
                        .split(/[0-9]/)[0]
                )
                const row = Number(
                    data.tickerRange
                        .toUpperCase()
                        .split(':')[0]
                        .split(/[a-zA-Z]/)[1]
                )
                if (shouldSave) {
                    saveBucket({
                        tickerRange: data.tickerRange,
                        parameterRange: data.parameterRange,
                        name: 'Bucket ' + (buckets.length + 1),
                    })
                }

                var firstCell = sheet.getCell(row - 1, column - 1)
                var lastCell = sheet.getCell(
                    row + resp.data.length - 2,
                    column + resp.data[0].length - 2
                )
                var dataRange = firstCell.getBoundingRect(lastCell)
                dataRange.values = resp.data
                await context.sync()
            })
        } catch (e) {
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    useEffect(() => {
        setValue2('tickerRange', 'A1:A1')
        setValue2('parameterRange', 'B1:B1')
    }, [])

    const runScreenerDemo = async () => {
        setLoading({ isLoading: true, message: 'Running screener...' })
        trackEvent(AmplitudeEvents.RUN_SCREENER_DEMO)
        try {
            await Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets
                const sheetName =
                    'Screened data ' +
                    new Date().getHours() +
                    ' ' +
                    new Date().getMinutes() +
                    ' ' +
                    new Date().getSeconds()
                const newSheet = worksheets.add(sheetName)
                newSheet.activate()
                let sheet = context.workbook.worksheets.getActiveWorksheet()
                const tickers = [
                    'TSM',
                    'NVDA',
                    'T.TO',
                    'RELIANCE.NS',
                    'BHP.AX',
                    'HSBA.L',
                    'BNP.PA',
                    'AMD',
                    'AMAT',
                    'ADI',
                    'LRCX',
                    'MU',
                    'KLAC',
                    'APH',
                    'IFNNY',
                    'NXPI',
                    'MCHP',
                    'TEL',
                    'STM',
                    'MRVL',
                    'MRAAY',
                    'GFS',
                    'KEYS',
                    'ON',
                    'HXGBY',
                    'GLW',
                    'FTV',
                    'TDY',
                    'GRMN',
                    'UMC',
                ]
                const parameters = [
                    'Revenue',
                    'Total Stockholders Equity',
                    'Free Cash Flow',
                    'ROE',
                    'Revenue Growth',
                ]

                const resp = await httpClient.post(
                    '/api/private/screener/data',
                    {
                        tickers: tickers,
                        parameters: parameters,
                    }
                )

                let dataToWriteToSheet = resp.data

                for (let i = 0; i < dataToWriteToSheet.length; i++) {
                    dataToWriteToSheet[i].unshift(tickers[i])
                }

                parameters.unshift('Stock')

                dataToWriteToSheet.unshift(parameters)
                var firstCell = sheet.getCell(0, 0)
                var lastCell = sheet.getCell(
                    dataToWriteToSheet.length - 1,
                    dataToWriteToSheet[0].length - 1
                )
                var dataRange = firstCell.getBoundingRect(lastCell)
                dataRange.values = dataToWriteToSheet

                var firstCell1 = sheet.getCell(0, 0)
                var lastCell1 = sheet.getCell(
                    0,
                    dataToWriteToSheet[0].length - 1
                )
                var range1 = firstCell1.getBoundingRect(lastCell1)
                range1.format.fill.color = '#364165'
                range1.format.font.color = '#F7F8F8'

                var firstCell2 = sheet.getCell(1, 1)
                var lastCell2 = sheet.getCell(29, 3)
                var range2 = firstCell2.getBoundingRect(lastCell2)
                range2.numberFormat = [['#,##0.00_);(#,##0.00)']]

                var firstCell3 = sheet.getCell(1, 4)
                var lastCell3 = sheet.getCell(29, 5)
                var range3 = firstCell3.getBoundingRect(lastCell3)
                range3.numberFormat = [['0.00%']]

                context.workbook.worksheets
                    .getActiveWorksheet()
                    .getRange()
                    .format.autofitColumns()
                await context.sync()
            })
        } catch (e) {
            setError({ hasError: true, errorMessage: 'Something went wrong.' })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <div>
            <StyledPageDescription>
                {activeTabIndex === 0
                    ? 'Get a list of securities based on specific criteria'
                    : 'Get the data to compare hundreds of securities in one view'}
            </StyledPageDescription>
            <StyledTabsContainer>
                <StyledTabs>
                    {tabs.map((tab, index) => {
                        return (
                            <StyledTab
                                key={index}
                                ref={(el) => (tabsRef.current[index] = el)}
                                onClick={() => setActiveTabIndex(index)}
                            >
                                {tab.label}
                            </StyledTab>
                        )
                    })}
                    <StyledTabHighlight
                        style={{
                            left: tabUnderlineLeft,
                            width: tabUnderlineWidth,
                        }}
                    />
                </StyledTabs>
            </StyledTabsContainer>
            {!isPremiumUser && activeTabIndex === 0 ? (
                <StyledClickHere
                    style={{ alignSelf: 'center', marginBottom: 20 }}
                >
                    <StyledLink
                        target="_blank"
                        href={
                            currentUser.isSubscribed
                                ? 'https://www.wisesheets.io/manage-account'
                                : 'https://www.wisesheets.io/payment'
                        }
                    >
                        Upgrade to elite plan for access
                    </StyledLink>
                </StyledClickHere>
            ) : null}
            <div>
                {activeTabIndex === 0 ? (
                    <form
                        onSubmit={handleSubmit((data) => runScreenerV2(data))}
                        style={{
                            opacity: isPremiumUser ? 1 : 0.4,
                            pointerEvents: isPremiumUser ? 'all' : 'none',
                        }}
                    >
                        {renderValidationInputs()}
                        <StyledAddAnotherFilter
                            onClick={() => setIsSelectFiltersModalOpen(true)}
                        >
                            + Add another filter
                        </StyledAddAnotherFilter>
                        {Object.keys(errors).length > 0 ? (
                            <StyledErrorMessage>
                                Please make sure all fields are filled or remove
                                empty fields
                            </StyledErrorMessage>
                        ) : null}
                        <ActionButton title="Get data" type="submit" />
                    </form>
                ) : (
                    <form
                        onSubmit={handleSubmit2((data) =>
                            runScreenerData(data, true, 'GET_SCREENER_DATA')
                        )}
                        onKeyDown={(e) => checkKeyDown(e)}
                    >
                        <StyledRangeLabel htmlFor="tickerRange">
                            Ticker Range
                        </StyledRangeLabel>
                        <StyledCustomInputContainer>
                            <StyledCustomInput
                                id="tickerRange"
                                type="text"
                                placeholder="Ticker range"
                                {...register2('tickerRange', {
                                    required: true,
                                })}
                            />
                            <StyledRxGridContainer>
                                <StyledRxGrid
                                    data-tooltip-id="select-ticker-range"
                                    data-tooltip-content="Highlight a range then click me"
                                    onClick={async () => {
                                        await Excel.run(async (context) => {
                                            setLoading({
                                                isLoading: true,
                                                message: '',
                                            })
                                            let range =
                                                context.workbook.getSelectedRange()
                                            range.load('address')

                                            await context.sync()

                                            setValue2(
                                                'tickerRange',
                                                range.address.split('!')[1]
                                            )
                                            setLoading({
                                                isLoading: false,
                                                message: '',
                                            })
                                        })
                                    }}
                                />
                                <ReactTooltip
                                    id="select-ticker-range"
                                    place="top"
                                />
                            </StyledRxGridContainer>
                        </StyledCustomInputContainer>
                        <StyledRangeLabel htmlFor="parameterRange">
                            Parameter Range
                        </StyledRangeLabel>
                        <StyledCustomInputContainer>
                            <StyledCustomInput
                                id="parameterRange"
                                type="text"
                                placeholder="Parameter range"
                                {...register2('parameterRange', {
                                    required: true,
                                })}
                            />
                            <StyledRxGridContainer>
                                <StyledRxGrid
                                    data-tooltip-id="select-parameter-range"
                                    data-tooltip-content="Highlight a range then click me"
                                    onClick={async () => {
                                        await Excel.run(async (context) => {
                                            setLoading({
                                                isLoading: true,
                                                message: '',
                                            })
                                            let range =
                                                context.workbook.getSelectedRange()
                                            range.load('address')

                                            await context.sync()

                                            setValue2(
                                                'parameterRange',
                                                range.address.split('!')[1]
                                            )
                                            setLoading({
                                                isLoading: false,
                                                message: '',
                                            })
                                        })
                                    }}
                                />
                                <ReactTooltip
                                    id="select-parameter-range"
                                    place="top"
                                />
                            </StyledRxGridContainer>
                        </StyledCustomInputContainer>
                        <ActionButton type={'submit'} title={'Get Data'} />
                        {buckets.map((bucket, index) => {
                            return (
                                <BucketListItem
                                    key={bucket.name || index}
                                    bucket={bucket}
                                    onSetBucketName={setBucketName}
                                    onRemoveBucket={removeBucket}
                                    onSetBucketTickerRange={
                                        setBucketTickersRange
                                    }
                                    onSetBucketParameterRange={
                                        setBucketParametersRange
                                    }
                                    onRefreshData={runScreenerData}
                                    index={index}
                                />
                            )
                        })}
                    </form>
                )}

                <br />
                <br />
                {currentUser.isSubscribed ? null : (
                    <>
                        <StyledFullVersionContainer>
                            <a
                                href="https://wisesheets.io/payment"
                                target="_blank"
                                onClick={() => {
                                    trackEvent(
                                        AmplitudeEvents.UPGRADE_BUTTON_CLICKED
                                    )
                                }}
                            >
                                <StyledFullVersionButton>
                                    Get Full Version
                                </StyledFullVersionButton>
                            </a>
                            <br />
                            <br />
                        </StyledFullVersionContainer>
                        <StyledInfo>
                            This is a trial version of our screener get data
                            feature. To see a small scale usage of this feature,
                            please press the button below.
                        </StyledInfo>
                        <br />
                        <ActionButton
                            title="See demo"
                            type="button"
                            action={runScreenerDemo}
                        />
                    </>
                )}
            </div>
            {isSelectFiltersModalOpen ? (
                <StyledOverlay
                    id="overlay"
                    onClick={(e) => {
                        if (e.target == document.getElementById('overlay')) {
                            setIsSelectFiltersModalOpen(false)
                        }
                    }}
                >
                    <StyledPopupContainer>
                        <StyledPopupContent>
                            <StyledModalHeader>
                                Choose filters to screen stocks
                            </StyledModalHeader>
                            {renderFormOptions()}
                        </StyledPopupContent>
                        <StyledCloseButtonContainer>
                            <StyledCloseButton
                                onClick={() =>
                                    setIsSelectFiltersModalOpen(false)
                                }
                            >
                                Close
                            </StyledCloseButton>
                        </StyledCloseButtonContainer>
                    </StyledPopupContainer>
                </StyledOverlay>
            ) : null}
            {currentMultiSelectFilter ? (
                <StyledOverlay
                    id="overlay"
                    onClick={(e) => {
                        if (e.target == document.getElementById('overlay')) {
                            handleMultiSelectOptionsModalClose()
                        }
                    }}
                >
                    <StyledPopupContainer>
                        <StyledPopupContent>
                            <StyledModalHeader>
                                Choose filter options:
                            </StyledModalHeader>
                            {shouldShowSearch ? (
                                <StyledSearchInput
                                    type="text"
                                    placeholder="Search here"
                                    onChange={handleChange}
                                    value={searchInput}
                                />
                            ) : null}
                            <StyledPopupData>
                                {selectedMiltiValueFilterData.map(
                                    (obj, index) => {
                                        if (typeof obj === 'object') {
                                            return (
                                                <StyledFilterOption key={index}>
                                                    <input
                                                        checked={shouldMultiFilterOptionBeChecked(
                                                            obj.short
                                                        )}
                                                        value={obj.short}
                                                        type="checkbox"
                                                        onChange={
                                                            handleMultiSelectValueCheckboxChange
                                                        }
                                                        id={`${obj.name}checkbox`}
                                                    />
                                                    <StyledCheckboxLabel
                                                        htmlFor={`${obj.name}checkbox`}
                                                    >
                                                        {obj.name}
                                                    </StyledCheckboxLabel>
                                                </StyledFilterOption>
                                            )
                                        } else {
                                            return (
                                                <StyledFilterOption key={index}>
                                                    <input
                                                        checked={shouldMultiFilterOptionBeChecked(
                                                            obj
                                                        )}
                                                        value={obj}
                                                        type="checkbox"
                                                        onChange={
                                                            handleMultiSelectValueCheckboxChange
                                                        }
                                                        id={`${obj}checkbox`}
                                                    />
                                                    <StyledCheckboxLabel
                                                        htmlFor={`${obj}checkbox`}
                                                    >
                                                        {obj}
                                                    </StyledCheckboxLabel>
                                                </StyledFilterOption>
                                            )
                                        }
                                    }
                                )}
                            </StyledPopupData>
                        </StyledPopupContent>
                        <StyledCloseButtonContainer>
                            <StyledCloseButton
                                onClick={handleMultiSelectOptionsModalClose}
                            >
                                Done
                            </StyledCloseButton>
                        </StyledCloseButtonContainer>
                    </StyledPopupContainer>
                </StyledOverlay>
            ) : null}
            <StyledLinkFooter>
                <StyledLink
                    target="_blank"
                    href="https://wisesheets.io/pages/docs#custom_screener"
                >
                    Wisesheets docs
                </StyledLink>
            </StyledLinkFooter>
        </div>
    )
}

export { Screener }
