import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { httpClient } from '../../utils/http'
import ActionButton from '../../components/ActionButton/ActionButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAuthStore, useErrorStore, useLoadingStore } from '../../state/store'
import {
    StyledContent,
    StyledBack,
    StyledHeader,
    StyledWhite,
    StyledGreen,
    StyledLoginForm,
    StyledInputContainer,
    StyledInput,
    StyledLoginButton,
    StyledNewUser,
    StyledValueProp,
    StyledPolicies,
    StyledLink,
    StyledTooltip,
} from './styles'
import { IoMdInformationCircleOutline } from 'react-icons/io'

export default function PasswordlessLogin() {
    // *********** state *********** //
    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)
    const [mojoAuthStateId, setMojoAuthStateId] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const { setAuth, currentUser } = useAuthStore((state) => state)
    const navigate = useNavigate()
    const { register, handleSubmit, reset } =
        useForm<{ email: string; otp: string }>()

    function goBack() {
        navigate('/')
    }

    const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: { state_id: string } } =
                await httpClient.post(
                    '/public/auth/send-otp',
                    {
                        email: data.email.trim(),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
            setEmail(data.email.trim())
            setMojoAuthStateId(response.data.state_id)
            reset()
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setError({
                    hasError: true,
                    errorMessage: 'Please make sure you have an account.',
                })
            } else {
                setError({
                    hasError: true,
                    errorMessage: 'Error while sending OTP.',
                })
            }
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const onSubmitCode: SubmitHandler<{ otp: string }> = async (data) => {
        setLoading({ isLoading: true, message: '' })
        try {
            const response: { data: any } = await httpClient.post(
                '/public/auth/verify-otp',
                {
                    otp: data.otp.trim(),
                    state_id: mojoAuthStateId,
                    platform: 'EXCEL',
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            const token = response.data.token
            window.localStorage.setItem('token', token)
            setAuth(token)
            setLoading({ isLoading: false, message: '' })
            navigate('/feature/statement-dump')
        } catch (e) {
            setLoading({ isLoading: false, message: '' })
            setError({ hasError: true, errorMessage: e.response.data })
        }
    }

    return (
        <>
            <StyledContent>
                <StyledBack onClick={goBack}>Back</StyledBack>
                <StyledHeader>
                    <StyledWhite>
                        Wise<StyledGreen>sheets</StyledGreen>
                    </StyledWhite>
                </StyledHeader>
                <br />

                {mojoAuthStateId === '' ? (
                    <>
                        <StyledValueProp>
                            We will send a one-time passcode to the email you
                            enter below.
                            <IoMdInformationCircleOutline
                                data-tooltip-id="otp-info"
                                data-tooltip-content="With OTP you'll be kept logged in. No need to log in every time you access Wisesheets."
                            />
                        </StyledValueProp>

                        <StyledTooltip id="otp-info" place="bottom" />

                        <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
                            <StyledInputContainer>
                                <StyledInput
                                    type="text"
                                    id="email"
                                    placeholder="Email"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'invalid email address',
                                        },
                                    })}
                                />
                            </StyledInputContainer>
                            <StyledLoginButton>
                                <ActionButton
                                    type={'submit'}
                                    title={'Login without password'}
                                />
                            </StyledLoginButton>
                        </StyledLoginForm>
                    </>
                ) : (
                    <>
                        <StyledValueProp>
                            We just sent an OTP to {email}.
                        </StyledValueProp>
                        <StyledLoginForm onSubmit={handleSubmit(onSubmitCode)}>
                            <StyledInputContainer>
                                <StyledInput
                                    type="text"
                                    id="otp"
                                    placeholder="OTP"
                                    {...register('otp', {
                                        required: true,
                                        pattern: {
                                            value: /^[0-9]{6}$/i,
                                            message: 'Invalid otp',
                                        },
                                    })}
                                />
                            </StyledInputContainer>
                            <StyledLoginButton>
                                <ActionButton
                                    type={'submit'}
                                    title={'Submit'}
                                />
                            </StyledLoginButton>
                        </StyledLoginForm>
                    </>
                )}
                <StyledNewUser>
                    New to Wisesheets?{' '}
                    <StyledLink
                        href="https://wisesheets.io/signup"
                        target="_blank"
                    >
                        Make an account
                    </StyledLink>
                    <br />
                    Forgot password?{' '}
                    <StyledLink
                        href="https://wisesheets.io/forgot-password"
                        target="_blank"
                    >
                        Reset password
                    </StyledLink>
                </StyledNewUser>
                <br />
                <StyledPolicies>
                    <StyledLink
                        href="https://wisesheets.io/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </StyledLink>
                    <StyledLink
                        href="https://wisesheets.io/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </StyledLink>
                </StyledPolicies>
            </StyledContent>
        </>
    )
}
