// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IHXTaNHbsdTOpn43yyGG {\n    min-height: 100vh;\n    width: 100%;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -ms-flex-direction: column;\n    flex-direction: column;\n    background-color: #364165;\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n    color: #f3f3f3;\n    font-size: 15px;\n    overflow-y: scroll;\n    padding: 0 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/PageRoot/PageRoot.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;IAC7B,0BAA0B;IAC1B,sBAAsB;IACtB,yBAAyB;IACzB,8BAA8B;IAC9B,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".taskPane {\n    min-height: 100vh;\n    width: 100%;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -ms-flex-direction: column;\n    flex-direction: column;\n    background-color: #364165;\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n    color: #f3f3f3;\n    font-size: 15px;\n    overflow-y: scroll;\n    padding: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskPane": "IHXTaNHbsdTOpn43yyGG"
};
export default ___CSS_LOADER_EXPORT___;
