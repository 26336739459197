import React, { useEffect, useRef, useState } from 'react'
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest'
import Autosuggest from 'react-autosuggest'
import { API_URL_V3 } from '../StatementDump/constants'
import { RiCloseLine } from 'react-icons/ri'
import Select from 'react-dropdown-select'
import Fuse from 'fuse.js'
import {
    ALL_PARAMETERS,
    AUTOSUGGEST_CATEGORY_LIST,
    formatDate,
    isSubset,
    PUT_OR_CALL_OPTIONS,
    QUARTER_OPTIONS,
    SEMICOLON_COUNTRY_CODES,
} from './constants'
import { PeriodModal } from './Modal/PeriodModal'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import copy from 'copy-to-clipboard'
import { useAuthStore, useLoadingStore } from '../../state/store'
import { IoCopyOutline } from 'react-icons/io5'
import {
    StyledAddPeriodButton,
    StyledAllDatesRadio,
    StyledAllDatesRadioContainer,
    StyledArithmeticFilter,
    StyledAutoSuggestionItem,
    StyledAutoSuggestWrapper,
    StyledClickHere,
    StyledContainer,
    StyledDateInputs,
    StyledDateTitle,
    StyledFilter,
    StyledFilterContainer,
    StyledFilterTitle,
    StyledFullVersionButton,
    StyledFullVersionContainer,
    StyledGeneratedFunction,
    StyledGeneratedFunctionSpacer,
    StyledInput,
    StyledLink,
    StyledRangeInputContainer,
    StyledRxGrid,
    StyledSelectedValue,
    StyledSelectionContainer,
    StyledUnsubscribedBanner,
} from './styles'
import { useTheme } from 'styled-components'
import { httpClient } from '../../utils/http'
import { EXCHANGE_PRIORITY_LIST } from '../../../constants'
import { AmplitudeEvents, useAmplitudeTrack } from '../../hooks/amplitude'

const fuseOptions = {
    keys: ['parameter'],
}

const EXCHANGE_PRIORITY_LIST_WITH_INDEX = [...EXCHANGE_PRIORITY_LIST, 'INDEX']

const fuse = new Fuse(ALL_PARAMETERS(), fuseOptions)

const FunctionBuilder = () => {
    const theme = useTheme()
    const trackEvent = useAmplitudeTrack()
    const [suggestedTickers, setSuggestedTickers] = useState<
        TickerSuggestion[]
    >([])
    const [suggestedParameters, setSuggestedParameters] = useState<
        PARAMETER_AND_CATEGORY[]
    >([])

    const { setLoading } = useLoadingStore((state) => state)
    const [tickerQuery, setTickerQuery] = useState<string>('')
    const [parameterQuery, setParameterQuery] = useState<string>('')
    const [selectedTickers, setSelectedTickers] = useState<TickerSuggestion[]>(
        []
    )
    const [selectedParameters, setSelectedParameters] = useState<
        PARAMETER_AND_CATEGORY[]
    >([])

    const [symbolInputMechanic, setSymbolInputMechanic] = useState<
        'search' | 'select_range'
    >('search')
    useEffect(() => {
        setSelectedTickers([
            {
                symbol: 'AAPL',
                name: 'Apple Inc',
                exchangeShortName: 'NASDAQ',
                stockExchange: 'NASDAQ',
                currency: 'USD',
            },
        ])
        const defaultParameters: PARAMETER_AND_CATEGORY[] = [
            {
                parameter: 'Revenue',
                category: 'financial_statements_and_key_metrics',
            },
        ]
        setSelectedParameters(defaultParameters)
        setSelectedPeriods([{ id: 12, name: '2021', type: 'SET_YEAR' }])
        setAllowedDateSelections(
            findCommonItemsOfDateWhitelistsForActiveCategories(
                defaultParameters
            )
        )
    }, [])

    const [startDate, setStartDate] = useState<string>(formatDate(new Date()))
    const [endDate, setEndDate] = useState<string>(formatDate(new Date()))
    const [isAllDates, setIsAllDates] = useState(false)
    const [isNumberOfDays, setIsNumberOfDays] = useState(false)
    const [numberOfDays, setNumberOfDays] = useState(null)

    const [isOptionsDataRealtime, setIsOptionsDataRealtime] = useState(false)

    const [selectedQuarter, setSelectedQuarter] = useState<PERIOD_OPTION[]>([])
    const [putOrCall, setPutOrCall] = useState<{ id: number; name: string }[]>([
        PUT_OR_CALL_OPTIONS[0],
    ])
    const [selectedPeriods, setSelectedPeriods] = useState<PERIOD_OPTION[]>([])
    const [allowedDateSelections, setAllowedDateSelections] = useState<
        DATE_SELECTIONS[]
    >([])
    const [shouldAddDateColumn, setShouldAddDateColumn] = useState(true)
    const [tooltipText, setTooltipText] = useState('Click to copy')

    const [shouldShowPeriodModal, setShouldShowPeriodModal] = useState(false)
    const paramSuggestionsInput = useRef(null)

    const renderTickerSuggestion = (ticker: TickerSuggestion) => {
        return (
            <div>
                {ticker.name} - {ticker.symbol}
            </div>
        )
    }
    const onTickerChange = (event: any, { newValue }: any) => {
        setTickerQuery(newValue)
    }

    const isStringAFunctionRange = (str: string): boolean => {
        const regex = /[A-Za-z]+[0-9]+:[A-Za-z]+[0-9]+/
        // determine if a string has a semicolon in it
        return regex.test(str)
    }
    const getSuggestedTickers = async (value: { value: string }) => {
        if (value.value.length < 2) return
        try {
            const ticker = value.value.toUpperCase()
            const response: { data: TickerSuggestion[] } = await httpClient.get(
                API_URL_V3 +
                    'search?query=' +
                    ticker +
                    `&limit=10&exchange=${EXCHANGE_PRIORITY_LIST_WITH_INDEX}`
            )

            const sortedByExchangePriority = response.data
                .sort((a, b) => {
                    return (
                        EXCHANGE_PRIORITY_LIST_WITH_INDEX.indexOf(
                            a.exchangeShortName.toUpperCase()
                        ) -
                        EXCHANGE_PRIORITY_LIST_WITH_INDEX.indexOf(
                            b.exchangeShortName.toUpperCase()
                        )
                    )
                })
                .filter((a) =>
                    EXCHANGE_PRIORITY_LIST_WITH_INDEX.includes(
                        a.exchangeShortName
                    )
                )
                .slice(0, 5)

            setSuggestedTickers(sortedByExchangePriority)
        } catch (e) {}
    }

    const getSuggestedParameters = (paramQuery: string) => {
        const fuseResult = fuse.search(paramQuery)
        const filteredParameters =
            paramQuery.length !== 0
                ? fuseResult.map((result) => result.item)
                : ALL_PARAMETERS()

        if (selectedParameters.length > 0) {
            const categoriesSelectedParamsBelongTo: CATEGORY_NAMES[] =
                selectedParameters.map((param) => param.category)

            const allowedCategoryObjects = AUTOSUGGEST_CATEGORY_LIST.filter(
                (category_obj) =>
                    categoriesSelectedParamsBelongTo.some(
                        (category_name) =>
                            category_obj.parameters_can_be_selected_with.includes(
                                category_name
                            ) || category_obj.bucket_name === category_name
                    )
            )

            const filteredParametersExcludingCurrentlySelected =
                filteredParameters.filter((param) => {
                    return !selectedParameters.some(
                        (selectedParam) =>
                            selectedParam.parameter === param.parameter
                    )
                })

            // If they already have some periods selected, we need to remove the categories that don't allow those periods
            const allowedCategoryObjectsConsideringSelectedPeriods =
                selectedPeriods.length > 0
                    ? allowedCategoryObjects.filter((category) => {
                          return isSubset(
                              selectedPeriods.map((period) => period.type),
                              category.date_selection_whitelist
                          )
                      })
                    : [...allowedCategoryObjects]

            const allowedParameters: PARAMETER_AND_CATEGORY[] =
                allowedCategoryObjectsConsideringSelectedPeriods.flatMap(
                    (category) =>
                        category.parameters.map((parameter) => ({
                            parameter,
                            category: category.bucket_name,
                        }))
                )

            const results = filteredParametersExcludingCurrentlySelected.map(
                (param) => {
                    const isAllowed = allowedParameters.some(
                        (filteredParam) =>
                            filteredParam.parameter === param.parameter
                    )
                    return { ...param, isAllowed }
                }
            )
            setSuggestedParameters(results)
        } else {
            const everythingAllowed = filteredParameters.map((param) => {
                return { ...param, isAllowed: true }
            })
            setSuggestedParameters(everythingAllowed)
        }
    }

    const symbolInputProps = {
        placeholder: 'Search for tickers',
        value: tickerQuery,
        onChange: onTickerChange,
    }

    const findCommonItemsOfDateWhitelistsForActiveCategories = (
        parameters: PARAMETER_AND_CATEGORY[]
    ): DATE_SELECTIONS[] => {
        if (parameters.length === 0) {
            return []
        }
        const activeCategories = AUTOSUGGEST_CATEGORY_LIST.filter((category) =>
            parameters.some((param) =>
                category.parameters.includes(param.parameter)
            )
        )
        if (activeCategories.length === 1) {
            return activeCategories[0].date_selection_whitelist
        }
        const activeDateWhitelists = activeCategories.map(
            (category) => category.date_selection_whitelist
        )
        return activeDateWhitelists.reduce((acc, list) => {
            return acc.filter((value) => list.includes(value))
        })
    }

    const buildFunctionCall = () => {
        const activeCategories = AUTOSUGGEST_CATEGORY_LIST.filter((category) =>
            selectedParameters.some((param) =>
                category.parameters.includes(param.parameter)
            )
        )
        if (
            activeCategories.length === 0 ||
            selectedTickers.length === 0 ||
            (selectedPeriods.length === 0 &&
                // these fields aren't selected in the PeriodModal, we still want to render a function if the below date types are selected
                !allowedDateSelections.includes('SPECIFIC_DATE_RANGE') &&
                !allowedDateSelections.includes('ALL_DATES') &&
                !allowedDateSelections.includes('NO_DATE') &&
                !allowedDateSelections.includes('SINGLE_DATE'))
        ) {
            return ''
        }

        const functionNamesWithDuplicates = activeCategories.map(function (
            category
        ) {
            return category.function_name
        })

        const seen = {}
        const functionNames = []

        for (let i = 0; i < functionNamesWithDuplicates.length; i++) {
            const name = functionNamesWithDuplicates[i]
            if (!seen[name]) {
                functionNames.push(name)
                seen[name] = true
            }
        }

        const functionName =
            functionNames.length === 1 ? functionNames[0] : 'MULTIPLE'

        const symbolField =
            selectedTickers.length > 1
                ? `{${selectedTickers
                      .map((ticker) => `"${ticker.symbol}"`)
                      .join(',')}}`
                : isStringAFunctionRange(selectedTickers[0].symbol)
                ? `${selectedTickers[0].symbol}`
                : `"${selectedTickers[0].symbol}"`

        const optionsTypeField = allowedDateSelections.includes('SINGLE_DATE')
            ? `"${putOrCall[0].name}"% `
            : ''

        const parameterField =
            selectedParameters.length > 1
                ? `{${selectedParameters
                      .map(
                          (parameter) =>
                              `"${parameter.parameter.split('-')[0].trim()}"` // remove description for duplicated fields
                      )
                      .join(',')}}`
                : `"${selectedParameters[0].parameter.split('-')[0].trim()}"`

        const dateField =
            startDate &&
            endDate &&
            allowedDateSelections.includes('SPECIFIC_DATE_RANGE')
                ? `, "${startDate}", "${endDate}"`
                : allowedDateSelections.includes('SINGLE_DATE') &&
                  startDate &&
                  !isOptionsDataRealtime
                ? `, "${startDate}"`
                : ''

        const periodField =
            selectedPeriods.length === 1
                ? `% ${
                      ['SET_YEAR', 'SET_YEAR_FUTURE'].includes(
                          selectedPeriods[0].type
                      )
                          ? selectedPeriods[0].name
                          : `"${selectedPeriods[0].name}"`
                  }`
                : selectedPeriods.length === 0
                ? ''
                : `% {${selectedPeriods
                      .map(
                          (period) =>
                              `${
                                  ['SET_YEAR', 'SET_YEAR_FUTURE'].includes(
                                      period.type
                                  )
                                      ? period.name
                                      : `"${period.name}"`
                              }`
                      )
                      .join(',')}}`

        const quarterField = selectedQuarter.length
            ? `% "${selectedQuarter[0].name}"`
            : ''

        const numberOfDaysField =
            allowedDateSelections.includes('SPECIFIC_DATE_RANGE') && !isAllDates
                ? numberOfDays !== null && !isNaN(numberOfDays)
                    ? `, ${numberOfDays}`
                    : ','
                : ''

        let builtFunction = `=${functionName}(${symbolField}% ${optionsTypeField}${parameterField}${numberOfDaysField}${
            periodField || dateField
        }${quarterField})`

        if (!shouldAddDateColumn) {
            return `=INDEX(${builtFunction.replace('=', '')}, 0, 2)`
        }

        return builtFunction
    }

    const updateAllowedDateSelectionsAndResetForm = (
        newParameters: PARAMETER_AND_CATEGORY[]
    ) => {
        setAllowedDateSelections(
            findCommonItemsOfDateWhitelistsForActiveCategories(newParameters)
        )

        if (selectedParameters.length === 0) {
            setSelectedPeriods([])
        }

        setSelectedQuarter([])
        setNumberOfDays(null)
        setStartDate(formatDate(new Date()))
        setEndDate(formatDate(new Date()))
        setIsAllDates(false)
        setIsNumberOfDays(false)
        setShouldAddDateColumn(true)
    }
    const functionCall = buildFunctionCall()
    const { currentUser } = useAuthStore((state) => state)
    return (
        <StyledContainer>
            {!currentUser.isSubscribed ? (
                <StyledUnsubscribedBanner>
                    You're on the free trial, you can only access data from
                    AAPL, TSLA, and AMZN
                </StyledUnsubscribedBanner>
            ) : (
                <br />
            )}
            {/************************** SYMBOL INPUT *************************/}
            <StyledFilterContainer>
                <StyledFilter>
                    <StyledFilterTitle>Symbol/s</StyledFilterTitle>
                    <StyledArithmeticFilter
                        value={symbolInputMechanic}
                        onChange={(event) => {
                            setSelectedTickers([])
                            const value = event.target.value as
                                | 'search'
                                | 'select_range'
                            setSymbolInputMechanic(value)
                        }}
                    >
                        <option value={'search'}>Search</option>
                        <option value={'select_range'}>Range</option>
                    </StyledArithmeticFilter>
                </StyledFilter>
                {symbolInputMechanic === 'select_range' ? (
                    <>
                        <StyledRangeInputContainer>
                            <StyledInput
                                placeholder={'A1:C1'}
                                onChange={(event) => {
                                    if (
                                        isStringAFunctionRange(
                                            event.target.value
                                        )
                                    ) {
                                        setSelectedTickers([
                                            {
                                                symbol: event.target.value.toUpperCase(),
                                                name: event.target.value,
                                                exchangeShortName: 'NASDAQ',
                                                stockExchange: 'NASDAQ',
                                                currency: 'USD',
                                            },
                                        ])
                                    }
                                }}
                            />
                            <StyledRxGrid
                                data-tooltip-id="select-ticker-range"
                                data-tooltip-content="Highlight a range then click me"
                                onClick={async () => {
                                    await Excel.run(async (context) => {
                                        setLoading({
                                            isLoading: true,
                                            message: '',
                                        })
                                        let range =
                                            context.workbook.getSelectedRange()
                                        range.load('address')

                                        await context.sync()
                                        setSelectedTickers([
                                            {
                                                symbol: range.address.split(
                                                    '!'
                                                )[1],
                                                name: range.address.split(
                                                    '!'
                                                )[1],
                                                exchangeShortName: 'NASDAQ',
                                                stockExchange: 'NASDAQ',
                                                currency: 'USD',
                                            },
                                        ])

                                        setLoading({
                                            isLoading: false,
                                            message: '',
                                        })
                                    })
                                }}
                            />
                            <ReactTooltip
                                id="select-ticker-range"
                                place="top"
                            />
                        </StyledRangeInputContainer>
                    </>
                ) : (
                    <StyledAutoSuggestWrapper>
                        <Autosuggest
                            suggestions={suggestedTickers}
                            onSuggestionSelected={(
                                event: any,
                                { suggestion }: any
                            ) => {
                                setSelectedTickers([
                                    ...selectedTickers,
                                    suggestion,
                                ])
                                setTickerQuery('')
                            }}
                            onSuggestionsFetchRequested={getSuggestedTickers}
                            onSuggestionsClearRequested={() =>
                                setSuggestedTickers([])
                            }
                            getSuggestionValue={(symbol: TickerSuggestion) => {
                                return symbol.symbol
                            }}
                            renderSuggestion={renderTickerSuggestion}
                            inputProps={symbolInputProps}
                            focusInputOnSuggestionClick={false}
                        />
                    </StyledAutoSuggestWrapper>
                )}

                <StyledSelectionContainer>
                    {selectedTickers.map((ticker) => {
                        return (
                            <StyledSelectedValue key={ticker.symbol}>
                                {ticker.symbol}
                                <RiCloseLine
                                    size={14}
                                    style={{
                                        marginLeft: 2,
                                        cursor: 'pointer',
                                    }}
                                    color={theme.colours.font.dark_grey}
                                    onClick={() => {
                                        setSelectedTickers(
                                            selectedTickers.filter(
                                                (selectedTicker) =>
                                                    selectedTicker.symbol !==
                                                    ticker.symbol
                                            )
                                        )
                                    }}
                                />
                            </StyledSelectedValue>
                        )
                    })}
                </StyledSelectionContainer>
            </StyledFilterContainer>
            {/************************** PARAMETER INPUT *************************/}
            <StyledFilterContainer>
                <StyledFilterTitle>Parameter/s</StyledFilterTitle>
                <AutoSuggest
                    onInputFocus={() => getSuggestedParameters('')}
                    inputRef={paramSuggestionsInput}
                    onInputBlur={() => {
                        setSuggestedParameters([])
                    }}
                    onInputChange={(e) => {
                        setParameterQuery(e.target.value)
                        getSuggestedParameters(e.target.value)
                    }}
                    placeholder={'Search for parameters'}
                    inputValue={parameterQuery}
                    width={241}
                    currentSuggestions={suggestedParameters}
                    renderSuggestion={(suggestion: PARAMETER_AND_CATEGORY) => (
                        <a
                            title={
                                suggestion.isAllowed
                                    ? null
                                    : "Items from different datasets can't be selected together"
                            }
                        >
                            <StyledAutoSuggestionItem
                                key={suggestion.parameter + suggestion.category}
                                style={{
                                    color: suggestion.isAllowed
                                        ? 'black'
                                        : 'lightgrey',
                                    cursor: suggestion.isAllowed
                                        ? 'pointer'
                                        : 'not-allowed',
                                }}
                                onMouseDown={(e) => {
                                    setSuggestedParameters([])
                                    setParameterQuery('')
                                    if (!suggestion.isAllowed) return
                                    setSelectedParameters((prevState) => {
                                        const newState = [
                                            ...selectedParameters,
                                            suggestion,
                                        ]
                                        updateAllowedDateSelectionsAndResetForm(
                                            newState
                                        )
                                        return newState
                                    })
                                }}
                            >
                                {suggestion.parameter}
                            </StyledAutoSuggestionItem>
                        </a>
                    )}
                />
                <StyledSelectionContainer>
                    {selectedParameters.map((parameter) => {
                        return (
                            <StyledSelectedValue
                                key={parameter.category + parameter.parameter}
                            >
                                {parameter.parameter}
                                <RiCloseLine
                                    size={16}
                                    style={{
                                        marginLeft: 4,
                                        cursor: 'pointer',
                                    }}
                                    color={theme.colours.font.dark_grey}
                                    onClick={() => {
                                        setSelectedParameters((prevState) => {
                                            const newState = prevState.filter(
                                                (selectedParameter) =>
                                                    selectedParameter.parameter !==
                                                    parameter.parameter
                                            )
                                            updateAllowedDateSelectionsAndResetForm(
                                                newState
                                            )
                                            return newState
                                        })
                                    }}
                                />
                            </StyledSelectedValue>
                        )
                    })}
                </StyledSelectionContainer>
            </StyledFilterContainer>
            {allowedDateSelections.includes('SINGLE_DATE') && (
                <>
                    <StyledFilterContainer>
                        <StyledDateTitle style={{ marginBottom: 8 }}>
                            Date
                        </StyledDateTitle>
                        <StyledDateInputs>
                            <input
                                aria-label="Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value)
                                }}
                                disabled={isOptionsDataRealtime}
                            />
                        </StyledDateInputs>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 8,
                            }}
                        >
                            <StyledAllDatesRadio
                                style={{
                                    backgroundColor: isOptionsDataRealtime
                                        ? theme.colours.background.secondary
                                        : theme.colours.background.primary,
                                    borderRadius: 5,
                                }}
                                onClick={() => {
                                    setIsOptionsDataRealtime((prevState) => {
                                        return !prevState
                                    })
                                }}
                            />
                            Real time?
                        </div>
                    </StyledFilterContainer>
                    <StyledFilterContainer>
                        <Select
                            multi={false}
                            options={PUT_OR_CALL_OPTIONS}
                            values={putOrCall}
                            closeOnClickInput={true}
                            searchable={false}
                            clearable={true}
                            labelField="name"
                            style={{
                                width: 175,
                                minHeight: 0,
                                borderRadius: 4,
                            }}
                            valueField="id"
                            onChange={(values) => {
                                setPutOrCall(values)
                            }}
                        />
                    </StyledFilterContainer>
                </>
            )}

            {/************************** DATE INPUT *************************/}
            {allowedDateSelections.includes('SPECIFIC_DATE_RANGE') && (
                <StyledFilterContainer>
                    <StyledDateTitle style={{ marginBottom: 8 }}>
                        Specific Dates
                    </StyledDateTitle>
                    <StyledDateInputs>
                        <input
                            aria-label="Date"
                            type="date"
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.target.value)
                            }}
                            disabled={isAllDates || numberOfDays !== null}
                        />
                        <input
                            aria-label="Date"
                            type="date"
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(e.target.value)
                            }}
                            disabled={isAllDates || numberOfDays !== null}
                        />
                    </StyledDateInputs>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 8,
                            marginBottom: 8,
                        }}
                    >
                        <StyledAllDatesRadio
                            style={{
                                backgroundColor: shouldAddDateColumn
                                    ? theme.colours.background.secondary
                                    : theme.colours.background.primary,
                                borderRadius: 5,
                            }}
                            onClick={() => {
                                setShouldAddDateColumn((prevState) => {
                                    return !prevState
                                })
                            }}
                        />
                        Include date column
                    </div>
                    {allowedDateSelections.includes('ALL_DATES') && (
                        <StyledAllDatesRadioContainer>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <StyledAllDatesRadio
                                    style={{
                                        backgroundColor: isAllDates
                                            ? theme.colours.background.secondary
                                            : theme.colours.background.primary,
                                        borderRadius: 5,
                                    }}
                                    onClick={() => {
                                        setIsAllDates((prevState) => {
                                            const newState = !prevState
                                            if (newState) {
                                                setStartDate(null)
                                                setEndDate(null)
                                            } else {
                                                setStartDate(
                                                    formatDate(new Date())
                                                )
                                                setEndDate(
                                                    formatDate(new Date())
                                                )
                                            }
                                            return newState
                                        })
                                    }}
                                />
                                All dates?
                            </div>
                        </StyledAllDatesRadioContainer>
                    )}
                    {allowedDateSelections.includes('NUMBER_OF_DAYS') && (
                        <StyledAllDatesRadioContainer>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <StyledAllDatesRadio
                                    style={{
                                        backgroundColor: isNumberOfDays
                                            ? theme.colours.background.secondary
                                            : theme.colours.background.primary,
                                        borderRadius: 5,
                                    }}
                                    onClick={() => {
                                        setIsNumberOfDays((prevState) => {
                                            const newState = !prevState
                                            if (newState) {
                                                setStartDate(null)
                                                setEndDate(null)
                                                setNumberOfDays(10)
                                            } else {
                                                setStartDate(
                                                    formatDate(new Date())
                                                )
                                                setEndDate(
                                                    formatDate(new Date())
                                                )
                                                setNumberOfDays(null)
                                            }
                                            return newState
                                        })
                                    }}
                                />
                                Number of days
                            </div>
                        </StyledAllDatesRadioContainer>
                    )}
                </StyledFilterContainer>
            )}
            {allowedDateSelections.includes('NUMBER_OF_DAYS') &&
                numberOfDays !== null && (
                    <StyledFilterContainer>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <StyledDateTitle
                                style={{ marginBottom: 0, marginRight: 20 }}
                            >
                                Number of Days:
                            </StyledDateTitle>
                            <StyledInput
                                type="number"
                                style={{
                                    width: 60,
                                    textAlign: 'center',
                                    fontSize: 16,
                                }}
                                value={numberOfDays}
                                onChange={(e) =>
                                    setNumberOfDays(parseInt(e.target.value))
                                }
                            />
                        </div>
                    </StyledFilterContainer>
                )}
            {(allowedDateSelections.includes('SET_YEAR') ||
                allowedDateSelections.includes('SET_YEAR_FUTURE') ||
                allowedDateSelections.includes('LY') ||
                allowedDateSelections.includes('LQ') ||
                allowedDateSelections.includes('TTM')) && (
                <StyledFilterContainer>
                    <div style={{ display: 'flex' }}>
                        <StyledDateTitle>Period/s</StyledDateTitle>
                        <StyledAddPeriodButton
                            onClick={() => setShouldShowPeriodModal(true)}
                        >
                            + Add period/s
                        </StyledAddPeriodButton>
                    </div>

                    <div>
                        <StyledSelectionContainer>
                            {selectedPeriods.map((period) => {
                                return (
                                    <StyledSelectedValue key={period.id}>
                                        {period.name}
                                        <RiCloseLine
                                            size={16}
                                            style={{
                                                marginLeft: 4,
                                                cursor: 'pointer',
                                            }}
                                            color={theme.colours.font.dark_grey}
                                            onClick={() => {
                                                setSelectedPeriods(
                                                    selectedPeriods.filter(
                                                        (selectedPeriod) =>
                                                            selectedPeriod !==
                                                            period
                                                    )
                                                )
                                            }}
                                        />
                                    </StyledSelectedValue>
                                )
                            })}
                        </StyledSelectionContainer>
                    </div>
                </StyledFilterContainer>
            )}
            {allowedDateSelections.includes('SET_QUARTER') &&
                selectedPeriods.length !== 0 &&
                // hide quarter selection if TTM, LQ or LQ-N is selected
                !['TTM', 'LQ', 'LY', 'LY-N', 'LQ-N'].some(
                    (type: DATE_SELECTIONS) =>
                        selectedPeriods
                            .map((option) => option.type)
                            .includes(type)
                ) && (
                    <StyledFilterContainer
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <StyledDateTitle>Quarter</StyledDateTitle>
                        <Select
                            multi={false}
                            options={QUARTER_OPTIONS}
                            values={selectedQuarter}
                            closeOnClickInput={true}
                            searchable={false}
                            clearable={true}
                            labelField="name"
                            style={{
                                width: 175,
                                minHeight: 0,
                                borderRadius: 4,
                            }}
                            valueField="id"
                            onChange={(values) => {
                                setSelectedQuarter(values)
                            }}
                        />
                    </StyledFilterContainer>
                )}
            {functionCall && (
                <>
                    <StyledGeneratedFunctionSpacer />
                    <StyledGeneratedFunction
                        data-tooltip-id="wise-function-ex1"
                        data-tooltip-content="Click to copy"
                        onClick={() => {
                            const myLanguage = Office.context.displayLanguage
                            if (SEMICOLON_COUNTRY_CODES.includes(myLanguage)) {
                                copy(functionCall.replaceAll('%', ';'), {
                                    format: 'text/plain',
                                })
                            } else {
                                copy(functionCall.replaceAll('%', ','), {
                                    format: 'text/plain',
                                })
                            }
                            setTooltipText('Copied!')
                        }}
                    >
                        <ReactTooltip
                            place="top"
                            afterHide={() => setTooltipText('Click to copy')}
                            content={tooltipText}
                            id="wise-function-ex1"
                        />
                        {buildFunctionCall().replaceAll('%', ',')}
                        <IoCopyOutline color={theme.colours.font.green} />
                    </StyledGeneratedFunction>
                </>
            )}

            <PeriodModal
                onExternalClick={() => {
                    setShouldShowPeriodModal(false)
                }}
                allowedPeriods={allowedDateSelections}
                selectedCategories={selectedParameters.map(
                    (param) => param.category
                )}
                isShown={shouldShowPeriodModal}
                selectedItems={selectedPeriods}
                onItemSelected={(item: any) => {
                    const isLY = ['LY-N'].includes(item.name)
                    // this field is a hack so we know we're handling LQ or LY
                    if (item.values) {
                        setSelectedPeriods((prevState) => {
                            const existingPeriodRemoved = [
                                ...prevState.filter(
                                    (period) =>
                                        !period.name.startsWith(
                                            isLY ? 'LY-' : 'LQ-'
                                        )
                                ),
                            ]

                            const validStateToAdd: PERIOD_OPTION[] =
                                item.values.map((value) => {
                                    return {
                                        name: isLY
                                            ? `LY-${value}`
                                            : `LQ-${value}`,
                                        type: isLY ? 'LY' : 'LQ',
                                    }
                                })

                            return [
                                ...existingPeriodRemoved,
                                ...validStateToAdd,
                            ]
                        })

                        return
                    }

                    setSelectedPeriods((prevState) => {
                        if (
                            prevState.map((item) => item.id).includes(item.id)
                        ) {
                            return prevState.filter(
                                (period) => period.id !== item.id
                            )
                        } else {
                            return [...prevState, item]
                        }
                    })
                }}
                onSavePressed={() => setShouldShowPeriodModal(false)}
            />
            {!currentUser.isSubscribed ? (
                <StyledFullVersionContainer>
                    <a
                        href="https://wisesheets.io/payment"
                        target="_blank"
                        onClick={() => {
                            trackEvent(AmplitudeEvents.UPGRADE_BUTTON_CLICKED)
                        }}
                    >
                        <StyledFullVersionButton>
                            Get Full Version
                        </StyledFullVersionButton>
                    </a>
                    <br />
                    <br />
                </StyledFullVersionContainer>
            ) : null}

            <StyledClickHere>
                <StyledLink
                    target="_blank"
                    href="https://wisesheets.io/pages/docs"
                >
                    Wisesheets docs
                </StyledLink>
                <StyledLink
                    target="_blank"
                    href="https://wisesheets.io/available-data"
                    style={{ marginLeft: 20 }}
                >
                    Available data
                </StyledLink>
            </StyledClickHere>
        </StyledContainer>
    )
}

export { FunctionBuilder }
