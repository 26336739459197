import create from 'zustand'
import { persist } from 'zustand/middleware'
import { ErrorStore, Error } from './types/error'
import { Loading, LoadingStore } from './types/loading'
import { AuthStore, User } from './types/auth'
import { Bucket, BucketStore } from './types/bucket'
import { SuccessStore } from './types/success'
import { CCExpireStore } from './types/status'
import * as amplitude from '@amplitude/analytics-browser'

export const useLoadingStore = create<LoadingStore>((set) => ({
    loading: { isLoading: false, message: '' },
    setLoading: (loading: Loading) => set({ loading: loading }),
}))

export const useErrorStore = create<ErrorStore>((set) => ({
    error: { hasError: false, errorMessage: '' },
    setError: (error: Error) => set({ error: error }),
}))

export const useSuccessStore = create<SuccessStore>((set) => ({
    message: '',
    setSuccessMessage: (message: string) => set({ message: message }),
}))

const authInitialState: User = {
    email: '',
    exp: 0,
    iat: 0,
    isSubscribed: false,
    stripeSubscriptionId: '',
    userId: 0,
    subscriptionType: null,
}

export const useAuthStore = create<AuthStore>()(
    persist(
        (set) => ({
            currentUser: authInitialState,
            token: '',
            setAuth: (token: string) => {
                let payload = token.split('.')[1]
                payload = window.atob(payload)
                const decodedToken = JSON.parse(payload) as User
                set({
                    currentUser: decodedToken,
                    token: token,
                })
                amplitude.setUserId(decodedToken.email)
            },
            logout: () => {
                set({
                    currentUser: authInitialState,
                    token: '',
                })
            },
        }),
        { name: 'auth' }
    )
)

const bucketInitialState: Bucket[] = []

const sanitizeString = (str) => {
    str = str.replace(/[^a-z0-9áéíóúñü: \.,_-]/gim, '')
    return str.trim()
}

export const useBucketStore = create<BucketStore>()(
    persist(
        (set) => ({
            buckets: bucketInitialState,
            saveBucket: (bucket: Bucket) => {
                set((state) => ({ buckets: [...state.buckets, bucket] }))
            },
            removeBucket: (index: number) => {
                set((state) => {
                    const buckets = [...state.buckets]
                    buckets.splice(index, 1)
                    return { buckets: buckets }
                })
            },
            setBucketName: (index: number, name: string) => {
                set((state) => {
                    const buckets = [...state.buckets]
                    buckets[index].name = name
                    return { buckets: buckets }
                })
            },
            setBucketTickersRange: (index: number, tickersRange: string) => {
                set((state) => {
                    const buckets = [...state.buckets]
                    buckets[index].tickerRange = sanitizeString(tickersRange)
                    return { buckets: buckets }
                })
            },
            setBucketParametersRange: (
                index: number,
                parametersRange: string
            ) => {
                set((state) => {
                    const buckets = [...state.buckets]
                    buckets[index].parameterRange =
                        sanitizeString(parametersRange)
                    return { buckets: buckets }
                })
            },
        }),
        { name: 'buckets' }
    )
)

export const useCCExpireStore = create<CCExpireStore>((set) => ({
    status: { expiring: false },
    setSubscriptionCCStatus: (status: boolean) =>
        set({ status: { expiring: status } }),
}))
