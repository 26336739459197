// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GHu0Np9PHmjdG8SxFDFE {\n    border-radius: 6px 6px 0 0;\n    font-size: 14px;\n}\n\n.X5ELtkuGIlxSXt4OASh5 {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px;\n    border-radius: 6px 6px 0 0;\n}\n.X5ELtkuGIlxSXt4OASh5:hover {\n    background-color: #465484;\n}\n\n.P0Es0W4XyCeB5gETzzpA {\n    padding: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/Collapsible/Collapsible.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,0BAA0B;AAC9B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".collapsibleContainer {\n    border-radius: 6px 6px 0 0;\n    font-size: 14px;\n}\n\n.labelContainer {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px;\n    border-radius: 6px 6px 0 0;\n}\n.labelContainer:hover {\n    background-color: #465484;\n}\n\n.childrenContainer {\n    padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsibleContainer": "GHu0Np9PHmjdG8SxFDFE",
	"labelContainer": "X5ELtkuGIlxSXt4OASh5",
	"childrenContainer": "P0Es0W4XyCeB5gETzzpA"
};
export default ___CSS_LOADER_EXPORT___;
