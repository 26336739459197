import * as React from 'react'
import { createRoot } from 'react-dom/client'
import App from './main/App'
/* global document, Office, module, require */
import { MemoryRouter as Router } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'

amplitude.init('95e628cf7c27e0d69136609759238a73', {
    autocapture: true,
})

const rootElement: HTMLElement = document.getElementById('container')
const root = createRoot(rootElement)

Office.onReady(() => {
    root?.render(
        <Router>
            <App />
        </Router>
    )
})

if ((module as any).hot) {
    ;(module as any).hot.accept('./main/App', () => {
        const NextApp = require('./main/App').default
        root?.render(
            <Router>
                <NextApp />
            </Router>
        )
    })
}
