// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NXGDcS_3EqTqlz2NSx_u {\n    padding: 10px 12px;\n    border: none;\n    border-radius: 6px;\n    background-color: #1fb723;\n    -webkit-transition: background-color 0.2s;\n    -o-transition: background-color 0.2s;\n    transition: background-color 0.2s;\n    font-size: 16px;\n    color: #ffffff;\n    text-align: center;\n}\n\n.NXGDcS_3EqTqlz2NSx_u:hover {\n    cursor: pointer;\n    background-color: #1f8b22;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/ActionButton/ActionButton.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,yCAAyC;IACzC,oCAAoC;IACpC,iCAAiC;IACjC,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".actionButton {\n    padding: 10px 12px;\n    border: none;\n    border-radius: 6px;\n    background-color: #1fb723;\n    -webkit-transition: background-color 0.2s;\n    -o-transition: background-color 0.2s;\n    transition: background-color 0.2s;\n    font-size: 16px;\n    color: #ffffff;\n    text-align: center;\n}\n\n.actionButton:hover {\n    cursor: pointer;\n    background-color: #1f8b22;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButton": "NXGDcS_3EqTqlz2NSx_u"
};
export default ___CSS_LOADER_EXPORT___;
