import {
    COUNTRIES,
    EXCHANGES,
    getAllIndustries,
    getAllSectors,
    SECTOR_TO_INDUSTRY_MAP,
} from './constants'

type WiseScreenerInput = {
    type: 'multiValueInput' | 'booleanInput' | 'arithmeticInput'
    displayableName: string
    ctaText?: string // ie. add countries
    multiSelectData?: Record<string, string>[] | string[] | (() => string[])
}

export enum SCREENER_FILTER_KEYS {
    'MARKET_CAP' = 'MARKET_CAP',
    'PRICE' = 'PRICE',
    'BETA' = 'BETA',
    'VOLUME' = 'VOLUME',
    'DIVIDEND' = 'DIVIDEND',
    'IS_ETF' = 'IS_ETF',
    'IS_FUND' = 'IS_FUND',
    'IS_ACTIVELY_TRADING' = 'IS_ACTIVELY_TRADING',
    'SECTOR' = 'SECTOR',
    'INDUSTRY' = 'INDUSTRY',
    'COUNTRY' = 'COUNTRY',
    'EXCHANGE' = 'EXCHANGE',
}

type SCREENER_INPUT_TO_FORM_VALIDATOR = Record<
    SCREENER_FILTER_KEYS,
    WiseScreenerInput
>

export const SCREENER_INPUT_MAP: SCREENER_INPUT_TO_FORM_VALIDATOR = {
    // type 1: arithmetic
    [SCREENER_FILTER_KEYS.MARKET_CAP]: {
        type: 'arithmeticInput',
        displayableName: 'Market Cap',
    },
    [SCREENER_FILTER_KEYS.PRICE]: {
        type: 'arithmeticInput',
        displayableName: 'Price',
    },
    [SCREENER_FILTER_KEYS.BETA]: {
        type: 'arithmeticInput',
        displayableName: 'Beta',
    },
    [SCREENER_FILTER_KEYS.VOLUME]: {
        type: 'arithmeticInput',
        displayableName: 'Volume',
    },
    [SCREENER_FILTER_KEYS.DIVIDEND]: {
        type: 'arithmeticInput',
        displayableName: 'Dividend',
    },
    // type 2: boolean
    [SCREENER_FILTER_KEYS.IS_ETF]: {
        type: 'booleanInput',
        displayableName: 'Is ETF',
    },
    [SCREENER_FILTER_KEYS.IS_FUND]: {
        type: 'booleanInput',
        displayableName: 'Is Fund',
    },
    [SCREENER_FILTER_KEYS.IS_ACTIVELY_TRADING]: {
        type: 'booleanInput',
        displayableName: 'Is Actively Trading',
    },
    // type 3: multi value
    [SCREENER_FILTER_KEYS.SECTOR]: {
        type: 'multiValueInput',
        displayableName: 'Sector',
        ctaText: 'Add sectors',
        multiSelectData: getAllSectors,
    },
    [SCREENER_FILTER_KEYS.INDUSTRY]: {
        type: 'multiValueInput',
        displayableName: 'Industry',
        ctaText: 'Add industries',
        multiSelectData: getAllIndustries,
    },
    [SCREENER_FILTER_KEYS.COUNTRY]: {
        type: 'multiValueInput',
        displayableName: 'Country',
        ctaText: 'Add countries',
        multiSelectData: COUNTRIES,
    },
    [SCREENER_FILTER_KEYS.EXCHANGE]: {
        type: 'multiValueInput',
        displayableName: 'Exchange',
        ctaText: 'Add exchanges',
        multiSelectData: EXCHANGES,
    },
}

export const DEFAULT_SELECTED_FILTER_OPTION_STATE = {
    [SCREENER_FILTER_KEYS.SECTOR]: [],
    [SCREENER_FILTER_KEYS.INDUSTRY]: [],
    [SCREENER_FILTER_KEYS.COUNTRY]: [],
    [SCREENER_FILTER_KEYS.EXCHANGE]: [],
}

export const getValidIndustries = (selectedSectors: string[]) => {
    if (!selectedSectors.length) return getAllIndustries()

    let industries = []
    selectedSectors.forEach((sector) => {
        if (SECTOR_TO_INDUSTRY_MAP[sector]) {
            industries = [...industries, ...SECTOR_TO_INDUSTRY_MAP[sector]]
        }
    })
    return industries
}
