import React, { useState } from 'react'
import { TbRefresh } from 'react-icons/tb'
import styles from './RefreshFunctions.module.css'
import OutsideAlerter from '../../hooks/ClickOutside'

export default function RefreshFunctions(props: {
    refreshFunctions: (isAllSheets: boolean) => void
}) {
    const { refreshFunctions } = props
    const [isOpened, SetIsOpened] = useState(false)

    return (
        <div
            className={styles.refreshFunctions}
            style={
                Office.context.platform === Office.PlatformType.Mac
                    ? { right: '68px' }
                    : { right: '44px' }
            }
        >
            <div className={styles.moreContainer}>
                <OutsideAlerter
                    onpress={() => {
                        SetIsOpened(false)
                    }}
                >
                    <TbRefresh
                        color="#fff"
                        onClick={() => SetIsOpened(!isOpened)}
                    />
                    {isOpened ? (
                        <div className={styles.dropDownMenu}>
                            <div
                                className={styles.menuItem}
                                onClick={() => refreshFunctions(false)}
                            >
                                Refresh current tab
                            </div>
                            <hr className={styles.divider} />
                            <div
                                className={styles.menuItem}
                                onClick={() => refreshFunctions(true)}
                            >
                                Refresh all tabs
                            </div>
                        </div>
                    ) : null}
                </OutsideAlerter>
            </div>
        </div>
    )
}
