// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IG9Vlp27jkVAo7pzEB4c {\n    position: absolute;\n    top: 20px;\n    color: #fff;\n    font-size: 18px;\n    cursor: pointer;\n    z-index: 2;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/RefreshErrors/RefreshErrors.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;IACf,UAAU;AACd","sourcesContent":[".refreshErrors {\n    position: absolute;\n    top: 20px;\n    color: #fff;\n    font-size: 18px;\n    cursor: pointer;\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshErrors": "IG9Vlp27jkVAo7pzEB4c"
};
export default ___CSS_LOADER_EXPORT___;
