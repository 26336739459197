import * as amplitude from '@amplitude/analytics-browser'
import { useAuthStore } from '../state/store'

export const AmplitudeEvents = {
    LOGIN: 'login',
    LIVE_DATA_UPDATED: 'live_data_updated',
    REFRESH_ERRORS: 'refresh_errors',
    RUN_SCREENER: 'run_screener',
    RUN_SCREENER_DATA: 'run_screener_data',
    RUN_SCREENER_DEMO: 'run_screener_demo',
    DATA_DUMP: 'data_dump',
    SEC_DATA_DUMP: 'sec_data_dump',
    APPLY_TEMPLATE: 'apply_template',
    UPGRADE_BUTTON_CLICKED: 'upgrade_button_clicked',
} as const

const Platform = 'excel'
export const useAmplitudeTrack = () => {
    const trackEvent = (
        event: typeof AmplitudeEvents[keyof typeof AmplitudeEvents],
        properties?: Record<string, any>
    ) => {
        // Get current state directly from store
        const currentUser = useAuthStore.getState().currentUser

        amplitude.track(event, {
            platform: Platform,
            user_id: currentUser?.email,
            subscription_status: currentUser?.isSubscribed,
            subscription_type: currentUser?.subscriptionType,
            ...properties,
        })
    }

    return trackEvent
}
